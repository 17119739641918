// import React, { useState, useEffect } from 'react';
// import { Loader2, AlertCircle, ArrowRight, FileEdit, Forward, Users } from 'lucide-react';
// import { toast } from 'sonner';
// const API_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// const JobSiteIcon = ({ site }) => {
//     const icons = {
//         LinkedIn: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                 <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//             </svg>
//         ),
//         Indeed: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#2164F3" />
//                 <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                 <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//             </svg>
//         ),
//         Glassdoor: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                 <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//             </svg>
//         ),
//         Dice: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                 <path d="M10 10h20v20H10z" fill="white" />
//                 <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                 <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                 <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                 <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//             </svg>
//         ),
//         Monster: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#6E45A5" />
//                 <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
//             </svg>
//         ),
//         'Talent.com': (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#1D2C4D" />
//                 <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
//                 <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
//             </svg>
//         ),
//         Greenhouse: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#3AB549" />
//                 <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
//                 <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
//             </svg>
//         )
//     };

//     return icons[site] || null;
// };

// const JobDescriptionModal = ({ isVisible, onClose, onSave, existingRoles = [] }) => {
//     const [showSelection, setShowSelection] = useState(true);
//     const [showMainContent, setShowMainContent] = useState(false);
//     const [jobData, setJobData] = useState({
//         companyName: '',
//         roleTitle: '',
//         department: '',
//         location: '',
//         salaryRange: '',
//         jobDescription: ''
//     });
//     const [url, setUrl] = useState('');
//     const [isScraping, setIsScraping] = useState(false);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [error, setError] = useState(null);
//     const [selectedSite, setSelectedSite] = useState(null);
//     const [matchingRoles, setMatchingRoles] = useState([]);

//     const jobSites = [
//         { name: 'LinkedIn', domain: 'linkedin.com' },
//         { name: 'Indeed', domain: 'indeed.com' },
//         { name: 'Glassdoor', domain: 'glassdoor.com' },
//         { name: 'Dice', domain: 'dice.com' },
//         { name: 'Monster', domain: 'monster.com' },
//         { name: 'Talent.com', domain: 'talent.com' },
//         { name: 'Greenhouse', domain: 'greenhouse.io' }
//     ];

//     useEffect(() => {
//         const checkExistingRoles = () => {
//             if (jobData.companyName && jobData.roleTitle) {
//                 const matches = existingRoles.filter(role =>
//                     role.company_name.toLowerCase().includes(jobData.companyName.toLowerCase()) &&
//                     role.role_title.toLowerCase().includes(jobData.roleTitle.toLowerCase())
//                 );
//                 setMatchingRoles(matches);
//             } else {
//                 setMatchingRoles([]);
//             }
//         };

//         checkExistingRoles();
//     }, [jobData.companyName, jobData.roleTitle, existingRoles]);

//     const handleQuickSubmit = async () => {
//         if (!jobData.companyName || !jobData.roleTitle) {
//             setError('Company name and role title are required');
//             return;
//         }

//         setIsSubmitting(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/open-roles', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     uuid: crypto.randomUUID(),
//                     company_name: jobData.companyName,
//                     role_title: jobData.roleTitle,
//                     department: jobData.department,
//                     location: jobData.location,
//                     salary_range: jobData.salaryRange,
//                     job_description: jobData.jobDescription,
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to save job posting');
//             }

//             const data = await response.json();
//             onSave({ ...jobData, uuid: data.uuid });
//             onClose();
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     const handleScrapeJob = async () => {
//         setIsScraping(true);
//         setError(null);
//         try {
//             // Step 1: Scrape job posting with CORS headers
//             const scrapeResponse = await fetch(`${API_BASE_URL}/scrape-job`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json',
//                     'Origin': window.location.origin,
//                     'Access-Control-Request-Method': 'POST'
//                 },
//                 credentials: 'include', // Include credentials if needed
//                 body: JSON.stringify({
//                     url,
//                     site: selectedSite?.domain
//                 })
//             });

//             if (!scrapeResponse.ok) {
//                 const errorData = await scrapeResponse.json();
//                 throw new Error(errorData.message || 'Failed to scrape job description');
//             }

//             const scrapeData = await scrapeResponse.json();
//             console.log('Scraped data:', scrapeData);

//             // Extract the raw text
//             const textToProcess = scrapeData.raw_text || scrapeData.description || '';

//             // Step 2: Parse the text using proxy endpoint
//             try {
//                 const parseResponse = await fetch(`${API_BASE_URL}/api/parse`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Accept': 'application/json',
//                         'Origin': window.location.origin,
//                         'Access-Control-Request-Method': 'POST'
//                     },
//                     credentials: 'include',
//                     body: JSON.stringify({
//                         text: textToProcess,
//                         style: 'job_posting',
//                         options: {
//                             parse_mode: 'full',
//                             extract_metadata: true,
//                             normalize_text: true
//                         }
//                     })
//                 });

//                 if (!parseResponse.ok) {
//                     // Try alternate endpoint if first fails
//                     const alternateResponse = await fetch(`${API_BASE_URL}/api/case-text/parse`, {
//                         method: 'POST',
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Origin': window.location.origin,
//                             'Access-Control-Request-Method': 'POST'
//                         },
//                         credentials: 'include',
//                         body: JSON.stringify({
//                             text: textToProcess,
//                             style: 'default',
//                             options: {
//                                 parse_mode: 'full',
//                                 extract_metadata: true,
//                                 normalize_text: true
//                             }
//                         })
//                     });

//                     if (!alternateResponse.ok) {
//                         throw new Error('All parsing attempts failed');
//                     }

//                     var parsedData = await alternateResponse.json();
//                 } else {
//                     var parsedData = await parseResponse.json();
//                 }
//             } catch (parseError) {
//                 console.error('Parsing error:', parseError);
//                 // Fall back to using scraped data directly if parsing fails
//                 parsedData = {
//                     company_name: scrapeData.company || scrapeData.metadata?.company,
//                     role_title: scrapeData.title || scrapeData.metadata?.title,
//                     location: scrapeData.location || scrapeData.metadata?.location,
//                     department: scrapeData.department || scrapeData.metadata?.department,
//                     salary_range: scrapeData.salary_range || scrapeData.metadata?.salary_range,
//                     job_description: textToProcess
//                 };
//             }

//             // Extract job details with comprehensive fallbacks
//             const jobDetails = {
//                 companyName: scrapeData.company ||
//                     scrapeData.metadata?.company ||
//                     parsedData.company_name || '',
//                 roleTitle: scrapeData.title ||
//                     scrapeData.metadata?.title ||
//                     parsedData.role_title || '',
//                 department: scrapeData.department ||
//                     scrapeData.metadata?.department ||
//                     parsedData.department || '',
//                 location: scrapeData.location ||
//                     scrapeData.metadata?.location ||
//                     parsedData.location || '',
//                 salaryRange: scrapeData.salary_range ||
//                     scrapeData.metadata?.salary_range ||
//                     parsedData.salary_range || '',
//                 jobDescription: textToProcess || parsedData.job_description || ''
//             };

//             // Update state with extracted data
//             setJobData(prev => ({
//                 ...prev,
//                 ...jobDetails
//             }));

//             // Validate extracted data
//             const missingFields = Object.entries(jobDetails)
//                 .filter(([key, value]) => !value && key !== 'department' && key !== 'salaryRange')
//                 .map(([key]) => key.replace(/([A-Z])/g, ' $1').trim());

//             if (missingFields.length > 0) {
//                 toast.warning(
//                     `Please review the following fields: ${missingFields.join(', ')}`,
//                     { duration: 6000 }
//                 );
//             } else {
//                 toast.success('Job details imported successfully!', { duration: 3000 });
//             }

//         } catch (err) {
//             console.error('Error during job import:', err);

//             // Handle specific error types
//             if (err.message.includes('CORS') || err.name === 'TypeError') {
//                 setError('Network error: Unable to connect to the server. Please try again later.');
//                 toast.error('Connection error. Please try again or enter details manually.');
//             } else {
//                 setError(err.message || 'Failed to import job details');
//                 toast.error('Import failed. Please try again or enter details manually.');
//             }
//         } finally {
//             setIsScraping(false);
//         }
//     };
//     const handleInputChange = (field, value) => {
//         setJobData(prev => ({ ...prev, [field]: value }));
//         setError(null);
//     };

//     const handleOptionSelect = (option) => {
//         setShowSelection(false);
//         setShowMainContent(true);
//         if (option === 'custom') {
//             setSelectedSite(null);
//         }
//     };

//     if (!isVisible) return null;

//     return (
//         <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//             <div className="bg-white rounded-xl shadow-2xl w-full max-w-6xl min-h-[80vh] max-h-[95vh] flex flex-col">
//                 <div className="p-6 border-b flex-shrink-0">
//                     <div className="flex justify-between items-center">
//                         <h2 className="text-2xl font-bold">New Job Opening</h2>
//                         <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
//                     </div>
//                 </div>

//                 <div className="flex-1 overflow-y-auto p-6">
//                     {showSelection ? (
//                         <div className="space-y-8">
//                             <div className="grid grid-cols-2 gap-4">
//                                 <button
//                                     onClick={() => handleOptionSelect('import')}
//                                     className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
//                                 >
//                                     <div className="flex flex-col items-center gap-3">
//                                         <Forward className="w-12 h-12 text-blue-600" />
//                                         <h3 className="text-xl font-semibold">Import from Job Site</h3>
//                                         <p className="text-sm text-gray-500 text-center">
//                                             Import job details from LinkedIn, Indeed, and other platforms
//                                         </p>
//                                     </div>
//                                 </button>

//                                 <button
//                                     onClick={() => handleOptionSelect('custom')}
//                                     className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
//                                 >
//                                     <div className="flex flex-col items-center gap-3">
//                                         <FileEdit className="w-12 h-12 text-blue-600" />
//                                         <h3 className="text-xl font-semibold">Custom Entry</h3>
//                                         <p className="text-sm text-gray-500 text-center">
//                                             Create a job posting from scratch
//                                         </p>
//                                     </div>
//                                 </button>
//                             </div>

//                             <div className="flex items-center gap-4">
//                                 <div className="flex-1 border-t border-gray-200"></div>
//                                 <span className="text-sm font-medium text-gray-500">OR</span>
//                                 <div className="flex-1 border-t border-gray-200"></div>
//                             </div>

//                             <div className="border rounded-lg border-gray-200">
//                                 <div className="p-4 border-b border-gray-200 bg-gray-50">
//                                     <h3 className="font-semibold text-gray-700">Existing Open Roles</h3>
//                                 </div>
//                                 <div className="divide-y divide-gray-200 max-h-[300px] overflow-y-auto">
//                                     {existingRoles.map((role, index) => (
//                                         <button
//                                             key={role.uuid}
//                                             onClick={() => handleOptionSelect('existing', role)}
//                                             className="w-full p-4 hover:bg-gray-50 flex items-center justify-between group"
//                                         >
//                                             <div className="flex-1">
//                                                 <h4 className="font-medium text-gray-900">{role.role_title}</h4>
//                                                 <div className="flex items-center gap-2 text-sm text-gray-500">
//                                                     <span>{role.company_name}</span>
//                                                     {role.location && (
//                                                         <>
//                                                             <span>•</span>
//                                                             <span>{role.location}</span>
//                                                         </>
//                                                     )}
//                                                 </div>
//                                             </div>
//                                             <ArrowRight className="w-5 h-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
//                                         </button>
//                                     ))}
//                                     {existingRoles.length === 0 && (
//                                         <div className="p-4 text-sm text-gray-500 text-center">
//                                             No open roles found
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>

//                             <div className="flex justify-center pt-4">
//                                 <button
//                                     onClick={onClose}
//                                     className="px-6 py-2 text-gray-600 hover:text-gray-800 text-sm"
//                                 >
//                                     Skip for now (assign later)
//                                 </button>
//                             </div>
//                         </div>
//                     ) : showMainContent && (
//                         <div className="space-y-8">
//                             <div className="border-b pb-6">
//                                 <h3 className="text-lg font-semibold mb-4">Import from Job Sites</h3>
//                                 <div className="grid grid-cols-4 gap-4">
//                                     {jobSites.map(site => (
//                                         <button
//                                             key={site.name}
//                                             onClick={() => setSelectedSite(site)}
//                                             className={`p-4 rounded-lg border transition-all ${selectedSite?.name === site.name
//                                                 ? 'border-blue-500 bg-blue-50'
//                                                 : 'border-gray-200 hover:border-gray-300'
//                                                 }`}
//                                         >
//                                             <div className="flex flex-col items-center gap-2">
//                                                 <JobSiteIcon site={site.name} />
//                                                 <span className="text-sm font-medium">{site.name}</span>
//                                             </div>
//                                         </button>
//                                     ))}
//                                 </div>
//                             </div>

//                             <div className="space-y-6">
//                                 {matchingRoles.length > 0 && (
//                                     <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
//                                         <div className="flex items-start gap-3">
//                                             <Users className="w-5 h-5 text-yellow-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-yellow-800">
//                                                     Similar Roles Found
//                                                 </h4>
//                                                 <p className="text-sm text-yellow-700 mt-1">
//                                                     There are {matchingRoles.length} similar open roles:
//                                                 </p>
//                                                 <ul className="mt-2 space-y-1">
//                                                     {matchingRoles.map((role, index) => (
//                                                         <li key={index} className="text-sm text-yellow-700">
//                                                             {role.company_name} - {role.role_title}
//                                                         </li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {selectedSite && (
//                                     <div className="space-y-4">
//                                         <div>
//                                             <label className="text-sm font-medium mb-1 block">
//                                                 Job URL from {selectedSite.name}
//                                             </label>
//                                             <div className="flex gap-2">
//                                                 <input
//                                                     type="url"
//                                                     className="flex-1 px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                                     value={url}
//                                                     onChange={(e) => setUrl(e.target.value)}
//                                                     placeholder={`Paste ${selectedSite.name} job URL`}
//                                                 />
//                                                 <button
//                                                     onClick={handleScrapeJob}
//                                                     disabled={!url || isScraping}
//                                                     className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                                                 >
//                                                     {isScraping ? (
//                                                         <>
//                                                             <Loader2 className="w-5 h-5 animate-spin" />
//                                                             <span>Importing...</span>
//                                                         </>
//                                                     ) : (
//                                                         <>
//                                                             <ArrowRight className="w-5 h-5" />
//                                                             <span>Import Job</span>
//                                                         </>
//                                                     )}
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="grid grid-cols-2 gap-6">
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Company Name</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.companyName}
//                                             onChange={(e) => handleInputChange('companyName', e.target.value)}
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Role Title</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.roleTitle}
//                                             onChange={(e) => handleInputChange('roleTitle', e.target.value)}
//                                         />
//                                     </div>
//                                 </div>

//                                 <div className="grid grid-cols-2 gap-6">
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Department</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.department}
//                                             onChange={(e) => handleInputChange('department', e.target.value)}
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Location</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.location}
//                                             onChange={(e) => handleInputChange('location', e.target.value)}
//                                         />
//                                     </div>
//                                 </div>

//                                 <div>
//                                     <label className="text-sm font-medium mb-1 block">Salary Range</label>
//                                     <input
//                                         type="text"
//                                         className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                         value={jobData.salaryRange}
//                                         onChange={(e) => handleInputChange('salaryRange', e.target.value)}
//                                         placeholder="e.g., $50k - $70k"
//                                     />
//                                 </div>

//                                 <div>
//                                     <label className="text-sm font-medium mb-1 block">Job Description</label>
//                                     <textarea
//                                         className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 h-96"
//                                         value={jobData.jobDescription}
//                                         onChange={(e) => handleInputChange('jobDescription', e.target.value)}
//                                         placeholder="Enter job description"
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 <div className="p-6 border-t flex-shrink-0">
//                     <div className="flex justify-between items-center">
//                         <div>
//                             {error && (
//                                 <div className="text-red-600 text-sm flex items-center gap-2">
//                                     <AlertCircle className="w-4 h-4" />
//                                     {error}
//                                 </div>
//                             )}
//                         </div>

//                         <div className="flex gap-4">
//                             {showMainContent && (
//                                 <button
//                                     onClick={() => {
//                                         setShowSelection(true);
//                                         setShowMainContent(false);
//                                     }}
//                                     className="px-6 py-3 border rounded-lg hover:bg-gray-50"
//                                 >
//                                     Back
//                                 </button>
//                             )}
//                             <button
//                                 onClick={handleQuickSubmit}
//                                 disabled={isSubmitting}
//                                 className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                             >
//                                 {isSubmitting ? (
//                                     <>
//                                         <Loader2 className="w-5 h-5 animate-spin" />
//                                         <span>Saving...</span>
//                                     </>
//                                 ) : (
//                                     <span>{showMainContent ? 'Post Job' : 'Continue'}</span>
//                                 )}
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default JobDescriptionModal;

import React, { useState, useEffect } from 'react';
import { Loader2, AlertCircle, ArrowRight, FileEdit, Forward, Users } from 'lucide-react';
import { toast } from 'sonner';
import supabase from '../../supabaseClient';

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}
        {...props}
    />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`flex flex-col space-y-1.5 p-6 ${className}`}
        {...props}
    />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={`text-lg font-semibold leading-none tracking-tight ${className}`}
        {...props}
    />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
    <p
        ref={ref}
        className={`text-sm text-muted-foreground ${className}`}
        {...props}
    />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 ${className}`} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`flex items-center p-6 pt-0 ${className}`}
        {...props}
    />
))
CardFooter.displayName = "CardFooter"

const API_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

const JobSiteIcon = ({ site }) => {
    const icons = {
        LinkedIn: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#0A66C2" />
                <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
            </svg>
        ),
        Indeed: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#2164F3" />
                <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
                <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
            </svg>
        ),
        Glassdoor: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#0CAA41" />
                <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
            </svg>
        ),
        Dice: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#EB1C26" />
                <path d="M10 10h20v20H10z" fill="white" />
                <circle cx="15" cy="15" r="2" fill="#EB1C26" />
                <circle cx="25" cy="15" r="2" fill="#EB1C26" />
                <circle cx="15" cy="25" r="2" fill="#EB1C26" />
                <circle cx="25" cy="25" r="2" fill="#EB1C26" />
            </svg>
        ),
        Monster: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#6E45A5" />
                <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
            </svg>
        ),
        'Talent.com': (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#1D2C4D" />
                <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
                <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
            </svg>
        ),
        Greenhouse: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#3AB549" />
                <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
                <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
            </svg>
        )
    };

    return icons[site] || null;
};

const JobDescriptionModal = ({ isVisible, onClose, onSave, existingRoles = [] }) => {
    const [showSelection, setShowSelection] = useState(true);
    const [showMainContent, setShowMainContent] = useState(false);
    const [jobData, setJobData] = useState({
        companyName: '',
        roleTitle: '',
        department: '',
        location: '',
        salaryRange: '',
        jobDescription: ''
    });
    const [url, setUrl] = useState('');
    const [isScraping, setIsScraping] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [matchingRoles, setMatchingRoles] = useState([]);
    const [rawJobDescription, setRawJobDescription] = useState('');


    const jobSites = [
        { name: 'LinkedIn', domain: 'linkedin.com' },
        { name: 'Indeed', domain: 'indeed.com' },
        { name: 'Glassdoor', domain: 'glassdoor.com' },
        { name: 'Dice', domain: 'dice.com' },
        { name: 'Monster', domain: 'monster.com' },
        { name: 'Talent.com', domain: 'talent.com' },
        { name: 'Greenhouse', domain: 'greenhouse.io' }
    ];

    useEffect(() => {
        const checkExistingRoles = () => {
            if (jobData.companyName && jobData.roleTitle) {
                const matches = existingRoles.filter(role =>
                    role.company_name.toLowerCase().includes(jobData.companyName.toLowerCase()) &&
                    role.role_title.toLowerCase().includes(jobData.roleTitle.toLowerCase())
                );
                setMatchingRoles(matches);
            } else {
                setMatchingRoles([]);
            }
        };

        checkExistingRoles();
    }, [jobData.companyName, jobData.roleTitle, existingRoles]);

    const handleQuickSubmit = async () => {
        if (!jobData.companyName || !jobData.roleTitle) {
            setError('Company name and role title are required');
            toast.error('Company name and role title are required');
            return;
        }

        setIsSubmitting(true);
        try {
            // Get current user and agency info
            const { data: { user } } = await supabase.auth.getUser();
            const { data: membership } = await supabase
                .from('agency_members')
                .select('agency_id')
                .eq('user_id', user.id)
                .single();

            // Insert into open_roles table
            const { data, error } = await supabase
                .from('open_roles')
                .insert({
                    uuid: crypto.randomUUID(),
                    agency_id: membership.agency_id,
                    uploaded_by: user.id,
                    company_name: jobData.companyName || null,
                    role_title: jobData.roleTitle || null,
                    department: jobData.department || null,
                    location: jobData.location || null,
                    salary_range: jobData.salaryRange || null,
                    job_description: jobData.jobDescription || null,
                    raw_description: rawJobDescription || null,  // Add the raw text
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                })
                .select()
                .single();

            if (error) throw error;

            onSave({ ...jobData, uuid: data.uuid });
            toast.success('Job posting saved successfully!');
            onClose();
        } catch (err) {
            console.error('Error saving job:', err);
            setError('Failed to save job posting. Please try again.');
            toast.error('Failed to save job posting');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleScrapeJob = async () => {

        setIsScraping(true);
        setError(null);
        try {
            // Step 1: Scrape job posting
            const scrapeResponse = await fetch(`${API_BASE_URL}/scrape-job`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    url,
                    site: selectedSite?.domain
                })
            });

            if (!scrapeResponse.ok) {
                throw new Error('Failed to scrape job description');
            }

            const scrapeData = await scrapeResponse.json();
            const descriptionText = scrapeData.description || '';

            setRawJobDescription(descriptionText);  // Store the raw text

            console.log('Scraped data:', scrapeData);


            // Get just the description text from the scraped data

            // Step 2: Parse only the description text
            try {
                const parseResponse = await fetch(`${API_BASE_URL}/api/case-text/parse`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        text: descriptionText,
                        style: 'job_posting'  // Added back the required style field

                    })
                });

                if (!parseResponse.ok) {
                    throw new Error('Failed to parse job description');
                }

                const parsedData = await parseResponse.json();

                // Use parsed data as primary source, fall back to scraped data
                const jobDetails = {
                    companyName: parsedData.company_name || scrapeData.company || '',
                    roleTitle: parsedData.role_title || scrapeData.title || '',
                    department: parsedData.department || scrapeData.department || '',
                    location: parsedData.location || scrapeData.location || '',
                    salaryRange: parsedData.salary_range || scrapeData.salary_range || '',
                    jobDescription: parsedData.job_description || descriptionText
                };

                setJobData(prev => ({
                    ...prev,
                    ...jobDetails
                }));

                toast.success('Job details imported successfully!');

                // Validate required fields
                const missingFields = Object.entries(jobDetails)
                    .filter(([key, value]) => !value && key !== 'department' && key !== 'salaryRange')
                    .map(([key]) => key.replace(/([A-Z])/g, ' $1').trim().toLowerCase());

                if (missingFields.length > 0) {
                    toast.warning(
                        `Please review the following fields: ${missingFields.join(', ')}`,
                        { duration: 6000 }
                    );
                }

            } catch (parseError) {
                console.error('Parsing error:', parseError);
                // If parsing fails, use the scraped data directly
                const jobDetails = {
                    companyName: scrapeData.company || '',
                    roleTitle: scrapeData.title || '',
                    department: scrapeData.department || '',
                    location: scrapeData.location || '',
                    salaryRange: scrapeData.salary_range || '',
                    jobDescription: descriptionText
                };

                setJobData(prev => ({
                    ...prev,
                    ...jobDetails
                }));

                toast.warning('Imported basic job details. Some fields may need manual review.');
            }

        } catch (err) {
            console.error('Error during job import:', err);
            setError('Failed to import job details. Please try again or enter manually.');
            toast.error('Import failed. Please try again or enter details manually.');
        } finally {
            setIsScraping(false);
        }
    };

    const handleInputChange = (field, value) => {
        setJobData(prev => ({ ...prev, [field]: value }));
        setError(null);
    };

    const handleOptionSelect = (option) => {
        setShowSelection(false);
        setShowMainContent(true);
        if (option === 'custom') {
            setSelectedSite(null);
        }
    };

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-2xl w-full max-w-6xl min-h-[80vh] max-h-[95vh] flex flex-col">
                <div className="p-6 border-b flex-shrink-0">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-bold">New Job Opening</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            ×
                        </button>
                    </div>
                </div>

                <div className="flex-1 overflow-y-auto p-6">
                    {showSelection ? (
                        <div className="space-y-8">
                            <div className="grid grid-cols-2 gap-4">
                                <button
                                    onClick={() => handleOptionSelect('import')}
                                    className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
                                >
                                    <div className="flex flex-col items-center gap-3">
                                        <Forward className="w-12 h-12 text-blue-600" />
                                        <h3 className="text-xl font-semibold">Import from Job Site</h3>
                                        <p className="text-sm text-gray-500 text-center">
                                            Import job details from LinkedIn, Indeed, and other platforms
                                        </p>
                                    </div>
                                </button>

                                <button
                                    onClick={() => handleOptionSelect('custom')}
                                    className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
                                >
                                    <div className="flex flex-col items-center gap-3">
                                        <FileEdit className="w-12 h-12 text-blue-600" />
                                        <h3 className="text-xl font-semibold">Custom Entry</h3>
                                        <p className="text-sm text-gray-500 text-center">
                                            Create a job posting from scratch
                                        </p>
                                    </div>
                                </button>
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="flex-1 border-t border-gray-200"></div>
                                <span className="text-sm font-medium text-gray-500">OR</span>
                                <div className="flex-1 border-t border-gray-200"></div>
                            </div>

                            <div className="border rounded-lg border-gray-200">
                                <div className="p-4 border-b border-gray-200 bg-gray-50">
                                    <h3 className="font-semibold text-gray-700">Existing Open Roles</h3>
                                </div>
                                <div className="divide-y divide-gray-200 max-h-[300px] overflow-y-auto">
                                    {existingRoles.map((role) => (
                                        <button
                                            key={role.uuid}
                                            onClick={() => handleOptionSelect('existing', role)}
                                            className="w-full p-4 hover:bg-gray-50 flex items-center justify-between group"
                                        >
                                            <div className="flex-1">
                                                <h4 className="font-medium text-gray-900">{role.role_title}</h4>
                                                <div className="flex items-center gap-2 text-sm text-gray-500">
                                                    <span>{role.company_name}</span>
                                                    {role.location && (
                                                        <>
                                                            <span>•</span>
                                                            <span>{role.location}</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <ArrowRight className="w-5 h-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                                        </button>
                                    ))}
                                    {existingRoles.length === 0 && (
                                        <div className="p-4 text-sm text-gray-500 text-center">
                                            No open roles found
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex justify-center pt-4">
                                <button
                                    onClick={onClose}
                                    className="px-6 py-2 text-gray-600 hover:text-gray-800 text-sm"
                                >
                                    Skip for now (assign later)
                                </button>
                            </div>
                        </div>
                    ) : showMainContent && (
                        <div className="space-y-8">
                            <div className="border-b pb-6">
                                <h3 className="text-lg font-semibold mb-4">Import from Job Sites</h3>
                                <div className="grid grid-cols-4 gap-4">
                                    {jobSites.map(site => (
                                        <button
                                            key={site.name}
                                            onClick={() => setSelectedSite(site)}
                                            className={`p-4 rounded-lg border transition-all ${selectedSite?.name === site.name
                                                ? 'border-blue-500 bg-blue-50'
                                                : 'border-gray-200 hover:border-gray-300'
                                                }`}
                                        >
                                            <div className="flex flex-col items-center gap-2">
                                                <JobSiteIcon site={site.name} />
                                                <span className="text-sm font-medium">{site.name}</span>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <div className="space-y-6">
                                {matchingRoles.length > 0 && (
                                    <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                                        <div className="flex items-start gap-3">
                                            <Users className="w-5 h-5 text-yellow-600 mt-0.5" />
                                            <div>
                                                <h4 className="font-medium text-yellow-800">
                                                    Similar Roles Found
                                                </h4>
                                                <p className="text-sm text-yellow-700 mt-1">
                                                    There are {matchingRoles.length} similar open roles:
                                                </p>
                                                <ul className="mt-2 space-y-1">
                                                    {matchingRoles.map((role, index) => (
                                                        <li key={index} className="text-sm text-yellow-700">
                                                            {role.company_name} - {role.role_title}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {selectedSite && (
                                    <div className="space-y-4">
                                        <div>
                                            <label className="text-sm font-medium mb-1 block">
                                                Job URL from {selectedSite.name}
                                            </label>
                                            <div className="flex gap-2">
                                                <input
                                                    type="url"
                                                    className="flex-1 px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                                    value={url}
                                                    onChange={(e) => setUrl(e.target.value)}
                                                    placeholder={`Paste ${selectedSite.name} job URL`}
                                                />
                                                <button
                                                    onClick={handleScrapeJob}
                                                    disabled={!url || isScraping}
                                                    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                                                >
                                                    {isScraping ? (
                                                        <>
                                                            <Loader2 className="w-5 h-5 animate-spin" />
                                                            <span>Importing...</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ArrowRight className="w-5 h-5" />
                                                            <span>Import Job</span>
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="text-sm font-medium mb-1 block">Company Name</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                            value={jobData.companyName}
                                            onChange={(e) => handleInputChange('companyName', e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm font-medium mb-1 block">Role Title</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                            value={jobData.roleTitle}
                                            onChange={(e) => handleInputChange('roleTitle', e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="text-sm font-medium mb-1 block">Department</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                            value={jobData.department}
                                            onChange={(e) => handleInputChange('department', e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm font-medium mb-1 block">Location</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                            value={jobData.location}
                                            onChange={(e) => handleInputChange('location', e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="text-sm font-medium mb-1 block">Salary Range</label>
                                    <input
                                        type="text"
                                        className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
                                        value={jobData.salaryRange}
                                        onChange={(e) => handleInputChange('salaryRange', e.target.value)}
                                        placeholder="e.g., $50k - $70k"
                                    />
                                </div>

                                <div>
                                    <label className="text-sm font-medium mb-1 block">Job Description</label>
                                    <textarea
                                        className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 h-96"
                                        value={jobData.jobDescription}
                                        onChange={(e) => handleInputChange('jobDescription', e.target.value)}
                                        placeholder="Enter job description"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="p-6 border-t flex-shrink-0">
                    <div className="flex justify-between items-center">
                        <div>
                            {error && (
                                <div className="text-red-600 text-sm flex items-center gap-2">
                                    <AlertCircle className="w-4 h-4" />
                                    {error}
                                </div>
                            )}
                        </div>

                        <div className="flex gap-4">
                            {showMainContent && (
                                <button
                                    onClick={() => {
                                        setShowSelection(true);
                                        setShowMainContent(false);
                                    }}
                                    className="px-6 py-3 border rounded-lg hover:bg-gray-50"
                                >
                                    Back
                                </button>
                            )}
                            <button
                                onClick={handleQuickSubmit}
                                disabled={isSubmitting}
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="w-5 h-5 animate-spin" />
                                        <span>Saving...</span>
                                    </>
                                ) : (
                                    <span>{showMainContent ? 'Post Job' : 'Continue'}</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDescriptionModal;