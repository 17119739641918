// SortableResumeSection.js
import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    ChevronDown,
    Edit2,
    Calendar,
    MapPin,
    Mail,
    Phone,
    Link,
    GripVertical,
    X
} from 'lucide-react';

const SortableResumeSection = ({
    id,
    title,
    data,
    formContent,
    isExpanded,
    onToggle
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    const renderBasicsPreview = () => {
        const basics = data?.basics || {};
        return (
            <div className="space-y-4 p-4">
                <div className="flex justify-between items-start">
                    <div>
                        <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                            {basics.name || 'Your Name'}
                        </h3>
                        <p className="text-lg text-gray-600 dark:text-gray-400">
                            {basics.label || 'Professional Title'}
                        </p>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    {basics.email && (
                        <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                            <Mail className="w-4 h-4" />
                            <span>{basics.email}</span>
                        </div>
                    )}
                    {basics.phone && (
                        <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                            <Phone className="w-4 h-4" />
                            <span>{basics.phone}</span>
                        </div>
                    )}
                    {basics.location?.city && (
                        <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                            <MapPin className="w-4 h-4" />
                            <span>
                                {`${basics.location.city}${basics.location.region ? `, ${basics.location.region}` : ''}`}
                            </span>
                        </div>
                    )}
                    {basics.url && (
                        <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                            <Link className="w-4 h-4" />
                            <span>{basics.url}</span>
                        </div>
                    )}
                </div>

                {basics.summary && (
                    <p className="text-gray-700 dark:text-gray-300 text-sm">
                        {basics.summary}
                    </p>
                )}
            </div>
        );
    };

    const renderWorkPreview = () => {
        const work = data?.work || [];
        return (
            <div className="space-y-6 p-4">
                {work.map((job, index) => (
                    <div
                        key={job.id || index}
                        className="relative pl-4 border-l-2 border-gray-200 dark:border-gray-700"
                    >
                        <div className="flex justify-between items-start">
                            <div>
                                <h4 className="font-semibold text-gray-900 dark:text-gray-100">
                                    {job.title || 'Position Title'}
                                </h4>
                                <p className="text-gray-600 dark:text-gray-400">
                                    {job.company || 'Company Name'}
                                </p>
                            </div>
                            <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                                <Calendar className="w-4 h-4" />
                                <span>{job.startDate || 'Start'} - {job.endDate || 'Present'}</span>
                            </div>
                        </div>
                        {job.description && (
                            <p className="mt-2 text-gray-700 dark:text-gray-300 text-sm">
                                {job.description}
                            </p>
                        )}
                    </div>
                ))}
                {work.length === 0 && (
                    <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                        No work experience added yet
                    </p>
                )}
            </div>
        );
    };

    const renderEducationPreview = () => {
        const education = data?.education || [];
        return (
            <div className="space-y-6 p-4">
                {education.map((edu, index) => (
                    <div
                        key={edu.id || index}
                        className="relative pl-4 border-l-2 border-gray-200 dark:border-gray-700"
                    >
                        <div className="flex justify-between items-start">
                            <div>
                                <h4 className="font-semibold text-gray-900 dark:text-gray-100">
                                    {edu.institution || 'Institution'}
                                </h4>
                                <p className="text-gray-600 dark:text-gray-400">
                                    {edu.degree || 'Degree'}
                                </p>
                            </div>
                            <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                                <Calendar className="w-4 h-4" />
                                <span>{edu.startDate || 'Start'} - {edu.endDate || 'Present'}</span>
                            </div>
                        </div>
                        {edu.gpa && (
                            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                GPA: {edu.gpa}
                            </p>
                        )}
                    </div>
                ))}
                {education.length === 0 && (
                    <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                        No education added yet
                    </p>
                )}
            </div>
        );
    };

    const renderSkillsPreview = () => {
        // Make sure to properly access the skills array and provide a default empty array
        const skills = Array.isArray(data?.skills) ? data.skills :
            Array.isArray(data?.skills?.skills) ? data.skills.skills : [];

        return (
            <div className="flex flex-wrap gap-2 p-4">
                {skills.length > 0 ? (
                    skills.map((skill, index) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded-full 
                                     text-sm text-gray-700 dark:text-gray-300"
                        >
                            {skill}
                        </span>
                    ))
                ) : (
                    <p className="text-gray-500 dark:text-gray-400 text-center w-full py-4">
                        No skills added yet
                    </p>
                )}
            </div>
        );
    };

    const renderProjectsPreview = () => {
        const projects = data?.projects || [];
        return (
            <div className="space-y-6 p-4">
                {projects.map((project, index) => (
                    <div
                        key={project.id || index}
                        className="relative pl-4 border-l-2 border-gray-200 dark:border-gray-700"
                    >
                        <div className="flex justify-between items-start">
                            <h4 className="font-semibold text-gray-900 dark:text-gray-100">
                                {project.name || 'Project Name'}
                            </h4>
                            {project.url && (
                                <a
                                    href={project.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 dark:text-blue-400 hover:underline"
                                >
                                    <Link className="w-4 h-4" />
                                </a>
                            )}
                        </div>
                        {project.description && (
                            <p className="mt-2 text-gray-700 dark:text-gray-300 text-sm">
                                {project.description}
                            </p>
                        )}
                        {project.technologies?.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-2">
                                {project.technologies.map((tech, i) => (
                                    <span
                                        key={i}
                                        className="px-2 py-1 bg-gray-100 dark:bg-gray-700 
                                                 rounded-full text-xs text-gray-600 dark:text-gray-400"
                                    >
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                {projects.length === 0 && (
                    <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                        No projects added yet
                    </p>
                )}
            </div>
        );
    };

    const renderPreview = () => {
        switch (title) {
            case 'basics':
                return renderBasicsPreview();
            case 'work':
                return renderWorkPreview();
            case 'education':
                return renderEducationPreview();
            case 'skills':
                return renderSkillsPreview();
            case 'projects':
                return renderProjectsPreview();
            default:
                return null;
        }
    };

    return (
        <div ref={setNodeRef} style={style}>
            <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                    <div
                        {...attributes}
                        {...listeners}
                        className="cursor-grab active:cursor-grabbing mr-2"
                    >
                        <GripVertical className="w-5 h-5 text-gray-400" />
                    </div>

                    <button
                        onClick={onToggle}
                        className="flex items-center gap-2 flex-1"
                    >
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 capitalize">
                            {title}
                        </h3>
                        <ChevronDown
                            className={`w-5 h-5 text-gray-500 transition-transform ${isExpanded ? 'transform rotate-180' : ''
                                }`}
                        />
                    </button>

                    <button
                        onClick={() => setIsEditing(!isEditing)}
                        className="ml-auto p-2 text-gray-500 hover:text-gray-700 
                                 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 
                                 rounded-lg transition-colors"
                    >
                        {isEditing ? (
                            <X className="w-5 h-5" />
                        ) : (
                            <Edit2 className="w-5 h-5" />
                        )}
                    </button>
                </div>

                {isExpanded && (
                    <div className="border-t border-gray-200 dark:border-gray-700">
                        {isEditing ? (
                            <div className="p-4">{formContent}</div>
                        ) : (
                            <div
                                className="hover:bg-gray-50 dark:hover:bg-gray-700/50 cursor-pointer 
                                         transition-colors rounded-b-lg"
                                onClick={() => setIsEditing(true)}
                            >
                                {renderPreview()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SortableResumeSection;