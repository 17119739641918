import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import EmailVerificationModal from '../Components/EmailVerificationModal';
import {
    ArrowRight,
    CheckCircle2,
    Zap,
    FileText,
    AlertCircle,
    Search,
    Users,
    Star,
    Mail,
    X,
    Info
} from 'lucide-react';

// Pricing Module Component
const PricingModule = ({ onSelectPlan }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <section className="py-12 bg-white relative overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className={`text-center mb-12 transform transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}>
                    <h2 className="text-3xl font-bold text-gray-900">Choose Your Plan</h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Start with a free trial or upgrade to unlock full features
                    </p>
                </div>

                <div className="grid md:grid-cols-4 gap-8">
                    {[
                        {
                            title: "Trial",
                            price: "Free",
                            features: ["Unlimited resumes for 2 weeks", "Email support", "Starter Templates"],
                            buttonText: "Start Trial",
                            delay: 200
                        },
                        {
                            title: "Starter",
                            price: "$159",
                            features: ["300 resumes/month", "50/month", "5 team members", "Upload Custom Templates"],
                            buttonText: "Get Started",
                            delay: 400
                        },
                        {
                            title: "Pro",
                            price: "$499",
                            features: ["1500 resumes/month", "200/month", "10 team members", "Upload Custom Templates"],
                            buttonText: "Upgrade to Pro",
                            isPro: true,
                            delay: 600
                        },
                        {
                            title: "Enterprise",
                            price: "Custom",
                            features: ["Unlimited resumes", "Unlimited calls", "Custom features", "Upload Custom Templates", "Dedicated support"],
                            buttonText: "Contact Sales",
                            delay: 800
                        }
                    ].map((plan, index) => (
                        <div
                            key={index}
                            className={`transform transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
                                } ${plan.isPro
                                    ? 'border-2 border-blue-500 bg-blue-50 relative rounded-lg p-8 hover:scale-105'
                                    : 'border rounded-lg p-8 hover:border-blue-500 hover:scale-105'
                                }`}
                            style={{ transitionDelay: `${plan.delay}ms` }}
                        >
                            {plan.isPro && (
                                <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
                                    <span className="bg-blue-500 text-white text-xs px-3 py-1 rounded-full animate-pulse">
                                        RECOMMENDED
                                    </span>
                                </div>
                            )}
                            <h3 className="text-xl font-semibold mb-4">{plan.title}</h3>
                            <p className="text-4xl font-bold mb-8">{plan.price}</p>
                            <ul className="space-y-4 mb-8">
                                {plan.features.map((feature, idx) => (
                                    <li key={idx} className="flex items-center">
                                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                                        <span>{feature}</span>
                                    </li>
                                ))}
                            </ul>
                            <button
                                onClick={() => onSelectPlan(plan.title.toLowerCase())}
                                className={`w-full py-2 px-4 ${plan.isPro
                                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                                    : 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                                    } rounded transition-colors duration-300`}
                            >
                                {plan.buttonText}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

// Notification Modal Component
const NotificationModal = ({ isOpen, onClose, title, message }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="notification-modal" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={onClose}
                ></div>

                <div className="relative inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div className="absolute right-4 top-4">
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500 transition-colors duration-300"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                            <Info className="h-6 w-6 text-blue-600" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                {title}
                            </h3>
                            <p className="text-sm text-gray-500">
                                {message}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm transition-colors duration-300"
                            onClick={onClose}
                        >
                            Got it
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Auth Modal Component
const AuthModal = ({ showAuthModal, setShowAuthModal, isLogin, setIsLogin, email, setEmail, password, setPassword, loading, error, handleEmailAuth, handleGoogleLogin }) => {
    if (!showAuthModal) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setShowAuthModal(false)}></div>

                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div className="absolute right-4 top-4">
                        <button
                            onClick={() => setShowAuthModal(false)}
                            className="text-gray-400 hover:text-gray-500 transition-colors duration-300"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                            <h3 className="text-2xl font-bold text-gray-900 mb-6">
                                {isLogin ? 'Welcome Back' : 'Create Account'}
                            </h3>

                            <form onSubmit={handleEmailAuth} className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                                        required
                                    />
                                </div>

                                {error && (
                                    <div className="text-red-500 text-sm">{error}</div>
                                )}

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300 disabled:opacity-50"
                                >
                                    {loading ? 'Processing...' : (isLogin ? 'Sign In' : 'Sign Up')}
                                </button>

                                <div className="text-center">
                                    <button
                                        type="button"
                                        onClick={() => setIsLogin(!isLogin)}
                                        className="text-blue-600 hover:underline text-sm transition-colors duration-300"
                                    >
                                        {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Sign in'}
                                    </button>
                                </div>

                                <div className="relative my-6">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"></div>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">Or continue with</span>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    onClick={handleGoogleLogin}
                                    className="w-full py-2 px-4 border border-gray-300 rounded-lg flex items-center justify-center space-x-2 hover:bg-gray-50 transition-colors duration-300"
                                >
                                    <img
                                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                        alt="Google"
                                        className="w-5 h-5"
                                    />
                                    <span>Continue with Google</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Main Landing Page Component
export default function LandingPage() {
    const navigate = useNavigate();
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({ title: '', message: '' });
    const [isVisible, setIsVisible] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(false);

    // Calendly Integration
    const initCalendly = () => {
        if (!document.getElementById('calendly-script')) {
            const link = document.createElement('link');
            link.href = 'https://assets.calendly.com/assets/external/widget.css';
            link.rel = 'stylesheet';
            document.head.appendChild(link);

            const script = document.createElement('script');
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.id = 'calendly-script';
            script.async = true;
            document.body.appendChild(script);
        }
    };

    useEffect(() => {
        setIsVisible(true);
        initCalendly();
    }, []);

    const handleDemoClick = (e) => {
        e.preventDefault();
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/sam-resumeably/resumeably-demo'
            });
        }
    };

    const handleEmailAuth = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Check if email is a Gmail address
        if (email.toLowerCase().endsWith('@gmail.com')) {
            setLoading(false);
            setShowAuthModal(false);
            setNotificationMessage({
                title: 'Use Google Sign In',
                message: "We noticed you're using a Gmail address. For a better experience, please use the 'Continue with Google' option to sign in directly with your Google account.",
            });
            setShowNotification(true);

            // After 3 seconds, close the notification and reopen auth modal with Google section highlighted
            setTimeout(() => {
                setShowNotification(false);
                setShowAuthModal(true);
            }, 3000);

            return;
        }

        try {
            let response;
            if (isLogin) {
                response = await supabase.auth.signInWithPassword({
                    email,
                    password,
                });
            } else {
                response = await supabase.auth.signUp({
                    email,
                    password,
                });
            }

            if (response.error) throw response.error;

            // For signup flow, we need to check if confirmation email was sent
            if (!isLogin && response.data?.user?.confirmation_sent_at) {
                console.log('Email verification needed, showing modal');
                setShowAuthModal(false);
                setShowVerificationModal(true);
            } else if (response.data?.user) {
                const { data: membership } = await supabase
                    .from('agency_members')
                    .select('*')
                    .eq('user_id', response.data.user.id)
                    .single();

                if (membership) {
                    localStorage.setItem('has_agency', 'true');
                    navigate('/dashboard');
                } else {
                    navigate('/onboarding');
                }
            }
        } catch (error) {
            console.error('Auth error:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResendEmail = async () => {
        try {
            const { error } = await supabase.auth.resend({
                type: 'signup',
                email: email,
            });
            if (error) throw error;

            // Show brief success message
            setNotificationMessage({
                title: 'Email Sent',
                message: 'A new verification email has been sent to your inbox.'
            });
            setShowNotification(true);
        } catch (error) {
            console.error('Error resending email:', error.message);
            setError(error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/dashboard`
                }
            });
            if (error) throw error;
        } catch (error) {
            console.error('Error logging in:', error.message);
            setError(error.message);
        }
    };

    const AuthButtons = () => (
        <div className="flex items-center space-x-4">
            <button
                onClick={() => {
                    setIsLogin(true);
                    setShowAuthModal(true);
                }}
                className="px-6 py-2 text-gray-700 hover:text-gray-900 transition-colors duration-300"
            >
                Sign In
            </button>
            <button
                onClick={() => {
                    setIsLogin(false);
                    setShowAuthModal(true);
                }}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transform hover:scale-105 transition-all duration-300"
            >
                Sign Up
            </button>
        </div>
    );
    const RotatingWords = () => {
        const words = ['📝', '📞'];
        const phrases = [
            'AI resume formatting tool',
            <>
                AI note-taking for{' '} teams, zoom, gmeet
            </>,
            'AI scribe for recruiters',
            '2x more resumes than allsorter*',
        ];

        const [currentWordIndex, setCurrentWordIndex] = useState(0);
        const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
        const [isWordAnimating, setIsWordAnimating] = useState(false);
        const [isPhraseAnimating, setIsPhraseAnimating] = useState(false);

        useEffect(() => {
            const wordInterval = setInterval(() => {
                setIsWordAnimating(true);
                setTimeout(() => {
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                    setIsWordAnimating(false);
                }, 600);
            }, 3500);

            const phraseInterval = setInterval(() => {
                setIsPhraseAnimating(true);
                setTimeout(() => {
                    setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
                    setIsPhraseAnimating(false);
                }, 400);
            }, 3500);

            return () => {
                clearInterval(wordInterval);
                clearInterval(phraseInterval);
            };
        }, []);

        return (
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 mb-4 sm:mb-8 leading-tight">
                <div className="mb-2 sm:mb-0">
                    Parse candidate{' '}
                    <span
                        className={`relative inline-block text-teal-500 transition-transform duration-400 ease-in-out ${isWordAnimating ? 'transform -translate-y-full opacity-0' : 'transform translate-y-0 opacity-100'
                            }`}
                        style={{
                            display: 'inline-block',
                            minHeight: '1.2em',
                            lineHeight: '1.2',
                        }}
                    >
                        {words[currentWordIndex]}
                    </span>{' '}
                    <span className="text-orange-400 font-extrabold italic animate-pulse">
                        faster
                    </span>{' '}
                    <span className="hidden sm:inline">with AI</span>
                </div>
                <div className="sm:hidden">with AI</div>
                <div className="mt-2 sm:mt-4">
                    <span
                        className={`relative inline-block text-transparent bg-gradient-to-r from-purple-500 via-blue-500 to-green-500 bg-clip-text transition-transform duration-400 ease-in-out ${isPhraseAnimating ? 'transform -translate-x-full opacity-0' : 'transform translate-x-0 opacity-100'
                            }`}
                        style={{
                            display: 'inline-block',
                            minHeight: '1.2em',
                            lineHeight: '1.4',
                        }}
                    >
                        {phrases[currentPhraseIndex]}
                    </span>
                </div>
            </h1>
        );
    };


    return (
        <div className="min-h-screen bg-white overflow-hidden">
            {/* Animated Background */}
            <div className="fixed inset-0 bg-gradient-to-br from-blue-50 via-white to-purple-50 animate-gradient-xy"></div>

            <div className="relative">
                <AuthModal
                    showAuthModal={showAuthModal}
                    setShowAuthModal={setShowAuthModal}
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    loading={loading}
                    error={error}
                    handleEmailAuth={handleEmailAuth}
                    handleGoogleLogin={handleGoogleLogin}
                />
                <NotificationModal
                    isOpen={showNotification}
                    onClose={() => setShowNotification(false)}
                    title={notificationMessage.title}
                    message={notificationMessage.message}
                />
                <EmailVerificationModal
                    isOpen={showVerificationModal}
                    onClose={() => setShowVerificationModal(false)}
                    email={email}
                    onResendEmail={handleResendEmail}
                />

                {/* Header */}
                <header className="relative bg-transparent">
                    <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                        <div className="flex justify-between items-center">
                            <div className={`flex items-center space-x-2 transform transition-all duration-1000 ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>
                                <FileText className="w-8 h-8 text-blue-600 animate-pulse" />
                                <span className="text-2xl font-bold text-gray-900 hover:text-blue-600 transition-colors duration-300">Resumeably</span>
                            </div>
                            <div className={`transform transition-all duration-1000 delay-300 ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
                                <AuthButtons />
                            </div>
                        </div>
                    </nav>

                    {/* Hero Section */}
                    <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-10 py-24 text-center">
                        <div className={`transform transition-all duration-1000 delay-500 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}>
                            <RotatingWords />

                            {/* Call to Action Buttons */}
                            <div className="flex justify-center gap-6 mb-12">
                                <button
                                    onClick={() => setShowAuthModal(true)}
                                    className="px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-full hover:bg-blue-700 transform hover:scale-105 transition-all duration-300 shadow-lg"
                                >
                                    Try Free Today
                                </button>
                                <button
                                    onClick={handleDemoClick}
                                    className="px-8 py-4 bg-white text-blue-600 text-lg font-semibold rounded-full border-2 border-blue-600 hover:bg-blue-50 transform hover:scale-105 transition-all duration-300 shadow-lg"
                                >
                                    Book a Demo
                                </button>
                            </div>
                        </div>

                        {/* Features Carousel */}
                        <div className="relative w-full overflow-hidden py-12">
                            <div className="animate-carousel flex gap-8">
                                {[
                                    "Instant resume formatting ✨",
                                    "Extract candidate call data 📞",
                                    "Auto-Parse Resumes with AI 🚀",
                                    "400+ resume templates 📝",
                                    "Auto Redact PII / PHI 🔒",
                                    "Candidate skill matching 🎯",
                                    "Bulk resume processing seconds ⚡️",
                                    "We support small agencies ❤️",
                                    "Easy candidate data export to your ATS 📊",
                                ].map((text, index) => (
                                    <div
                                        key={index}
                                        className="flex-none w-80 transform transition-all duration-300 hover:scale-105"
                                    >
                                        <div className="relative group">
                                            <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-teal-500 rounded-xl blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
                                            <div className="relative bg-white px-6 py-8 rounded-xl shadow-lg border border-gray-200 hover:border-blue-300 transition-all duration-300">
                                                <p className="text-gray-800 font-medium text-lg">
                                                    {text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* Duplicate items for seamless loop */}
                                {[
                                    "Instant resume formatting ✨",
                                    "Extract candidate call data 📞",
                                    "Auto-Parse Resumes with AI 🚀",
                                    "400+ resume templates 📝",
                                    "Auto Redact PII / PHI 🔒",
                                    "Candidate skill matching 🎯",
                                    "Bulk resume processing seconds ⚡️",
                                    "We support small agencies ❤️",
                                    "Easy candidate data export to your ATS 📊",
                                ].map((text, index) => (
                                    <div
                                        key={`duplicate-${index}`}
                                        className="flex-none w-80 transform transition-all duration-300 hover:scale-105"
                                    >
                                        <div className="relative group">
                                            <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-teal-500 rounded-xl blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
                                            <div className="relative bg-white px-6 py-8 rounded-xl shadow-lg border border-gray-200 hover:border-blue-300 transition-all duration-300">
                                                <p className="text-gray-800 font-medium text-lg">
                                                    {text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </header>

                {/* Features Section */}
                <PricingModule onSelectPlan={(plan) => {
                    setShowAuthModal(true);
                    setIsLogin(false);
                }} />

                {/* Footer */}
                <footer className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="grid md:grid-cols-4 gap-8">
                            <div className={`transform transition-all duration-700 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}>
                                <div className="flex items-center space-x-2 mb-4">
                                    <FileText className="w-6 h-6 text-blue-600" />
                                    <span className="text-xl font-bold">Resumeably</span>
                                </div>
                                <p className="text-gray-600">
                                    Making resume processing easy and efficient.
                                </p>
                            </div>
                            {["Product", "Company", "Resources"].map((column, index) => (
                                <div
                                    key={column}
                                    className={`transform transition-all duration-700 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}
                                    style={{ transitionDelay: `${200 + (index * 100)}ms` }}
                                >
                                    <h4 className="font-semibold mb-4">{column}</h4>
                                    <ul className="space-y-2">
                                        {["Features", "Pricing", "About", "Contact"].map((item) => (
                                            <li key={item}>
                                                <a href="#" className="text-gray-600 hover:text-gray-900 transition-colors duration-300">
                                                    {item}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className="border-t mt-12 pt-8 text-center text-gray-600">
                            <p>© 2024 Resumeably. All rights reserved.</p>
                        </div>
                    </div>
                </footer>

                {/* Global Styles */}
                <style jsx>{`
                    @keyframes carousel {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(-50%);
                        }
                    }
                    .animate-carousel {
                        animation: carousel 20s linear infinite;
                    }
                    .animate-carousel:hover {
                        animation-play-state: paused;
                    }
                    @keyframes gradient-xy {
                        0%, 100% {
                            background-position: 0% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                    }
                    .animate-gradient-xy {
                        animation: gradient-xy 15s ease infinite;
                        background-size: 400% 400%;
                    }
                `}</style>
            </div>
        </div>
    );
}