import { useState, useEffect } from 'react';
import {
    Briefcase, Users, Link, Star, Filter,
    Search, Plus, ChevronRight, ArrowUpRight,
    Clock, CheckCircle, Trophy, UserCheck
} from 'lucide-react';

const RecruiterDashboard = () => {
    const [activeView, setActiveView] = useState('jobs'); // 'jobs', 'candidates'
    const [selectedJob, setSelectedJob] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [stats, setStats] = useState({
        totalJobs: 0,
        totalCandidates: 0,
        avgMatchScore: 0
    });

    // Sample data - replace with Supabase fetch
    const [jobs, setJobs] = useState([
        {
            id: 1,
            title: 'Senior Frontend Engineer',
            department: 'Engineering',
            location: 'San Francisco, CA',
            postedDate: '2024-03-15',
            status: 'active',
            candidates: [
                { id: 1, name: 'Alex Johnson', matchScore: 92, status: 'reviewed' },
                { id: 2, name: 'Sarah Chen', matchScore: 88, status: 'pending' }
            ]
        },
        {
            id: 2,
            title: 'Product Manager',
            department: 'Product',
            location: 'Remote',
            postedDate: '2024-03-14',
            status: 'active',
            candidates: [
                { id: 3, name: 'Michael Lee', matchScore: 95, status: 'interviewed' }
            ]
        }
    ]);

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50 p-6">
            {/* Header */}
            <header className="mb-6 rounded-xl bg-white p-4 shadow-sm">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div className="rounded-xl bg-purple-600 p-2 text-white">
                            <Briefcase className="h-6 w-6" />
                        </div>
                        <div>
                            <h1 className="text-xl font-bold text-gray-900">Recruiter Dashboard</h1>
                            <p className="text-sm text-gray-600">Manage your jobs and candidates</p>
                        </div>
                    </div>

                    <button
                        onClick={() => { }}
                        className="flex items-center gap-2 rounded-lg bg-purple-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700"
                    >
                        <Plus className="h-4 w-4" />
                        Post New Job
                    </button>
                </div>
            </header>

            {/* Main Content */}
            <div className="grid gap-6 lg:grid-cols-[2fr_1fr]">
                {/* Left Column - Main Content */}
                <div className="space-y-6">
                    {/* Search and Filters */}
                    <div className="flex items-center gap-4">
                        <div className="relative flex-1">
                            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search jobs..."
                                className="w-full rounded-lg border border-gray-200 bg-white pl-10 pr-4 py-2 text-sm outline-none focus:border-purple-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button className="rounded-lg border border-gray-200 bg-white p-2 hover:bg-gray-50">
                            <Filter className="h-4 w-4 text-gray-600" />
                        </button>
                    </div>

                    {/* Jobs List */}
                    <div className="rounded-xl bg-white p-6 shadow-sm">
                        <h2 className="mb-4 text-lg font-semibold text-gray-900">Active Jobs</h2>
                        <div className="space-y-4">
                            {jobs.map((job) => (
                                <div
                                    key={job.id}
                                    className="cursor-pointer rounded-lg border border-gray-200 p-4 transition-all hover:border-purple-500 hover:shadow-sm"
                                    onClick={() => {
                                        setSelectedJob(job);
                                        setActiveView('candidates');
                                    }}
                                >
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h3 className="font-medium text-gray-900">{job.title}</h3>
                                            <div className="mt-1 flex items-center gap-4 text-sm text-gray-600">
                                                <span>{job.department}</span>
                                                <span>•</span>
                                                <span>{job.location}</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <p className="text-sm font-medium text-gray-900">
                                                {job.candidates.length} candidates
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Posted {new Date(job.postedDate).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>

                                    {job.candidates.length > 0 && (
                                        <div className="mt-4 border-t border-gray-100 pt-4">
                                            <p className="mb-2 text-sm font-medium text-gray-700">Top Matches</p>
                                            <div className="flex flex-wrap gap-2">
                                                {job.candidates
                                                    .sort((a, b) => b.matchScore - a.matchScore)
                                                    .slice(0, 3)
                                                    .map((candidate) => (
                                                        <div
                                                            key={candidate.id}
                                                            className="flex items-center gap-2 rounded-full bg-purple-50 px-3 py-1 text-sm text-purple-600"
                                                        >
                                                            <Star className="h-4 w-4" />
                                                            {candidate.name} ({candidate.matchScore}%)
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    )}

                                    <div className="mt-4 flex gap-2">
                                        <button className="flex items-center gap-1 rounded-lg bg-purple-100 px-3 py-1 text-sm text-purple-600 hover:bg-purple-200">
                                            <Link className="h-4 w-4" />
                                            Generate Link
                                        </button>
                                        <button className="flex items-center gap-1 rounded-lg bg-gray-100 px-3 py-1 text-sm text-gray-600 hover:bg-gray-200">
                                            <ArrowUpRight className="h-4 w-4" />
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right Column - Stats and Activity */}
                <div className="space-y-6">
                    {/* Quick Stats */}
                    <div className="rounded-xl bg-white p-6 shadow-sm">
                        <h3 className="mb-4 font-medium text-gray-900">Quick Stats</h3>
                        <div className="grid gap-4">
                            <div className="flex items-center justify-between rounded-lg bg-purple-50 p-4">
                                <div className="flex items-center gap-3">
                                    <div className="rounded-lg bg-purple-100 p-2">
                                        <Briefcase className="h-5 w-5 text-purple-600" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-600">Active Jobs</p>
                                        <p className="text-lg font-semibold text-purple-600">{jobs.length}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-between rounded-lg bg-blue-50 p-4">
                                <div className="flex items-center gap-3">
                                    <div className="rounded-lg bg-blue-100 p-2">
                                        <Users className="h-5 w-5 text-blue-600" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-600">Total Candidates</p>
                                        <p className="text-lg font-semibold text-blue-600">
                                            {jobs.reduce((sum, job) => sum + job.candidates.length, 0)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-between rounded-lg bg-green-50 p-4">
                                <div className="flex items-center gap-3">
                                    <div className="rounded-lg bg-green-100 p-2">
                                        <Trophy className="h-5 w-5 text-green-600" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-600">Avg Match Score</p>
                                        <p className="text-lg font-semibold text-green-600">87%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Recent Activity */}
                    <div className="rounded-xl bg-white p-6 shadow-sm">
                        <h3 className="mb-4 font-medium text-gray-900">Recent Activity</h3>
                        <div className="space-y-4">
                            {jobs.flatMap(job =>
                                job.candidates.slice(0, 2).map(candidate => ({
                                    jobTitle: job.title,
                                    candidateName: candidate.name,
                                    action: 'applied',
                                    timestamp: new Date()
                                }))
                            )
                                .slice(0, 5)
                                .map((activity, index) => (
                                    <div key={index} className="flex items-start gap-3">
                                        <div className="rounded-full bg-purple-100 p-2">
                                            <UserCheck className="h-4 w-4 text-purple-600" />
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-900">
                                                {activity.candidateName} {activity.action} for {activity.jobTitle}
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                {activity.timestamp.toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruiterDashboard;