// import React, { useState } from 'react';
// import { Sparkles, Loader2, Target } from 'lucide-react';
// import TextEditor from './TextEditor';

// const SummaryEditor = ({ content, onChange, fullResume, jobDescription }) => {
//     const [isGenerating, setIsGenerating] = useState(false);

//     const handleGenerateFromResume = async () => {
//         setIsGenerating(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/generate/summary', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ resume: fullResume })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.generated_summary);
//             }
//         } catch (error) {
//             console.error('Failed to generate summary:', error);
//         } finally {
//             setIsGenerating(false);
//         }
//     };

//     const handleTailorToJob = async () => {
//         setIsGenerating(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/tailor/summary', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     current_summary: content,
//                     job_description: jobDescription
//                 })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.tailored_summary);
//             }
//         } catch (error) {
//             console.error('Failed to tailor summary:', error);
//         } finally {
//             setIsGenerating(false);
//         }
//     };

//     return (
//         <div className="space-y-4">
//             <TextEditor
//                 content={content}
//                 onChange={onChange}
//             />

//             <div className="border-t border-gray-200 dark:border-gray-700 p-4">
//                 <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
//                     Summary Generation Tools
//                 </h3>
//                 <div className="grid grid-cols-2 gap-3">
//                     <button
//                         onClick={handleGenerateFromResume}
//                         disabled={isGenerating}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isGenerating
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
//                             }
//                             transition-all duration-200
//                         `}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isGenerating ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Sparkles className="w-6 h-6 text-blue-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Generate from Resume
//                         </span>
//                     </button>

//                     <button
//                         onClick={handleTailorToJob}
//                         disabled={isGenerating || !jobDescription}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isGenerating || !jobDescription
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-emerald-50 dark:hover:bg-emerald-900/30 hover:border-emerald-300 dark:hover:border-emerald-500'
//                             }
//                             transition-all duration-200
//                         `}
//                         title={!jobDescription ? "Add a job description to enable this feature" : ""}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isGenerating ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Target className="w-6 h-6 text-emerald-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Tailor to Job
//                         </span>
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SummaryEditor;

// import React, { useState, useCallback, useRef } from 'react';
// import {
//     Bold, Italic, Underline, List, RotateCcw, RotateCw,
//     Loader2, Sparkles, Type, Layout, Hash, RefreshCw,
//     Target
// } from 'lucide-react';
// import { STYLE_UI_CONFIG } from '../constants';

// const NGROK_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// const ControlGroup = ({ title, children }) => (
//     <div className="flex items-center gap-2">
//         <span className="text-sm text-gray-500 dark:text-gray-400 font-medium">{title}</span>
//         <div className="flex items-center gap-1 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//             {children}
//         </div>
//     </div>
// );

// const SummaryEditor = ({ content, onChange, fullResume, jobDescription }) => {
//     const [selectedFormat, setSelectedFormat] = useState(null);
//     const [history, setHistory] = useState([content]);
//     const [historyIndex, setHistoryIndex] = useState(0);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [activeTab, setActiveTab] = useState('text');
//     const [autoBulletEnabled, setAutoBulletEnabled] = useState(true);
//     const [originalText, setOriginalText] = useState(content);
//     const [textFormat, setTextFormat] = useState({
//         bold: false,
//         italic: false,
//         underline: false
//     });

//     const editorRef = useRef(null);

//     const handleFormat = (type) => {
//         setTextFormat(prev => ({
//             ...prev,
//             [type]: !prev[type]
//         }));

//         const selection = window.getSelection();
//         const selectedText = selection?.toString();
//         if (selectedText) {
//             const newText = content.replace(selectedText, formatText(selectedText, type));
//             onChange(newText);
//             addToHistory(newText);
//         }
//     };

//     const formatText = (text, type) => {
//         switch (type) {
//             case 'bold':
//                 return `**${text}**`;
//             case 'italic':
//                 return `*${text}*`;
//             case 'underline':
//                 return `_${text}_`;
//             default:
//                 return text;
//         }
//     };

//     const addToHistory = useCallback((newContent) => {
//         setHistory(prev => {
//             const newHistory = prev.slice(0, historyIndex + 1);
//             newHistory.push(newContent);
//             return newHistory;
//         });
//         setHistoryIndex(prev => prev + 1);
//     }, [historyIndex]);

//     const handleUndo = useCallback(() => {
//         if (historyIndex > 0) {
//             setHistoryIndex(prev => prev - 1);
//             onChange(history[historyIndex - 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const handleRedo = useCallback(() => {
//         if (historyIndex < history.length - 1) {
//             setHistoryIndex(prev => prev + 1);
//             onChange(history[historyIndex + 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const restoreOriginal = useCallback(() => {
//         onChange(originalText);
//         addToHistory(originalText);
//     }, [originalText, onChange, addToHistory]);

//     const handleKeyDown = useCallback((e) => {
//         if (autoBulletEnabled && e.key === 'Enter') {
//             e.preventDefault();
//             const lines = content.split('\n');
//             const lastLine = lines[lines.length - 1];
//             const bulletMatch = lastLine.match(/^(\s*)([-•*])\s/);

//             if (bulletMatch) {
//                 const [, spacing, bullet] = bulletMatch;
//                 const isEmpty = lastLine.trim() === bullet.trim();

//                 if (isEmpty) {
//                     lines.pop();
//                 } else {
//                     lines.push(`${spacing}${bullet} `);
//                 }

//                 const newContent = lines.join('\n');
//                 onChange(newContent);
//                 addToHistory(newContent);
//             }
//         }
//     }, [autoBulletEnabled, content, onChange, addToHistory]);

//     const handleAIApply = async (preset, type = 'transform') => {
//         if (!preset) return;
//         setIsProcessing(true);

//         try {
//             const selection = window.getSelection();
//             const selectedText = selection?.toString() || '';
//             const textToProcess = selectedText || content;

//             let endpoint;
//             switch (type) {
//                 case 'case':
//                     endpoint = `/api/case-text/${preset}`;
//                     break;
//                 case 'format':
//                     endpoint = `/api/format-text/${preset}`;
//                     break;
//                 case 'list':
//                     endpoint = `/api/list-text/${preset}`;
//                     break;
//                 default:
//                     endpoint = `/api/transform/${preset}`;
//             }

//             const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     text: textToProcess,
//                     style: preset
//                 })
//             });

//             if (!response.ok) {
//                 const error = await response.json();
//                 throw new Error(error.detail || 'AI processing failed');
//             }

//             const result = await response.json();
//             const newContent = selectedText
//                 ? content.replace(selectedText, result.transformed_text)
//                 : result.transformed_text;

//             onChange(newContent);
//             addToHistory(newContent);
//         } catch (err) {
//             console.error('AI processing failed:', err);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleGenerateFromResume = async () => {
//         setIsProcessing(true);
//         try {
//             const response = await fetch(`${NGROK_BASE_URL}/api/generate/summary`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ resume: fullResume })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.generated_summary);
//                 addToHistory(result.generated_summary);
//             }
//         } catch (error) {
//             console.error('Failed to generate summary:', error);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleTailorToJob = async () => {
//         setIsProcessing(true);
//         try {
//             const response = await fetch(`${NGROK_BASE_URL}/api/tailor/summary`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     current_summary: content,
//                     job_description: jobDescription
//                 })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.tailored_summary);
//                 addToHistory(result.tailored_summary);
//             }
//         } catch (error) {
//             console.error('Failed to tailor summary:', error);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const renderStyleSelector = () => {
//         const options = {
//             text: STYLE_UI_CONFIG.TEXT_PRESETS,
//             case: STYLE_UI_CONFIG.CASE_STYLES,
//             format: STYLE_UI_CONFIG.FORMAT_STYLES,
//             list: STYLE_UI_CONFIG.LIST_STYLES
//         }[activeTab] || [];

//         return (
//             <select
//                 className="px-3 py-1 border rounded min-w-[200px] bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                 onChange={(e) => handleAIApply(e.target.value, activeTab)}
//                 value={selectedFormat || ''}
//             >
//                 <option value="">Select Style</option>
//                 {options.map(option => (
//                     <option key={option.value} value={option.value}>
//                         {option.label}
//                     </option>
//                 ))}
//             </select>
//         );
//     };

//     return (
//         <div className="border rounded-lg bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 shadow-sm">
//             <div className="border-b dark:border-gray-700 p-2 space-y-2">
//                 <div className="flex items-center gap-4">
//                     <ControlGroup title="Format">
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.bold ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('bold')}
//                             title="Bold"
//                         >
//                             <Bold size={18} className={`${textFormat.bold ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.italic ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('italic')}
//                             title="Italic"
//                         >
//                             <Italic size={18} className={`${textFormat.italic ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.underline ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('underline')}
//                             title="Underline"
//                         >
//                             <Underline size={18} className={`${textFormat.underline ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="Auto-Bullet">
//                         <button
//                             className={`p-1 rounded tooltip ${autoBulletEnabled ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => setAutoBulletEnabled(!autoBulletEnabled)}
//                             title="Toggle Auto-Bullet"
//                         >
//                             <List size={18} className={`${autoBulletEnabled ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="History">
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleUndo}
//                             disabled={historyIndex === 0}
//                             title="Undo"
//                         >
//                             <RotateCcw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleRedo}
//                             disabled={historyIndex === history.length - 1}
//                             title="Redo"
//                         >
//                             <RotateCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip"
//                             onClick={restoreOriginal}
//                             title="Restore Original"
//                         >
//                             <RefreshCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                     </ControlGroup>
//                 </div>

//                 <div className="flex items-center gap-4">
//                     <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'text' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('text')}
//                         >
//                             <Type size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Text</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'case' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('case')}
//                         >
//                             <Hash size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Case</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'format' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('format')}
//                         >
//                             <Layout size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Format</span>
//                         </button>
//                     </div>

//                     {renderStyleSelector()}

//                     <button
//                         onClick={() => selectedFormat && handleAIApply(selectedFormat)}
//                         disabled={isProcessing || !selectedFormat}
//                         className={`
//                             px-3 py-1 rounded flex items-center gap-2
//                             ${isProcessing || !selectedFormat
//                                 ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-400'
//                                 : 'bg-blue-500 hover:bg-blue-600 text-white'
//                             }
//                         `}
//                     >
//                         {isProcessing ? (
//                             <>
//                                 <Loader2 className="w-4 h-4 animate-spin" />
//                                 <span>Processing...</span>
//                             </>
//                         ) : (
//                             <>
//                                 <Sparkles className="w-4 h-4" />
//                                 <span>Apply Transform</span>
//                             </>
//                         )}
//                     </button>
//                 </div>
//             </div>

//             <div className="p-2 bg-white dark:bg-gray-800">
//                 <textarea
//                     ref={editorRef}
//                     value={content}
//                     onChange={(e) => {
//                         onChange(e.target.value);
//                         addToHistory(e.target.value);
//                     }}
//                     onKeyDown={handleKeyDown}
//                     className="w-full min-h-[200px] p-2 border rounded resize-y focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                     placeholder="Enter your professional summary..."
//                 />
//             </div>

//             {/* Summary Generation Tools */}
//             <div className="border-t border-gray-200 dark:border-gray-700 p-4">
//                 <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
//                     Summary Generation Tools
//                 </h3>
//                 <div className="grid grid-cols-2 gap-3">
//                     <button
//                         onClick={handleGenerateFromResume}
//                         disabled={isProcessing}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isProcessing
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
//                             }
//                             transition-all duration-200
//                         `}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isProcessing ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Sparkles className="w-6 h-6 text-blue-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Generate from Resume
//                         </span>
//                     </button>

//                     <button
//                         onClick={handleTailorToJob}
//                         disabled={isProcessing || !jobDescription}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isProcessing || !jobDescription
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-emerald-50 dark:hover:bg-emerald-900/30 hover:border-emerald-300 dark:hover:border-emerald-500'
//                             }
//                             transition-all duration-200
//                         `}
//                         title={!jobDescription ? "Add a job description to enable this feature" : ""}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isProcessing ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Target className="w-6 h-6 text-emerald-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Tailor to Job
//                         </span>
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SummaryEditor;

// import React, { useState, useCallback, useRef } from 'react';
// import {
//     Bold, Italic, Underline, List, RotateCcw, RotateCw,
//     Loader2, Sparkles, Type, Layout, Hash, RefreshCw,
//     Target
// } from 'lucide-react';
// import { STYLE_UI_CONFIG } from '../constants';

// const NGROK_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// const ControlGroup = ({ title, children }) => (
//     <div className="flex items-center gap-2">
//         <span className="text-sm text-gray-500 dark:text-gray-400 font-medium">{title}</span>
//         <div className="flex items-center gap-1 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//             {children}
//         </div>
//     </div>
// );

// const SummaryEditor = ({ content, onChange, fullResume, jobDescription }) => {
//     const [selectedFormat, setSelectedFormat] = useState(null);
//     const [history, setHistory] = useState([content]);
//     const [historyIndex, setHistoryIndex] = useState(0);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [activeTab, setActiveTab] = useState('text');
//     const [autoBulletEnabled, setAutoBulletEnabled] = useState(true);
//     const [originalText, setOriginalText] = useState(content);
//     const [completenessScore, setCompletenessScore] = useState(null);
//     const [textFormat, setTextFormat] = useState({
//         bold: false,
//         italic: false,
//         underline: false
//     });

//     const editorRef = useRef(null);

//     const handleFormat = (type) => {
//         setTextFormat(prev => ({
//             ...prev,
//             [type]: !prev[type]
//         }));

//         const selection = window.getSelection();
//         const selectedText = selection?.toString();
//         if (selectedText) {
//             const newText = content.replace(selectedText, formatText(selectedText, type));
//             onChange(newText);
//             addToHistory(newText);
//         }
//     };

//     const formatText = (text, type) => {
//         switch (type) {
//             case 'bold':
//                 return `**${text}**`;
//             case 'italic':
//                 return `*${text}*`;
//             case 'underline':
//                 return `_${text}_`;
//             default:
//                 return text;
//         }
//     };

//     const addToHistory = useCallback((newContent) => {
//         setHistory(prev => {
//             const newHistory = prev.slice(0, historyIndex + 1);
//             newHistory.push(newContent);
//             return newHistory;
//         });
//         setHistoryIndex(prev => prev + 1);
//     }, [historyIndex]);

//     const handleUndo = useCallback(() => {
//         if (historyIndex > 0) {
//             setHistoryIndex(prev => prev - 1);
//             onChange(history[historyIndex - 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const handleRedo = useCallback(() => {
//         if (historyIndex < history.length - 1) {
//             setHistoryIndex(prev => prev + 1);
//             onChange(history[historyIndex + 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const restoreOriginal = useCallback(() => {
//         onChange(originalText);
//         addToHistory(originalText);
//     }, [originalText, onChange, addToHistory]);

//     const handleKeyDown = useCallback((e) => {
//         if (autoBulletEnabled && e.key === 'Enter') {
//             e.preventDefault();
//             const lines = content.split('\n');
//             const lastLine = lines[lines.length - 1];
//             const bulletMatch = lastLine.match(/^(\s*)([-•*])\s/);

//             if (bulletMatch) {
//                 const [, spacing, bullet] = bulletMatch;
//                 const isEmpty = lastLine.trim() === bullet.trim();

//                 if (isEmpty) {
//                     lines.pop();
//                 } else {
//                     lines.push(`${spacing}${bullet} `);
//                 }

//                 const newContent = lines.join('\n');
//                 onChange(newContent);
//                 addToHistory(newContent);
//             }
//         }
//     }, [autoBulletEnabled, content, onChange, addToHistory]);

//     const analyzeResumeText = async (text) => {
//         try {
//             const response = await fetch(`${NGROK_BASE_URL}/api/analyze/resume_text`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ text })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 return result.completeness_score;
//             }
//             return null;
//         } catch (error) {
//             console.error('Failed to analyze resume:', error);
//             return null;
//         }
//     };

//     const handleGenerateFromResume = async () => {
//         setIsProcessing(true);
//         try {
//             // First analyze the resume text
//             const completeness = await analyzeResumeText(fullResume);
//             setCompletenessScore(completeness);

//             // Generate summary with completeness score
//             const response = await fetch(`${NGROK_BASE_URL}/api/generate/summary`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume: fullResume,
//                     completeness_score: completeness
//                 })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.generated_summary);
//                 addToHistory(result.generated_summary);
//             }
//         } catch (error) {
//             console.error('Failed to generate summary:', error);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleTailorToJob = async () => {
//         setIsProcessing(true);
//         try {
//             const response = await fetch(`${NGROK_BASE_URL}/api/tailor/summary`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     current_summary: content,
//                     job_description: jobDescription
//                 })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.tailored_summary);
//                 addToHistory(result.tailored_summary);
//             }
//         } catch (error) {
//             console.error('Failed to tailor summary:', error);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleAIApply = async (preset, type = 'transform') => {
//         if (!preset) return;
//         setIsProcessing(true);

//         try {
//             const selection = window.getSelection();
//             const selectedText = selection?.toString() || '';
//             const textToProcess = selectedText || content;

//             let endpoint;
//             switch (type) {
//                 case 'case':
//                     endpoint = `/api/case-text/${preset}`;
//                     break;
//                 case 'format':
//                     endpoint = `/api/format-text/${preset}`;
//                     break;
//                 case 'list':
//                     endpoint = `/api/list-text/${preset}`;
//                     break;
//                 default:
//                     endpoint = `/api/transform/${preset}`;
//             }

//             const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     text: textToProcess,
//                     style: preset
//                 })
//             });

//             if (!response.ok) {
//                 const error = await response.json();
//                 throw new Error(error.detail || 'AI processing failed');
//             }

//             const result = await response.json();
//             const newContent = selectedText
//                 ? content.replace(selectedText, result.transformed_text)
//                 : result.transformed_text;

//             onChange(newContent);
//             addToHistory(newContent);
//         } catch (err) {
//             console.error('AI processing failed:', err);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const renderStyleSelector = () => {
//         const options = {
//             text: STYLE_UI_CONFIG.TEXT_PRESETS,
//             case: STYLE_UI_CONFIG.CASE_STYLES,
//             format: STYLE_UI_CONFIG.FORMAT_STYLES,
//             list: STYLE_UI_CONFIG.LIST_STYLES
//         }[activeTab] || [];

//         return (
//             <select
//                 className="px-3 py-1 border rounded min-w-[200px] bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                 onChange={(e) => handleAIApply(e.target.value, activeTab)}
//                 value={selectedFormat || ''}
//             >
//                 <option value="">Select Style</option>
//                 {options.map(option => (
//                     <option key={option.value} value={option.value}>
//                         {option.label}
//                     </option>
//                 ))}
//             </select>
//         );
//     };

//     return (
//         <div className="border rounded-lg bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 shadow-sm">
//             <div className="border-b dark:border-gray-700 p-2 space-y-2">
//                 {/* Formatting Controls */}
//                 <div className="flex items-center gap-4">
//                     <ControlGroup title="Format">
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.bold ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('bold')}
//                             title="Bold"
//                         >
//                             <Bold size={18} className={`${textFormat.bold ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.italic ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('italic')}
//                             title="Italic"
//                         >
//                             <Italic size={18} className={`${textFormat.italic ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.underline ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('underline')}
//                             title="Underline"
//                         >
//                             <Underline size={18} className={`${textFormat.underline ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="Auto-Bullet">
//                         <button
//                             className={`p-1 rounded tooltip ${autoBulletEnabled ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => setAutoBulletEnabled(!autoBulletEnabled)}
//                             title="Toggle Auto-Bullet"
//                         >
//                             <List size={18} className={`${autoBulletEnabled ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="History">
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleUndo}
//                             disabled={historyIndex === 0}
//                             title="Undo"
//                         >
//                             <RotateCcw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleRedo}
//                             disabled={historyIndex === history.length - 1}
//                             title="Redo"
//                         >
//                             <RotateCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip"
//                             onClick={restoreOriginal}
//                             title="Restore Original"
//                         >
//                             <RefreshCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                     </ControlGroup>
//                 </div>

//                 {/* Style Controls */}
//                 <div className="flex items-center gap-4">
//                     <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'text' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('text')}
//                         >
//                             <Type size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Text</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'case' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('case')}
//                         >
//                             <Hash size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Case</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'format' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('format')}
//                         >
//                             <Layout size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Format</span>
//                         </button>
//                     </div>

//                     {renderStyleSelector()}

//                     <button
//                         onClick={() => selectedFormat && handleAIApply(selectedFormat)}
//                         disabled={isProcessing || !selectedFormat}
//                         className={`
//                             px-3 py-1 rounded flex items-center gap-2
//                             ${isProcessing || !selectedFormat
//                                 ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-400'
//                                 : 'bg-blue-500 hover:bg-blue-600 text-white'
//                             }
//                         `}
//                     >
//                         {isProcessing ? (
//                             <>
//                                 <Loader2 className="w-4 h-4 animate-spin" />
//                                 <span>Processing...</span>
//                             </>
//                         ) : (
//                             <>
//                                 <Sparkles className="w-4 h-4" />
//                                 <span>Apply Transform</span>
//                             </>
//                         )}
//                     </button>
//                 </div>
//             </div>

//             {/* Editor Area */}
//             <div className="p-2 bg-white dark:bg-gray-800">
//                 <textarea
//                     ref={editorRef}
//                     value={content}
//                     onChange={(e) => {
//                         onChange(e.target.value);
//                         addToHistory(e.target.value);
//                     }}
//                     onKeyDown={handleKeyDown}
//                     className="w-full min-h-[200px] p-2 border rounded resize-y focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                     placeholder="Enter your professional summary..."
//                 />
//             </div>

//             {/* Summary Generation Tools with Resume Analysis */}
//             <div className="border-t border-gray-200 dark:border-gray-700 p-4">
//                 <div className="flex justify-between items-center mb-3">
//                     <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                         Summary Generation Tools
//                     </h3>
//                     {completenessScore !== null && (
//                         <div className="flex items-center gap-2">
//                             <span className="text-sm text-gray-600 dark:text-gray-400">
//                                 Resume Completeness:
//                             </span>
//                             <div className="flex items-center gap-1">
//                                 <div className="h-2 w-24 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
//                                     <div
//                                         className="h-full bg-blue-500 transition-all duration-300"
//                                         style={{ width: `${completenessScore}%` }}
//                                     />
//                                 </div>
//                                 <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                                     {completenessScore}%
//                                 </span>
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 <div className="grid grid-cols-2 gap-3">
//                     <button
//                         onClick={handleGenerateFromResume}
//                         disabled={isProcessing || !fullResume}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isProcessing || !fullResume
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
//                             }
//                             transition-all duration-200
//                         `}
//                         title={!fullResume ? "Upload a resume to enable this feature" : ""}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isProcessing ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Sparkles className="w-6 h-6 text-blue-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Generate from Resume
//                         </span>
//                     </button>

//                     <button
//                         onClick={handleTailorToJob}
//                         disabled={isProcessing || !jobDescription}
//                         className={`
//                             flex flex-col items-center justify-center p-3 rounded-lg
//                             border border-gray-200 dark:border-gray-600
//                             ${isProcessing || !jobDescription
//                                 ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                 : 'hover:bg-emerald-50 dark:hover:bg-emerald-900/30 hover:border-emerald-300 dark:hover:border-emerald-500'
//                             }
//                             transition-all duration-200
//                         `}
//                         title={!jobDescription ? "Add a job description to enable this feature" : ""}
//                     >
//                         <span className="text-2xl mb-2">
//                             {isProcessing ? (
//                                 <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
//                             ) : (
//                                 <Target className="w-6 h-6 text-emerald-500" />
//                             )}
//                         </span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                             Tailor to Job
//                         </span>
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SummaryEditor;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Bold, Italic, Underline, List, RotateCcw, RotateCw,
    Loader2, Sparkles, Type, Layout, Hash, RefreshCw,
    Target
} from 'lucide-react';

import { STYLE_UI_CONFIG } from '../constants';

import supabase from '../supabaseClient';

const NGROK_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

const ControlGroup = ({ title, children }) => (
    <div className="flex items-center gap-2">
        <span className="text-sm text-gray-500 dark:text-gray-400 font-medium">{title}</span>
        <div className="flex items-center gap-1 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
            {children}
        </div>
    </div>
);

const SummaryEditor = ({ content, onChange, resumeId, jobDescription }) => {
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [history, setHistory] = useState([content]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('text');
    const [autoBulletEnabled, setAutoBulletEnabled] = useState(true);
    const [originalText, setOriginalText] = useState(content);
    const [completenessScore, setCompletenessScore] = useState(null);
    const [resumeText, setResumeText] = useState(null);
    const [error, setError] = useState(null);
    const [textFormat, setTextFormat] = useState({
        bold: false,
        italic: false,
        underline: false
    });

    const editorRef = useRef(null);

    // Fetch resume text from Supabase
    useEffect(() => {
        const fetchResumeText = async () => {
            if (!resumeId) return;

            setIsLoading(true);
            setError(null);

            try {
                const { data, error: supabaseError } = await supabase
                    .from('resumes')
                    .select('resume_text')
                    .eq('id', resumeId)
                    .single();

                if (supabaseError) throw supabaseError;

                if (data && data.resume_text) {
                    setResumeText(data.resume_text);
                } else {
                    throw new Error('No resume text found');
                }
            } catch (err) {
                console.error('Error fetching resume text:', err);
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchResumeText();
    }, [resumeId]);

    const handleFormat = (type) => {
        setTextFormat(prev => ({
            ...prev,
            [type]: !prev[type]
        }));

        const selection = window.getSelection();
        const selectedText = selection?.toString();
        if (selectedText) {
            const newText = content.replace(selectedText, formatText(selectedText, type));
            onChange(newText);
            addToHistory(newText);
        }
    };

    const formatText = (text, type) => {
        switch (type) {
            case 'bold':
                return `**${text}**`;
            case 'italic':
                return `*${text}*`;
            case 'underline':
                return `_${text}_`;
            default:
                return text;
        }
    };

    const addToHistory = useCallback((newContent) => {
        setHistory(prev => {
            const newHistory = prev.slice(0, historyIndex + 1);
            newHistory.push(newContent);
            return newHistory;
        });
        setHistoryIndex(prev => prev + 1);
    }, [historyIndex]);

    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            setHistoryIndex(prev => prev - 1);
            onChange(history[historyIndex - 1]);
        }
    }, [history, historyIndex, onChange]);

    const handleRedo = useCallback(() => {
        if (historyIndex < history.length - 1) {
            setHistoryIndex(prev => prev + 1);
            onChange(history[historyIndex + 1]);
        }
    }, [history, historyIndex, onChange]);

    const restoreOriginal = useCallback(() => {
        onChange(originalText);
        addToHistory(originalText);
    }, [originalText, onChange, addToHistory]);

    const handleKeyDown = useCallback((e) => {
        if (autoBulletEnabled && e.key === 'Enter') {
            e.preventDefault();
            const lines = content.split('\n');
            const lastLine = lines[lines.length - 1];
            const bulletMatch = lastLine.match(/^(\s*)([-•*])\s/);

            if (bulletMatch) {
                const [, spacing, bullet] = bulletMatch;
                const isEmpty = lastLine.trim() === bullet.trim();

                if (isEmpty) {
                    lines.pop();
                } else {
                    lines.push(`${spacing}${bullet} `);
                }

                const newContent = lines.join('\n');
                onChange(newContent);
                addToHistory(newContent);
            }
        }
    }, [autoBulletEnabled, content, onChange, addToHistory]);

    const analyzeResumeText = async (text) => {
        try {
            const response = await fetch(`${NGROK_BASE_URL}/api/analyze/resume_text`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text })
            });

            if (!response.ok) throw new Error('Failed to analyze resume text');

            const result = await response.json();
            return result.completeness_score;
        } catch (error) {
            console.error('Failed to analyze resume:', error);
            return null;
        }
    };

    const handleGenerateFromResume = async () => {
        if (!resumeText) {
            setError('No resume text available');
            return;
        }

        setIsProcessing(true);
        setError(null);

        try {
            // First analyze the resume text
            const completeness = await analyzeResumeText(resumeText);
            setCompletenessScore(completeness);

            // Generate summary with completeness score
            const response = await fetch(`${NGROK_BASE_URL}/api/generate/summary`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    resume: resumeText,
                    completeness_score: completeness
                })
            });

            if (!response.ok) throw new Error('Failed to generate summary');

            const result = await response.json();
            onChange(result.generated_summary);
            addToHistory(result.generated_summary);
        } catch (error) {
            console.error('Failed to generate summary:', error);
            setError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleTailorToJob = async () => {
        if (!jobDescription) {
            setError('No job description provided');
            return;
        }

        setIsProcessing(true);
        setError(null);

        try {
            const response = await fetch(`${NGROK_BASE_URL}/api/tailor/summary`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    current_summary: content,
                    job_description: jobDescription
                })
            });

            if (!response.ok) throw new Error('Failed to tailor summary');

            const result = await response.json();
            onChange(result.tailored_summary);
            addToHistory(result.tailored_summary);
        } catch (error) {
            console.error('Failed to tailor summary:', error);
            setError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleAIApply = async (preset, type = 'transform') => {
        if (!preset) return;

        setIsProcessing(true);
        setError(null);

        try {
            const selection = window.getSelection();
            const selectedText = selection?.toString() || '';
            const textToProcess = selectedText || content;

            let endpoint;
            switch (type) {
                case 'case':
                    endpoint = `/api/case-text/${preset}`;
                    break;
                case 'format':
                    endpoint = `/api/format-text/${preset}`;
                    break;
                case 'list':
                    endpoint = `/api/list-text/${preset}`;
                    break;
                default:
                    endpoint = `/api/transform/${preset}`;
            }

            const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    text: textToProcess,
                    style: preset
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || 'AI processing failed');
            }

            const result = await response.json();
            const newContent = selectedText
                ? content.replace(selectedText, result.transformed_text)
                : result.transformed_text;

            onChange(newContent);
            addToHistory(newContent);
        } catch (err) {
            console.error('AI processing failed:', err);
            setError('Failed to apply AI transformation');
        } finally {
            setIsProcessing(false);
        }
    };

    const renderStyleSelector = () => {
        const options = {
            text: STYLE_UI_CONFIG.TEXT_PRESETS,
            case: STYLE_UI_CONFIG.CASE_STYLES,
            format: STYLE_UI_CONFIG.FORMAT_STYLES,
            list: STYLE_UI_CONFIG.LIST_STYLES
        }[activeTab] || [];

        return (
            <select
                className="px-3 py-1 border rounded min-w-[200px] bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
                onChange={(e) => handleAIApply(e.target.value, activeTab)}
                value={selectedFormat || ''}
                disabled={isProcessing}
            >
                <option value="">Select Style</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center p-8">
                <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
            </div>
        );
    }

    return (
        <div className="border rounded-lg bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 shadow-sm">
            {error && (
                <div className="p-4 bg-red-50 dark:bg-red-900/20 border-b border-red-100 dark:border-red-800">
                    <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
                </div>
            )}

            <div className="border-b dark:border-gray-700 p-2 space-y-2">
                {/* Formatting Controls */}
                <div className="flex items-center gap-4">
                    <ControlGroup title="Format">
                        <button
                            className={`p-1 rounded tooltip ${textFormat.bold ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('bold')}
                            title="Bold"
                        >
                            <Bold size={18} className={`${textFormat.bold ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                        <button
                            className={`p-1 rounded tooltip ${textFormat.italic ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('italic')}
                            title="Italic"
                        >
                            <Italic size={18} className={`${textFormat.italic ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                        <button
                            className={`p-1 rounded tooltip ${textFormat.underline ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('underline')}
                            title="Underline"
                        >
                            <Underline size={18} className={`${textFormat.underline ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                    </ControlGroup>

                    <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

                    <ControlGroup title="Auto-Bullet">
                        <button
                            className={`p-1 rounded tooltip ${autoBulletEnabled ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => setAutoBulletEnabled(!autoBulletEnabled)}
                            title="Toggle Auto-Bullet"
                        >
                            <List size={18} className={`${autoBulletEnabled ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                    </ControlGroup>

                    <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

                    <ControlGroup title="History">
                        <button
                            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
                            onClick={handleRedo}
                            disabled={historyIndex === history.length - 1}
                            title="Redo"
                        >
                            <RotateCw size={18} className="text-gray-700 dark:text-gray-200" />
                        </button>
                        <button
                            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip"
                            onClick={restoreOriginal}
                            title="Restore Original"
                        >
                            <RefreshCw size={18} className="text-gray-700 dark:text-gray-200" />
                        </button>
                    </ControlGroup>
                </div>

                {/* Style Controls */}
                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'text' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('text')}
                        >
                            <Type size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Text</span>
                        </button>
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'case' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('case')}
                        >
                            <Hash size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Case</span>
                        </button>
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'format' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('format')}
                        >
                            <Layout size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Format</span>
                        </button>
                    </div>

                    {renderStyleSelector()}

                    <button
                        onClick={() => selectedFormat && handleAIApply(selectedFormat)}
                        disabled={isProcessing || !selectedFormat}
                        className={`
                            px-3 py-1 rounded flex items-center gap-2
                            ${isProcessing || !selectedFormat
                                ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-400'
                                : 'bg-blue-500 hover:bg-blue-600 text-white'
                            }
                        `}
                    >
                        {isProcessing ? (
                            <>
                                <Loader2 className="w-4 h-4 animate-spin" />
                                <span>Processing...</span>
                            </>
                        ) : (
                            <>
                                <Sparkles className="w-4 h-4" />
                                <span>Apply Transform</span>
                            </>
                        )}
                    </button>
                </div>
            </div>

            {/* Editor Area */}
            <div className="p-2 bg-white dark:bg-gray-800">
                <textarea
                    ref={editorRef}
                    value={content}
                    onChange={(e) => {
                        onChange(e.target.value);
                        addToHistory(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    className="w-full min-h-[200px] p-2 border rounded resize-y focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
                    placeholder="Enter your professional summary..."
                />
            </div>

            {/* Summary Generation Tools */}
            <div className="border-t border-gray-200 dark:border-gray-700 p-4">
                <div className="flex justify-between items-center mb-3">
                    <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        Summary Generation Tools
                    </h3>
                    {completenessScore !== null && (
                        <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-600 dark:text-gray-400">
                                Resume Completeness:
                            </span>
                            <div className="flex items-center gap-1">
                                <div className="h-2 w-24 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                                    <div
                                        className="h-full bg-blue-500 transition-all duration-300"
                                        style={{ width: `${completenessScore}%` }}
                                    />
                                </div>
                                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                    {completenessScore}%
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="grid grid-cols-2 gap-3">
                    <button
                        onClick={handleGenerateFromResume}
                        disabled={isProcessing || !resumeText}
                        className={`
                            flex flex-col items-center justify-center p-3 rounded-lg
                            border border-gray-200 dark:border-gray-600
                            ${isProcessing || !resumeText
                                ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
                                : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
                            }
                            transition-all duration-200
                        `}
                        title={!resumeText ? "No resume text available" : ""}
                    >
                        <span className="text-2xl mb-2">
                            {isProcessing ? (
                                <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
                            ) : (
                                <Sparkles className="w-6 h-6 text-blue-500" />
                            )}
                        </span>
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Generate from Resume
                        </span>
                    </button>

                    <button
                        onClick={handleTailorToJob}
                        disabled={isProcessing || !jobDescription}
                        className={`
                            flex flex-col items-center justify-center p-3 rounded-lg
                            border border-gray-200 dark:border-gray-600
                            ${isProcessing || !jobDescription
                                ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
                                : 'hover:bg-emerald-50 dark:hover:bg-emerald-900/30 hover:border-emerald-300 dark:hover:border-emerald-500'
                            }
                            transition-all duration-200
                        `}
                        title={!jobDescription ? "Add a job description to enable this feature" : ""}
                    >
                        <span className="text-2xl mb-2">
                            {isProcessing ? (
                                <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
                            ) : (
                                <Target className="w-6 h-6 text-emerald-500" />
                            )}
                        </span>
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Tailor to Job
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SummaryEditor;