// // import React, { useState, useCallback, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import { v4 as uuidv4 } from 'uuid';
// // import {
// //     FileText,
// //     Upload,
// //     AlertCircle,
// //     Loader2,
// //     X,
// //     Wand2,
// //     Link,
// //     Forward,
// //     Users,
// //     ArrowRight,
// //     ArrowLeft,
// //     Building,
// //     BriefcaseIcon,
// //     MapPin,
// //     DollarSign,
// //     FileEdit
// // } from 'lucide-react';
// // import { toast } from 'sonner';
// // import supabase from '../../supabaseClient';

// // const API_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// // // Card Components
// // const Card = React.forwardRef(({ className, ...props }, ref) => (
// //     <div ref={ref} className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`} {...props} />
// // ));
// // Card.displayName = "Card";

// // const JobSiteIcon = ({ site }) => {
// //     const icons = {
// //         LinkedIn: (
// //             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
// //                 <rect width="40" height="40" rx="8" fill="#0A66C2" />
// //                 <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
// //             </svg>
// //         ),
// //         Indeed: (
// //             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
// //                 <rect width="40" height="40" rx="8" fill="#2164F3" />
// //                 <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
// //                 <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
// //             </svg>
// //         ),
// //         Glassdoor: (
// //             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
// //                 <rect width="40" height="40" rx="8" fill="#0CAA41" />
// //                 <path d="M20 8c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S26.627 8 20 8zm0 22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z" fill="white" />
// //                 <path d="M20 14c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" fill="white" />
// //             </svg>
// //         )
// //     };
// //     return icons[site] || null;
// // };

// // const LoadingSpinner = () => (
// //     <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
// //         <div className="bg-white dark:bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4">
// //             <div className="flex items-center gap-4">
// //                 <Loader2 className="h-6 w-6 animate-spin text-blue-600 dark:text-blue-400" />
// //                 <div>
// //                     <h3 className="text-lg font-medium text-gray-900 dark:text-white">Processing...</h3>
// //                     <p className="text-sm text-gray-500 dark:text-gray-400">This may take a few moments</p>
// //                 </div>
// //             </div>
// //         </div>
// //     </div>
// // );

// // const CandidateJobUploadModal = () => {
// //     const navigate = useNavigate();

// //     // File Upload States
// //     const [selectedFile, setSelectedFile] = useState(null);
// //     const [parseOption, setParseOption] = useState('ai');
// //     const [isParsingResume, setIsParsingResume] = useState(false);
// //     const [uploadProgress, setUploadProgress] = useState(0);

// //     // Job Data States
// //     const [jobData, setJobData] = useState({
// //         companyName: '',
// //         roleTitle: '',
// //         department: '',
// //         location: '',
// //         salaryRange: '',
// //         jobDescription: '',
// //         skills: [],
// //         yearsOfExperience: '',
// //         education: [],
// //         employmentType: 'full-time',
// //         remoteType: 'on-site'
// //     });

// //     // Job Source States
// //     const [url, setUrl] = useState('');
// //     const [isScraping, setIsScraping] = useState(false);
// //     const [isSubmitting, setIsSubmitting] = useState(false);
// //     const [error, setError] = useState(null);
// //     const [selectedSite, setSelectedSite] = useState(null);
// //     const [matchingRoles, setMatchingRoles] = useState([]);
// //     const [rawJobDescription, setRawJobDescription] = useState('');

// //     const jobSites = [
// //         { name: 'LinkedIn', domain: 'linkedin.com' },
// //         { name: 'Indeed', domain: 'indeed.com' },
// //         { name: 'Glassdoor', domain: 'glassdoor.com' }
// //     ];

// //     const employmentTypes = [
// //         { value: 'full-time', label: 'Full-time' },
// //         { value: 'part-time', label: 'Part-time' },
// //         { value: 'contract', label: 'Contract' },
// //         { value: 'temporary', label: 'Temporary' },
// //         { value: 'internship', label: 'Internship' }
// //     ];

// //     const remoteTypes = [
// //         { value: 'on-site', label: 'On-site' },
// //         { value: 'hybrid', label: 'Hybrid' },
// //         { value: 'remote', label: 'Remote' }
// //     ];

// //     useEffect(() => {
// //         // Check for matching roles when company or role title changes
// //         const checkForMatchingRoles = async () => {
// //             if (!jobData.companyName || !jobData.roleTitle) return;

// //             try {
// //                 const { data: { user } } = await supabase.auth.getUser();
// //                 const { data: membership } = await supabase
// //                     .from('agency_members')
// //                     .select('agency_id')
// //                     .eq('user_id', user.id)
// //                     .single();

// //                 const { data: matches } = await supabase
// //                     .from('open_roles')
// //                     .select('*')
// //                     .eq('agency_id', membership.agency_id)
// //                     .ilike('company_name', `%${jobData.companyName}%`)
// //                     .ilike('role_title', `%${jobData.roleTitle}%`);

// //                 setMatchingRoles(matches || []);
// //             } catch (err) {
// //                 console.error('Error checking for matching roles:', err);
// //             }
// //         };

// //         checkForMatchingRoles();
// //     }, [jobData.companyName, jobData.roleTitle]);

// //     const handleClose = () => navigate('/dashboard');

// //     const handleFileSelect = useCallback((file) => {
// //         if (!file) return;

// //         if (file.size > 10 * 1024 * 1024) {
// //             setError('File size must be less than 10MB');
// //             return;
// //         }

// //         const allowedTypes = [
// //             'application/pdf',
// //             'application/msword',
// //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
// //         ];

// //         if (!allowedTypes.includes(file.type)) {
// //             setError('Only PDF, DOC, and DOCX files are allowed');
// //             return;
// //         }

// //         setSelectedFile(file);
// //         setError(null);

// //         if (parseOption === 'ai') {
// //             handleResumeAIParse(file);
// //         }
// //     }, [parseOption]);

// //     const handleResumeAIParse = async (file) => {
// //         setIsParsingResume(true);
// //         try {
// //             const formData = new FormData();
// //             formData.append('resume', file);

// //             const response = await fetch(`${API_BASE_URL}/parse-resume`, {
// //                 method: 'POST',
// //                 body: formData
// //             });

// //             if (!response.ok) throw new Error('Failed to parse resume');

// //             const parseData = await response.json();

// //             setJobData(prev => ({
// //                 ...prev,
// //                 companyName: parseData.current_company || '',
// //                 roleTitle: parseData.current_title || '',
// //                 department: parseData.department || '',
// //                 location: parseData.location || '',
// //                 jobDescription: parseData.experience || '',
// //                 skills: parseData.skills || [],
// //                 yearsOfExperience: parseData.years_of_experience || '',
// //                 education: parseData.education || []
// //             }));

// //             toast.success('Resume parsed successfully!');
// //         } catch (err) {
// //             console.error('Resume parsing error:', err);
// //             toast.error('Failed to parse resume. You can still fill details manually.');
// //         } finally {
// //             setIsParsingResume(false);
// //         }
// //     };

// //     const handleScrapeJob = async () => {
// //         if (!url || !selectedSite) return;

// //         setIsScraping(true);
// //         setError(null);
// //         try {
// //             const scrapeResponse = await fetch(`${API_BASE_URL}/scrape-job`, {
// //                 method: 'POST',
// //                 headers: {
// //                     'Content-Type': 'application/json',
// //                     'Accept': 'application/json'
// //                 },
// //                 body: JSON.stringify({
// //                     url,
// //                     site: selectedSite.domain
// //                 })
// //             });

// //             if (!scrapeResponse.ok) {
// //                 throw new Error('Failed to scrape job description');
// //             }

// //             const scrapeData = await scrapeResponse.json();
// //             const descriptionText = scrapeData.description || '';
// //             setRawJobDescription(descriptionText);

// //             try {
// //                 const parseResponse = await fetch(`${API_BASE_URL}/api/case-text/parse`, {
// //                     method: 'POST',
// //                     headers: {
// //                         'Content-Type': 'application/json',
// //                         'Accept': 'application/json'
// //                     },
// //                     body: JSON.stringify({
// //                         text: descriptionText,
// //                         style: 'job_posting'
// //                     })
// //                 });

// //                 if (!parseResponse.ok) {
// //                     throw new Error('Failed to parse job description');
// //                 }

// //                 const parsedData = await parseResponse.json();

// //                 setJobData(prev => ({
// //                     ...prev,
// //                     companyName: parsedData.company_name || scrapeData.company || prev.companyName,
// //                     roleTitle: parsedData.role_title || scrapeData.title || prev.roleTitle,
// //                     department: parsedData.department || scrapeData.department || prev.department,
// //                     location: parsedData.location || scrapeData.location || prev.location,
// //                     salaryRange: parsedData.salary_range || scrapeData.salary_range || prev.salaryRange,
// //                     jobDescription: parsedData.job_description || descriptionText,
// //                     skills: [...new Set([...prev.skills, ...(parsedData.required_skills || [])])],
// //                     employmentType: parsedData.employment_type || prev.employmentType,
// //                     remoteType: parsedData.remote_type || prev.remoteType
// //                 }));

// //                 toast.success('Job details imported successfully!');

// //             } catch (parseError) {
// //                 console.error('Parsing error:', parseError);
// //                 setJobData(prev => ({
// //                     ...prev,
// //                     companyName: scrapeData.company || prev.companyName,
// //                     roleTitle: scrapeData.title || prev.roleTitle,
// //                     department: scrapeData.department || prev.department,
// //                     location: scrapeData.location || prev.location,
// //                     salaryRange: scrapeData.salary_range || prev.salaryRange,
// //                     jobDescription: descriptionText
// //                 }));

// //                 toast.warning('Imported basic job details. Some fields may need manual review.');
// //             }

// //         } catch (err) {
// //             console.error('Error during job import:', err);
// //             setError('Failed to import job details. Please try again or enter manually.');
// //             toast.error('Import failed. Please try again or enter details manually.');
// //         } finally {
// //             setIsScraping(false);
// //         }
// //     };

// //     const handleSubmit = async () => {
// //         if (!selectedFile) {
// //             setError('Please upload a resume file');
// //             toast.error('Please upload a resume file');
// //             return;
// //         }

// //         setIsSubmitting(true);
// //         try {
// //             const { data: { user } } = await supabase.auth.getUser();
// //             const { data: membership } = await supabase
// //                 .from('agency_members')
// //                 .select('agency_id')
// //                 .eq('user_id', user.id)
// //                 .single();

// //             // Upload resume file first
// //             const resumeId = uuidv4();
// //             const filePath = `agencies/${membership.agency_id}/uploads/${resumeId}/${selectedFile.name}`;

// //             const { error: uploadError } = await supabase.storage
// //                 .from('resume_uploads')
// //                 .upload(filePath, selectedFile);

// //             if (uploadError) throw uploadError;

// //             const { data: { publicUrl } } = supabase.storage
// //                 .from('resume_uploads')
// //                 .getPublicUrl(filePath);

// //             // Create the resume record
// //             const { error: resumeError } = await supabase
// //                 .from('resumes')
// //                 .insert({
// //                     id: resumeId,
// //                     agency_id: membership.agency_id,
// //                     uploaded_by: user.id,
// //                     file_url: publicUrl,
// //                     file_path: filePath,
// //                     file_name: selectedFile.name,
// //                     parsed_data: jobData,
// //                     raw_text: rawJobDescription,
// //                     status: 'active',
// //                     created_at: new Date().toISOString(),
// //                     updated_at: new Date().toISOString()
// //                 });

// //             if (resumeError) throw resumeError;

// //             // Create job posting if details are provided
// //             if (jobData.companyName && jobData.roleTitle) {
// //                 const { data: role, error: roleError } = await supabase
// //                     .from('open_roles')
// //                     .insert({
// //                         id: uuidv4(),
// //                         agency_id: membership.agency_id,
// //                         resume_id: resumeId,
// //                         uploaded_by: user.id,
// //                         company_name: jobData.companyName,
// //                         role_title: jobData.roleTitle,
// //                         department: jobData.department || null,
// //                         location: jobData.location || null,
// //                         salary_range: jobData.salaryRange || null,
// //                         job_description: jobData.jobDescription,
// //                         skills: jobData.skills,
// //                         years_of_experience: jobData.yearsOfExperience,
// //                         education_requirements: jobData.education,
// //                         employment_type: jobData.employmentType,
// //                         remote_type: jobData.remoteType,
// //                         raw_description: rawJobDescription,
// //                         status: 'active',
// //                         created_at: new Date().toISOString(),
// //                         updated_at: new Date().toISOString()
// //                     })
// //                     .select()
// //                     .single();

// //                 if (roleError) throw roleError;
// //             }

// //             toast.success('Resume uploaded successfully!');
// //             navigate('/dashboard', {
// //                 state: { message: 'Resume processed and job details saved successfully' }
// //             });

// //         } catch (err) {
// //             console.error('Error:', err);
// //             setError(err.message || 'Failed to process resume and save job details');
// //             toast.error('Failed to process request');
// //         } finally {
// //             setIsSubmitting(false);
// //         }
// //     };

// //     const handleInputChange = (field, value) => {
// //         setJobData(prev => ({ ...prev, [field]: value }));
// //         setError(null);
// //     };

// //     const renderSkillBadges = () => (
// //         <div className="flex flex-wrap gap-2">
// //             {jobData.skills.map((skill, index) => (
// //                 <span
// //                     key={index}
// //                     className="px-3 py-1 bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 rounded-full text-sm flex items-center gap-2"
// //                 >
// //                     {skill}
// //                     <button
// //                         onClick={() => handleInputChange('skills', jobData.skills.filter((_, i) => i !== index))}
// //                         className="hover:text-blue-600 dark:hover:text-blue-300"
// //                     >
// //                         <X className="h-4 w-4" />
// //                     </button>
// //                 </span>
// //             ))}
// //         </div>
// //     );

// //     return (
// //         <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
// //             <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-full max-w-6xl flex flex-col">
// //                 {/* Header */}
// //                 <div className="p-6 border-b dark:border-gray-700">
// //                     <div className="flex justify-between items-center">
// //                         <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Upload Resume</h2>
// //                         <button onClick={handleClose} className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">×</button>
// //                     </div>
// //                 </div>

// //                 {/* Body */}
// //                 <div className="flex-1 overflow-y-auto p-6 space-y-8">
// //                     {/* Parse Options */}
// //                     <div className="flex justify-end space-x-4">
// //                         <button
// //                             onClick={() => setParseOption('ai')}
// //                             className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${parseOption === 'ai'
// //                                 ? 'bg-blue-600 text-white'
// //                                 : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
// //                                 }`}
// //                         >
// //                             <Wand2 className="w-4 h-4" />
// //                             AI Parse
// //                         </button>
// //                         <button
// //                             onClick={() => setParseOption('manual')}
// //                             className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${parseOption === 'manual'
// //                                 ? 'bg-blue-600 text-white'
// //                                 : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
// //                                 }`}
// //                         >
// //                             <FileEdit className="w-4 h-4" />
// //                             Manual Entry
// //                         </button>
// //                     </div>

// //                     {/* Resume Upload Area */}
// //                     <div className="mb-8">
// //                         <div
// //                             className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 text-center
// //                                      hover:border-blue-500 dark:hover:border-blue-400 transition-colors"
// //                             onDrop={(e) => {
// //                                 e.preventDefault();
// //                                 handleFileSelect(e.dataTransfer.files[0]);
// //                             }}
// //                             onDragOver={(e) => e.preventDefault()}
// //                         >
// //                             <input
// //                                 type="file"
// //                                 id="file-upload"
// //                                 className="hidden"
// //                                 accept=".pdf,.doc,.docx"
// //                                 onChange={(e) => handleFileSelect(e.target.files[0])}
// //                             />
// //                             {selectedFile ? (
// //                                 <div className="flex items-center justify-center gap-4">
// //                                     <FileText className="h-8 w-8 text-blue-600 dark:text-blue-400" />
// //                                     <div>
// //                                         <p className="font-medium text-gray-900 dark:text-white">{selectedFile.name}</p>
// //                                         <p className="text-sm text-gray-500 dark:text-gray-400">
// //                                             {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
// //                                         </p>
// //                                     </div>
// //                                     <button
// //                                         onClick={() => setSelectedFile(null)}
// //                                         className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
// //                                     >
// //                                         <X className="h-5 w-5 text-gray-500 dark:text-gray-400" />
// //                                     </button>
// //                                 </div>
// //                             ) : (
// //                                 <div>
// //                                     <Upload className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
// //                                     <label
// //                                         htmlFor="file-upload"
// //                                         className="cursor-pointer text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
// //                                     >
// //                                         Click to upload or drag and drop
// //                                     </label>
// //                                     <p className="text-gray-500 dark:text-gray-400 text-sm mt-1">
// //                                         PDF, DOC, DOCX up to 10MB
// //                                     </p>
// //                                 </div>
// //                             )}
// //                         </div>
// //                     </div>

// //                     {/* Job Details Form */}
// //                     <div className="space-y-6">
// //                         {/* Job Source Section */}
// //                         <div>
// //                             <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
// //                                 Import Role Details (Optional)
// //                             </h3>
// //                             <div className="grid grid-cols-3 gap-4 mb-4">
// //                                 {jobSites.map(site => (
// //                                     <button
// //                                         key={site.name}
// //                                         onClick={() => setSelectedSite(site)}
// //                                         className={`p-4 rounded-lg border ${selectedSite?.name === site.name
// //                                             ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
// //                                             : 'border-gray-200 dark:border-gray-700'
// //                                             } flex items-center gap-3 transition-colors`}
// //                                     >
// //                                         <JobSiteIcon site={site.name} />
// //                                         <span className="text-gray-900 dark:text-white">{site.name}</span>
// //                                     </button>
// //                                 ))}
// //                             </div>
// //                             {selectedSite && (
// //                                 <div className="flex gap-2">
// //                                     <input
// //                                         type="url"
// //                                         className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                         value={url}
// //                                         onChange={(e) => setUrl(e.target.value)}
// //                                         placeholder={`Paste ${selectedSite.name} job URL`}
// //                                     />
// //                                     <button
// //                                         onClick={handleScrapeJob}
// //                                         disabled={!url || isScraping}
// //                                         className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
// //                                                  disabled:opacity-50 flex items-center gap-2 transition-colors"
// //                                     >
// //                                         {isScraping ? (
// //                                             <>
// //                                                 <Loader2 className="w-5 h-5 animate-spin" />
// //                                                 <span>Importing...</span>
// //                                             </>
// //                                         ) : (
// //                                             <span>Import Details</span>
// //                                         )}
// //                                     </button>
// //                                 </div>
// //                             )}
// //                         </div>

// //                         {/* Company and Role Details */}
// //                         <div className="grid grid-cols-2 gap-6">
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Company Name
// //                                 </label>
// //                                 <div className="relative">
// //                                     <Building className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
// //                                     <input
// //                                         type="text"
// //                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                         value={jobData.companyName}
// //                                         onChange={(e) => handleInputChange('companyName', e.target.value)}
// //                                     />
// //                                 </div>
// //                             </div>
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Role Title
// //                                 </label>
// //                                 <div className="relative">
// //                                     <BriefcaseIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
// //                                     <input
// //                                         type="text"
// //                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                         value={jobData.roleTitle}
// //                                         onChange={(e) => handleInputChange('roleTitle', e.target.value)}
// //                                     />
// //                                 </div>
// //                             </div>
// //                         </div>

// //                         {/* Location and Department */}
// //                         <div className="grid grid-cols-2 gap-6">
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Location
// //                                 </label>
// //                                 <div className="relative">
// //                                     <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
// //                                     <input
// //                                         type="text"
// //                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                         value={jobData.location}
// //                                         onChange={(e) => handleInputChange('location', e.target.value)}
// //                                     />
// //                                 </div>
// //                             </div>
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Department
// //                                 </label>
// //                                 <input
// //                                     type="text"
// //                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                     value={jobData.department}
// //                                     onChange={(e) => handleInputChange('department', e.target.value)}
// //                                 />
// //                             </div>
// //                         </div>

// //                         {/* Employment Type and Remote Type */}
// //                         <div className="grid grid-cols-2 gap-6">
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Employment Type
// //                                 </label>
// //                                 <select
// //                                     value={jobData.employmentType}
// //                                     onChange={(e) => handleInputChange('employmentType', e.target.value)}
// //                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                 >
// //                                     {employmentTypes.map(type => (
// //                                         <option key={type.value} value={type.value}>
// //                                             {type.label}
// //                                         </option>
// //                                     ))}
// //                                 </select>
// //                             </div>
// //                             <div>
// //                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                     Remote Type
// //                                 </label>
// //                                 <select
// //                                     value={jobData.remoteType}
// //                                     onChange={(e) => handleInputChange('remoteType', e.target.value)}
// //                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                 >
// //                                     {remoteTypes.map(type => (
// //                                         <option key={type.value} value={type.value}>
// //                                             {type.label}
// //                                         </option>
// //                                     ))}
// //                                 </select>
// //                             </div>
// //                         </div>

// //                         {/* Salary Range */}
// //                         <div>
// //                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                 Salary Range
// //                             </label>
// //                             <div className="relative">
// //                                 <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
// //                                 <input
// //                                     type="text"
// //                                     className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                     value={jobData.salaryRange}
// //                                     onChange={(e) => handleInputChange('salaryRange', e.target.value)}
// //                                     placeholder="e.g., $50,000 - $80,000"
// //                                 />
// //                             </div>
// //                         </div>

// //                         {/* Skills Section */}
// //                         <div>
// //                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                 Skills
// //                             </label>
// //                             <div className="space-y-2">
// //                                 <div className="flex gap-2">
// //                                     <input
// //                                         type="text"
// //                                         className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
// //                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                         placeholder="Add a skill"
// //                                         onKeyDown={(e) => {
// //                                             if (e.key === 'Enter' && e.target.value) {
// //                                                 e.preventDefault();
// //                                                 handleInputChange('skills', [...jobData.skills, e.target.value]);
// //                                                 e.target.value = '';
// //                                             }
// //                                         }}
// //                                     />
// //                                 </div>
// //                                 {renderSkillBadges()}
// //                             </div>
// //                         </div>

// //                         {/* Job Description */}
// //                         <div>
// //                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
// //                                 Job Description
// //                             </label>
// //                             <textarea
// //                                 className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-64 resize-none
// //                                          bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
// //                                 value={jobData.jobDescription}
// //                                 onChange={(e) => handleInputChange('jobDescription', e.target.value)}
// //                                 placeholder="Enter job description..."
// //                             />
// //                         </div>

// //                         {/* Similar Roles Warning */}
// //                         {matchingRoles.length > 0 && (
// //                             <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-700 rounded-lg p-4">
// //                                 <div className="flex items-start gap-3">
// //                                     <Users className="w-5 h-5 text-yellow-600 dark:text-yellow-400 mt-0.5" />
// //                                     <div>
// //                                         <h4 className="font-medium text-yellow-800 dark:text-yellow-200">
// //                                             Similar Roles Found
// //                                         </h4>
// //                                         <p className="text-sm text-yellow-700 dark:text-yellow-300 mt-1">
// //                                             There are {matchingRoles.length} similar open roles:
// //                                         </p>
// //                                         <ul className="mt-2 space-y-1">
// //                                             {matchingRoles.map((role, index) => (
// //                                                 <li key={index} className="text-sm text-yellow-700 dark:text-yellow-300">
// //                                                     {role.company_name} - {role.role_title}
// //                                                 </li>
// //                                             ))}
// //                                         </ul>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         )}
// //                     </div>
// //                 </div>

// //                 {/* Footer */}
// //                 <div className="p-6 border-t dark:border-gray-700">
// //                     <div className="flex justify-between items-center">
// //                         <div>
// //                             {error && (
// //                                 <div className="text-red-600 dark:text-red-400 text-sm flex items-center gap-2">
// //                                     <AlertCircle className="w-4 h-4" />
// //                                     {error}
// //                                 </div>
// //                             )}
// //                         </div>

// //                         <div className="flex gap-4">
// //                             <button
// //                                 onClick={handleClose}
// //                                 className="px-6 py-2 border dark:border-gray-600 rounded-lg 
// //                                          hover:bg-gray-50 dark:hover:bg-gray-700 
// //                                          text-gray-700 dark:text-gray-300 transition-colors"
// //                             >
// //                                 Cancel
// //                             </button>
// //                             <button
// //                                 onClick={handleSubmit}
// //                                 disabled={!selectedFile || isSubmitting}
// //                                 className="px-6 py-2 bg-blue-600 text-white rounded-lg 
// //                                          hover:bg-blue-700 disabled:opacity-50 
// //                                          flex items-center gap-2 transition-colors"
// //                             >
// //                                 {isSubmitting ? (
// //                                     <>
// //                                         <Loader2 className="w-5 h-5 animate-spin" />
// //                                         <span>Processing...</span>
// //                                     </>
// //                                 ) : (
// //                                     <span>Upload Resume & Save Details</span>
// //                                 )}
// //                             </button>
// //                         </div>
// //                     </div>
// //                 </div>

// //                 {/* Loading Overlay */}
// //                 {(isParsingResume || (uploadProgress > 0 && uploadProgress < 100)) && <LoadingSpinner />}
// //             </div>
// //         </div>
// //     );
// // };

// // export default CandidateJobUploadModal;

// import React, { useState, useCallback, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import {
//     FileText,
//     Upload,
//     AlertCircle,
//     Loader2,
//     X,
//     Wand2,
//     Link,
//     Forward,
//     Users,
//     ArrowRight,
//     ArrowLeft,
//     Building,
//     Info,
//     BriefcaseIcon,
//     MapPin,
//     DollarSign,
//     FileEdit
// } from 'lucide-react';
// import { toast } from 'sonner';
// import supabase from '../../supabaseClient';

// const API_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// // Card Components
// const Card = React.forwardRef(({ className, ...props }, ref) => (
//     <div ref={ref} className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`} {...props} />
// ));
// Card.displayName = "Card";

// const JobSiteIcon = ({ site }) => {
//     const icons = {
//         LinkedIn: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                 <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//             </svg>
//         ),
//         Indeed: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#2164F3" />
//                 <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                 <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//             </svg>
//         ),
//         Glassdoor: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                 <path d="M20 8c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S26.627 8 20 8zm0 22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z" fill="white" />
//                 <path d="M20 14c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" fill="white" />
//             </svg>
//         )
//     };
//     return icons[site] || null;
// };

// const LoadingSpinner = () => (
//     <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
//         <div className="bg-white dark:bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4">
//             <div className="flex items-center gap-4">
//                 <Loader2 className="h-6 w-6 animate-spin text-blue-600 dark:text-blue-400" />
//                 <div>
//                     <h3 className="text-lg font-medium text-gray-900 dark:text-white">Processing...</h3>
//                     <p className="text-sm text-gray-500 dark:text-gray-400">This may take a few moments</p>
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// const CandidateJobUploadModal = () => {
//     const navigate = useNavigate();

//     // Initialize states with localStorage values
//     const [selectedFile, setSelectedFile] = useState(() => {
//         const savedFileInfo = localStorage.getItem('uploadFormFile');
//         return savedFileInfo ? JSON.parse(savedFileInfo) : null;
//     });

//     const [parseOption, setParseOption] = useState(() =>
//         localStorage.getItem('uploadFormParseOption') || 'ai'
//     );

//     const [jobData, setJobData] = useState(() => {
//         const savedData = localStorage.getItem('uploadFormJobData');
//         return savedData ? JSON.parse(savedData) : {
//             companyName: '',
//             roleTitle: '',
//             department: '',
//             location: '',
//             salaryRange: '',
//             jobDescription: '',
//             skills: [],
//             yearsOfExperience: '',
//             education: [],
//             employmentType: 'full-time',
//             remoteType: 'on-site'
//         };
//     });

//     const [url, setUrl] = useState(() =>
//         localStorage.getItem('uploadFormUrl') || ''
//     );

//     const [selectedSite, setSelectedSite] = useState(() => {
//         const saved = localStorage.getItem('uploadFormSelectedSite');
//         return saved ? JSON.parse(saved) : null;
//     });

//     const [rawJobDescription, setRawJobDescription] = useState(() =>
//         localStorage.getItem('uploadFormRawDesc') || ''
//     );

//     // Non-persisted UI states
//     const [isParsingResume, setIsParsingResume] = useState(false);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [isScraping, setIsScraping] = useState(false);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [error, setError] = useState(null);
//     const [matchingRoles, setMatchingRoles] = useState([]);
//     const [existingRoles, setExistingRoles] = useState([]);
//     const [selectedExistingRole, setSelectedExistingRole] = useState(null);
//     const [isLoadingRoles, setIsLoadingRoles] = useState(true);

//     // Save states to localStorage
//     useEffect(() => {
//         if (selectedFile) {
//             const fileInfo = {
//                 name: selectedFile.name,
//                 size: selectedFile.size,
//                 type: selectedFile.type,
//                 lastModified: selectedFile.lastModified
//             };
//             localStorage.setItem('uploadFormFile', JSON.stringify(fileInfo));
//         } else {
//             localStorage.removeItem('uploadFormFile');
//         }
//     }, [selectedFile]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormJobData', JSON.stringify(jobData));
//     }, [jobData]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormParseOption', parseOption);
//     }, [parseOption]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormUrl', url);
//     }, [url]);

//     useEffect(() => {
//         if (selectedSite) {
//             localStorage.setItem('uploadFormSelectedSite', JSON.stringify(selectedSite));
//         } else {
//             localStorage.removeItem('uploadFormSelectedSite');
//         }
//     }, [selectedSite]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormRawDesc', rawJobDescription);
//     }, [rawJobDescription]);

//     // Add beforeunload handler
//     useEffect(() => {
//         const handleBeforeUnload = (e) => {
//             if (selectedFile || jobData.companyName || jobData.roleTitle) {
//                 e.preventDefault();
//                 e.returnValue = '';
//             }
//         };

//         window.addEventListener('beforeunload', handleBeforeUnload);
//         return () => window.removeEventListener('beforeunload', handleBeforeUnload);
//     }, [selectedFile, jobData]);

//     useEffect(() => {
//         const fetchExistingRoles = async () => {
//             setIsLoadingRoles(true);
//             try {
//                 const { data: { user } } = await supabase.auth.getUser();
//                 const { data: membership } = await supabase
//                     .from('agency_members')
//                     .select('agency_id')
//                     .eq('user_id', user.id)
//                     .single();

//                 if (membership) {
//                     const { data: roles } = await supabase
//                         .from('open_roles')
//                         .select('*')
//                         .eq('agency_id', membership.agency_id)
//                         .eq('status', 'active')
//                         .order('created_at', { ascending: false });

//                     setExistingRoles(roles || []);
//                 }
//             } catch (err) {
//                 console.error('Error fetching roles:', err);
//                 toast.error('Failed to fetch existing roles');
//             } finally {
//                 setIsLoadingRoles(false);
//             }
//         };

//         fetchExistingRoles();
//     }, []);

//     // Add handler for selecting existing role
//     const handleExistingRoleSelect = (roleId) => {
//         const role = existingRoles.find(r => r.id === roleId);
//         if (role) {
//             setSelectedExistingRole(role);
//             setJobData({
//                 ...jobData,
//                 companyName: role.company_name,
//                 roleTitle: role.role_title,
//                 department: role.department || '',
//                 location: role.location || '',
//                 salaryRange: role.salary_range || '',
//                 jobDescription: role.job_description || '',
//                 skills: role.skills || [],
//                 employmentType: role.employment_type || 'full-time',
//                 remoteType: role.remote_type || 'on-site'
//             });
//         } else {
//             setSelectedExistingRole(null);
//         }
//     };
//     // Constants
//     const jobSites = [
//         { name: 'LinkedIn', domain: 'linkedin.com' },
//         { name: 'Indeed', domain: 'indeed.com' },
//         { name: 'Glassdoor', domain: 'glassdoor.com' }
//     ];

//     const employmentTypes = [
//         { value: 'full-time', label: 'Full-time' },
//         { value: 'part-time', label: 'Part-time' },
//         { value: 'contract', label: 'Contract' },
//         { value: 'temporary', label: 'Temporary' },
//         { value: 'internship', label: 'Internship' }
//     ];

//     const remoteTypes = [
//         { value: 'on-site', label: 'On-site' },
//         { value: 'hybrid', label: 'Hybrid' },
//         { value: 'remote', label: 'Remote' }
//     ];
//     // Cleanup function
//     const cleanupStorage = () => {
//         localStorage.removeItem('uploadFormFile');
//         localStorage.removeItem('uploadFormJobData');
//         localStorage.removeItem('uploadFormParseOption');
//         localStorage.removeItem('uploadFormUrl');
//         localStorage.removeItem('uploadFormSelectedSite');
//         localStorage.removeItem('uploadFormRawDesc');
//     };

//     const handleClose = () => {
//         cleanupStorage();
//         navigate('/dashboard');
//     };

//     const handleFileSelect = useCallback((file) => {
//         if (!file) return;

//         if (file.size > 10 * 1024 * 1024) {
//             setError('File size must be less than 10MB');
//             return;
//         }

//         const allowedTypes = [
//             'application/pdf',
//             'application/msword',
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//         ];

//         if (!allowedTypes.includes(file.type)) {
//             setError('Only PDF, DOC, and DOCX files are allowed');
//             return;
//         }

//         setSelectedFile(file);
//         setError(null);

//         if (parseOption === 'ai') {
//             handleResumeAIParse(file);
//         }
//     }, [parseOption]);

//     const handleResumeAIParse = async (file) => {
//         setIsParsingResume(true);
//         try {
//             const formData = new FormData();
//             formData.append('resume', file);

//             const response = await fetch(`${API_BASE_URL}/parse-resume`, {
//                 method: 'POST',
//                 body: formData
//             });

//             if (!response.ok) throw new Error('Failed to parse resume');

//             const parseData = await response.json();

//             setJobData(prev => ({
//                 ...prev,
//                 companyName: parseData.current_company || '',
//                 roleTitle: parseData.current_title || '',
//                 department: parseData.department || '',
//                 location: parseData.location || '',
//                 jobDescription: parseData.experience || '',
//                 skills: parseData.skills || [],
//                 yearsOfExperience: parseData.years_of_experience || '',
//                 education: parseData.education || []
//             }));

//             toast.success('Resume parsed successfully!');
//         } catch (err) {
//             console.error('Resume parsing error:', err);
//             toast.error('Failed to parse resume. You can still fill details manually.');
//         } finally {
//             setIsParsingResume(false);
//         }
//     };

//     const handleScrapeJob = async () => {
//         if (!url || !selectedSite) return;

//         setIsScraping(true);
//         setError(null);
//         try {
//             const scrapeResponse = await fetch(`${API_BASE_URL}/scrape-job`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     url,
//                     site: selectedSite.domain
//                 })
//             });

//             if (!scrapeResponse.ok) {
//                 throw new Error('Failed to scrape job description');
//             }

//             const scrapeData = await scrapeResponse.json();
//             const descriptionText = scrapeData.description || '';
//             setRawJobDescription(descriptionText);

//             try {
//                 const parseResponse = await fetch(`${API_BASE_URL}/api/case-text/parse`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Accept': 'application/json'
//                     },
//                     body: JSON.stringify({
//                         text: descriptionText,
//                         style: 'job_posting'
//                     })
//                 });

//                 if (!parseResponse.ok) {
//                     throw new Error('Failed to parse job description');
//                 }

//                 const parsedData = await parseResponse.json();

//                 setJobData(prev => ({
//                     ...prev,
//                     companyName: parsedData.company_name || scrapeData.company || prev.companyName,
//                     roleTitle: parsedData.role_title || scrapeData.title || prev.roleTitle,
//                     department: parsedData.department || scrapeData.department || prev.department,
//                     location: parsedData.location || scrapeData.location || prev.location,
//                     salaryRange: parsedData.salary_range || scrapeData.salary_range || prev.salaryRange,
//                     jobDescription: parsedData.job_description || descriptionText,
//                     skills: [...new Set([...prev.skills, ...(parsedData.required_skills || [])])],
//                     employmentType: parsedData.employment_type || prev.employmentType,
//                     remoteType: parsedData.remote_type || prev.remoteType
//                 }));

//                 toast.success('Job details imported successfully!');

//             } catch (parseError) {
//                 console.error('Parsing error:', parseError);
//                 setJobData(prev => ({
//                     ...prev,
//                     companyName: scrapeData.company || prev.companyName,
//                     roleTitle: scrapeData.title || prev.roleTitle,
//                     department: scrapeData.department || prev.department,
//                     location: scrapeData.location || prev.location,
//                     salaryRange: scrapeData.salary_range || prev.salaryRange,
//                     jobDescription: descriptionText
//                 }));

//                 toast.warning('Imported basic job details. Some fields may need manual review.');
//             }

//         } catch (err) {
//             console.error('Error during job import:', err);
//             setError('Failed to import job details. Please try again or enter manually.');
//             toast.error('Import failed. Please try again or enter details manually.');
//         } finally {
//             setIsScraping(false);
//         }
//     };

//     const handleSubmit = async () => {
//         if (!selectedFile) {
//             setError('Please upload a resume file');
//             toast.error('Please upload a resume file');
//             return;
//         }

//         setIsSubmitting(true);
//         try {
//             const { data: { user } } = await supabase.auth.getUser();
//             const { data: membership } = await supabase
//                 .from('agency_members')
//                 .select('agency_id')
//                 .eq('user_id', user.id)
//                 .single();

//             // Upload resume file first
//             const resumeId = uuidv4();
//             const filePath = `agencies/${membership.agency_id}/uploads/${resumeId}/${selectedFile.name}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('resume_uploads')
//                 .upload(filePath, selectedFile);

//             if (uploadError) throw uploadError;

//             const { data: { publicUrl } } = supabase.storage
//                 .from('resume_uploads')
//                 .getPublicUrl(filePath);

//             // Create the resume record
//             const { error: resumeError } = await supabase
//                 .from('resume_uploads')
//                 .insert({
//                     id: resumeId,
//                     agency_id: membership.agency_id,
//                     uploaded_by: user.id,
//                     file_url: publicUrl,
//                     file_path: filePath,
//                     file_name: selectedFile.name,
//                     parsed_data: jobData,
//                     raw_text: rawJobDescription,
//                     status: 'active',
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 });

//             if (resumeError) throw resumeError;

//             // Handle role association
//             if (selectedExistingRole) {
//                 // If using an existing role, create role application
//                 const { error: applicationError } = await supabase
//                     .from('role_applications')
//                     .insert({
//                         id: uuidv4(),
//                         role_id: selectedExistingRole.id,
//                         resume_id: resumeId,
//                         status: 'pending',
//                         created_at: new Date().toISOString(),
//                         updated_at: new Date().toISOString()
//                     });

//                 if (applicationError) throw applicationError;
//             } else {
//                 // Create new role if company and role title are provided
//                 if (jobData.companyName && jobData.roleTitle) {
//                     const { error: roleError } = await supabase
//                         .from('open_roles')
//                         .insert({
//                             id: uuidv4(),
//                             agency_id: membership.agency_id,
//                             resume_id: resumeId,
//                             uploaded_by: user.id,
//                             company_name: jobData.companyName,
//                             role_title: jobData.roleTitle,
//                             department: jobData.department || null,
//                             location: jobData.location || null,
//                             salary_range: jobData.salaryRange || null,
//                             job_description: jobData.jobDescription,
//                             skills: jobData.skills,
//                             years_of_experience: jobData.yearsOfExperience,
//                             education_requirements: jobData.education,
//                             employment_type: jobData.employmentType,
//                             remote_type: jobData.remoteType,
//                             raw_description: rawJobDescription,
//                             status: 'active',
//                             created_at: new Date().toISOString(),
//                             updated_at: new Date().toISOString()
//                         });

//                     if (roleError) throw roleError;
//                 }
//             }

//             // Clean up storage and show success message
//             cleanupStorage();
//             toast.success(
//                 selectedExistingRole
//                     ? 'Resume uploaded and linked to existing role successfully!'
//                     : 'Resume processed and new role created successfully!'
//             );

//             navigate('/dashboard', {
//                 state: {
//                     message: selectedExistingRole
//                         ? 'Resume uploaded and linked to existing role'
//                         : 'Resume processed and new role created'
//                 }
//             });

//         } catch (err) {
//             console.error('Error:', err);
//             setError(err.message || 'Failed to process resume and save job details');
//             toast.error('Failed to process request');
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     const handleInputChange = (field, value) => {
//         setJobData(prev => ({ ...prev, [field]: value }));
//         setError(null);
//     };

//     const renderSkillBadges = () => (
//         <div className="flex flex-wrap gap-2">
//             {jobData.skills.map((skill, index) => (
//                 <span
//                     key={index}
//                     className="px-3 py-1 bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 
//                              rounded-full text-sm flex items-center gap-2"
//                 >
//                     {skill}
//                     <button
//                         onClick={() => handleInputChange('skills', jobData.skills.filter((_, i) => i !== index))}
//                         className="hover:text-blue-600 dark:hover:text-blue-300"
//                     >
//                         <X className="h-4 w-4" />
//                     </button>
//                 </span>
//             ))}
//         </div>
//     );

//     // Check for matching roles
//     useEffect(() => {
//         const checkForMatchingRoles = async () => {
//             if (!jobData.companyName || !jobData.roleTitle) return;

//             try {
//                 const { data: { user } } = await supabase.auth.getUser();
//                 const { data: membership } = await supabase
//                     .from('agency_members')
//                     .select('agency_id')
//                     .eq('user_id', user.id)
//                     .single();

//                 const { data: matches } = await supabase
//                     .from('open_roles')
//                     .select('*')
//                     .eq('agency_id', membership.agency_id)
//                     .ilike('company_name', `%${jobData.companyName}%`)
//                     .ilike('role_title', `%${jobData.roleTitle}%`);

//                 setMatchingRoles(matches || []);
//             } catch (err) {
//                 console.error('Error checking for matching roles:', err);
//             }
//         };

//         checkForMatchingRoles();
//     }, [jobData.companyName, jobData.roleTitle]);

//     return (
//         <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
//             <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-full max-w-6xl flex flex-col">
//                 {/* Header */}
//                 <div className="p-6 border-b dark:border-gray-700">
//                     <div className="flex justify-between items-center">
//                         <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Upload Resume</h2>
//                         <button onClick={handleClose} className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">×</button>
//                     </div>
//                 </div>

//                 {/* Body */}
//                 <div className="flex-1 overflow-y-auto p-6 space-y-8">
//                     {/* Parse Options */}
//                     <div className="flex justify-end space-x-4">
//                         <button
//                             onClick={() => setParseOption('ai')}
//                             className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
//                                     ${parseOption === 'ai'
//                                     ? 'bg-blue-600 text-white'
//                                     : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
//                         >
//                             <Wand2 className="w-4 h-4" />
//                             AI Parse
//                         </button>
//                         <button
//                             onClick={() => setParseOption('manual')}
//                             className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
//                                     ${parseOption === 'manual'
//                                     ? 'bg-blue-600 text-white'
//                                     : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
//                         >
//                             <FileEdit className="w-4 h-4" />
//                             Manual Entry
//                         </button>
//                     </div>

//                     {/* Resume Upload Area */}
//                     <div className="mb-8">
//                         <div
//                             className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 
//                                      text-center hover:border-blue-500 dark:hover:border-blue-400 transition-colors"
//                             onDrop={(e) => {
//                                 e.preventDefault();
//                                 handleFileSelect(e.dataTransfer.files[0]);
//                             }}
//                             onDragOver={(e) => e.preventDefault()}
//                         >
//                             <input
//                                 type="file"
//                                 id="file-upload"
//                                 className="hidden"
//                                 accept=".pdf,.doc,.docx"
//                                 onChange={(e) => handleFileSelect(e.target.files[0])}
//                             />
//                             {selectedFile ? (
//                                 <div className="flex items-center justify-center gap-4">
//                                     <FileText className="h-8 w-8 text-blue-600 dark:text-blue-400" />
//                                     <div>
//                                         <p className="font-medium text-gray-900 dark:text-white">{selectedFile.name}</p>
//                                         <p className="text-sm text-gray-500 dark:text-gray-400">
//                                             {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
//                                         </p>
//                                     </div>
//                                     <button
//                                         onClick={() => setSelectedFile(null)}
//                                         className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
//                                     >
//                                         <X className="h-5 w-5 text-gray-500 dark:text-gray-400" />
//                                     </button>
//                                 </div>
//                             ) : (
//                                 <div>
//                                     <Upload className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
//                                     <label
//                                         htmlFor="file-upload"
//                                         className="cursor-pointer text-blue-600 dark:text-blue-400 
//                                                  hover:text-blue-700 dark:hover:text-blue-300"
//                                     >
//                                         Click to upload or drag and drop
//                                     </label>
//                                     <p className="text-gray-500 dark:text-gray-400 text-sm mt-1">
//                                         PDF, DOC, DOCX up to 10MB
//                                     </p>
//                                 </div>
//                             )}
//                         </div>
//                     </div>

//                     <div className="mb-8">
//                         <div className="flex items-center justify-between mb-4">
//                             <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
//                                 Select Existing Role (Optional)
//                             </h3>
//                             {selectedExistingRole && (
//                                 <button
//                                     onClick={() => handleExistingRoleSelect(null)}
//                                     className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 
//                              dark:hover:text-blue-300 flex items-center gap-1"
//                                 >
//                                     <X className="w-4 h-4" />
//                                     Clear Selection
//                                 </button>
//                             )}
//                         </div>
//                         <div className="relative">
//                             {isLoadingRoles ? (
//                                 <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
//                                     <Loader2 className="w-4 h-4 animate-spin" />
//                                     Loading roles...
//                                 </div>
//                             ) : existingRoles.length > 0 ? (
//                                 <select
//                                     value={selectedExistingRole?.id || ''}
//                                     onChange={(e) => handleExistingRoleSelect(e.target.value)}
//                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
//                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                 >
//                                     <option value="">Create New Role</option>
//                                     {existingRoles.map(role => (
//                                         <option key={role.id} value={role.id}>
//                                             {role.company_name} - {role.role_title}
//                                             {role.location ? ` (${role.location})` : ''}
//                                         </option>
//                                     ))}
//                                 </select>
//                             ) : (
//                                 <p className="text-gray-500 dark:text-gray-400">
//                                     No existing roles found. Create a new one below.
//                                 </p>
//                             )}
//                         </div>
//                         {selectedExistingRole && (
//                             <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
//                                 <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400 mb-2">
//                                     <Info className="w-4 h-4" />
//                                     <span className="font-medium">Using Existing Role</span>
//                                 </div>
//                                 <p className="text-sm text-blue-700 dark:text-blue-300">
//                                     The resume will be associated with the selected role:
//                                     <span className="font-medium">
//                                         {` ${selectedExistingRole.company_name} - ${selectedExistingRole.role_title}`}
//                                     </span>
//                                 </p>
//                             </div>
//                         )}
//                     </div>

//                     {/* Job Details Form */}
//                     <div className="space-y-6">
//                         {/* Job Source Section */}
//                         <div>
//                             <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
//                                 Import Role Details (Optional)
//                             </h3>
//                             <div className="grid grid-cols-3 gap-4 mb-4">
//                                 {jobSites.map(site => (
//                                     <button
//                                         key={site.name}
//                                         onClick={() => setSelectedSite(site)}
//                                         className={`p-4 rounded-lg border ${selectedSite?.name === site.name
//                                             ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
//                                             : 'border-gray-200 dark:border-gray-700'
//                                             } flex items-center gap-3 transition-colors`}
//                                     >
//                                         <JobSiteIcon site={site.name} />
//                                         <span className="text-gray-900 dark:text-white">{site.name}</span>
//                                     </button>
//                                 ))}
//                             </div>
//                             {selectedSite && (
//                                 <div className="flex gap-2">
//                                     <input
//                                         type="url"
//                                         className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                         value={url}
//                                         onChange={(e) => setUrl(e.target.value)}
//                                         placeholder={`Paste ${selectedSite.name} job URL`}
//                                     />
//                                     <button
//                                         onClick={handleScrapeJob}
//                                         disabled={!url || isScraping}
//                                         className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
//                                                  disabled:opacity-50 flex items-center gap-2 transition-colors"
//                                     >
//                                         {isScraping ? (
//                                             <>
//                                                 <Loader2 className="w-5 h-5 animate-spin" />
//                                                 <span>Importing...</span>
//                                             </>
//                                         ) : (
//                                             <span>Import Details</span>
//                                         )}
//                                     </button>
//                                 </div>
//                             )}
//                         </div>

//                         {/* Company and Role Details */}
//                         <div className="grid grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Company Name
//                                 </label>
//                                 <div className="relative">
//                                     <Building className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                     <input
//                                         type="text"
//                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                         value={jobData.companyName}
//                                         onChange={(e) => handleInputChange('companyName', e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Role Title
//                                 </label>
//                                 <div className="relative">
//                                     <BriefcaseIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                     <input
//                                         type="text"
//                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                         value={jobData.roleTitle}
//                                         onChange={(e) => handleInputChange('roleTitle', e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Location and Department */}
//                         <div className="grid grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Location
//                                 </label>
//                                 <div className="relative">
//                                     <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                     <input
//                                         type="text"
//                                         className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                         value={jobData.location}
//                                         onChange={(e) => handleInputChange('location', e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Department
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                     value={jobData.department}
//                                     onChange={(e) => handleInputChange('department', e.target.value)}
//                                 />
//                             </div>
//                         </div>

//                         {/* Employment Type and Remote Type */}
//                         <div className="grid grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Employment Type
//                                 </label>
//                                 <select
//                                     value={jobData.employmentType}
//                                     onChange={(e) => handleInputChange('employmentType', e.target.value)}
//                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                 >
//                                     {employmentTypes.map(type => (
//                                         <option key={type.value} value={type.value}>
//                                             {type.label}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                     Remote Type
//                                 </label>
//                                 <select
//                                     value={jobData.remoteType}
//                                     onChange={(e) => handleInputChange('remoteType', e.target.value)}
//                                     className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                 >
//                                     {remoteTypes.map(type => (
//                                         <option key={type.value} value={type.value}>
//                                             {type.label}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                         </div>

//                         {/* Salary Range */}
//                         <div>
//                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                 Salary Range
//                             </label>
//                             <div className="relative">
//                                 <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                 <input
//                                     type="text"
//                                     className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg 
//                                              bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                     value={jobData.salaryRange}
//                                     onChange={(e) => handleInputChange('salaryRange', e.target.value)}
//                                     placeholder="e.g., $50,000 - $80,000"
//                                 />
//                             </div>
//                         </div>

//                         {/* Skills Section */}
//                         <div>
//                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                 Skills
//                             </label>
//                             <div className="space-y-2">
//                                 <div className="flex gap-2">
//                                     <input
//                                         type="text"
//                                         className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                         placeholder="Add a skill and press Enter"
//                                         onKeyDown={(e) => {
//                                             if (e.key === 'Enter' && e.target.value) {
//                                                 e.preventDefault();
//                                                 handleInputChange('skills', [...jobData.skills, e.target.value]);
//                                                 e.target.value = '';
//                                             }
//                                         }}
//                                     />
//                                 </div>
//                                 {renderSkillBadges()}
//                             </div>
//                         </div>

//                         {/* Job Description */}
//                         <div>
//                             <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
//                                 Job Description
//                             </label>
//                             <textarea
//                                 className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-64 resize-none
//                                          bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                 value={jobData.jobDescription}
//                                 onChange={(e) => handleInputChange('jobDescription', e.target.value)}
//                                 placeholder="Enter job description..."
//                             />
//                         </div>

//                         {/* Similar Roles Warning */}
//                         {matchingRoles.length > 0 && (
//                             <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 
//                                           dark:border-yellow-700 rounded-lg p-4">
//                                 <div className="flex items-start gap-3">
//                                     <Users className="w-5 h-5 text-yellow-600 dark:text-yellow-400 mt-0.5" />
//                                     <div>
//                                         <h4 className="font-medium text-yellow-800 dark:text-yellow-200">
//                                             Similar Roles Found
//                                         </h4>
//                                         <p className="text-sm text-yellow-700 dark:text-yellow-300 mt-1">
//                                             There are {matchingRoles.length} similar open roles:
//                                         </p>
//                                         <ul className="mt-2 space-y-1">
//                                             {matchingRoles.map((role, index) => (
//                                                 <li key={index} className="text-sm text-yellow-700 dark:text-yellow-300">
//                                                     {role.company_name} - {role.role_title}
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>

//                 {/* Footer */}
//                 <div className="p-6 border-t dark:border-gray-700">
//                     <div className="flex justify-between items-center">
//                         <div>
//                             {error && (
//                                 <div className="text-red-600 dark:text-red-400 text-sm flex items-center gap-2">
//                                     <AlertCircle className="w-4 h-4" />
//                                     {error}
//                                 </div>
//                             )}
//                         </div>

//                         <div className="flex gap-4">
//                             <button
//                                 onClick={handleClose}
//                                 className="px-6 py-2 border dark:border-gray-600 rounded-lg 
//                                          hover:bg-gray-50 dark:hover:bg-gray-700 
//                                          text-gray-700 dark:text-gray-300 transition-colors"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 onClick={handleSubmit}
//                                 disabled={!selectedFile || isSubmitting}
//                                 className="px-6 py-2 bg-blue-600 text-white rounded-lg 
//                                          hover:bg-blue-700 disabled:opacity-50 
//                                          flex items-center gap-2 transition-colors"
//                             >
//                                 {isSubmitting ? (
//                                     <>
//                                         <Loader2 className="w-5 h-5 animate-spin" />
//                                         <span>Processing...</span>
//                                     </>
//                                 ) : (
//                                     <span>Upload Resume & Save Details</span>
//                                 )}
//                             </button>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Loading Overlay */}
//                 {(isParsingResume || (uploadProgress > 0 && uploadProgress < 100)) && <LoadingSpinner />}
//             </div>
//         </div>
//     );
// };

// export default CandidateJobUploadModal;

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    FileText,
    AlertCircle,
    Loader2,
    X,
    Info,
    ArrowLeft,
    Building,
    MapPin,
    BriefcaseIcon,
    DollarSign,
    Users,
    Wand2,
    FileEdit,
    Link,
    Forward,
    Upload
} from 'lucide-react';

import { toast } from 'sonner';
import supabase from '../../supabaseClient';

const API_BASE_URL = 'https://8217-45-130-83-55.ngrok-free.app';


const JobSiteIcon = ({ site }) => {
    const icons = {
        LinkedIn: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#0A66C2" />
                <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
            </svg>
        ),
        Indeed: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#2164F3" />
                <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
                <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
            </svg>
        ),
        Glassdoor: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#0CAA41" />
                <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
            </svg>
        ),
        Dice: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#EB1C26" />
                <path d="M10 10h20v20H10z" fill="white" />
                <circle cx="15" cy="15" r="2" fill="#EB1C26" />
                <circle cx="25" cy="15" r="2" fill="#EB1C26" />
                <circle cx="15" cy="25" r="2" fill="#EB1C26" />
                <circle cx="25" cy="25" r="2" fill="#EB1C26" />
            </svg>
        ),
        Monster: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#6E45A5" />
                <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
            </svg>
        ),
        'Talent.com': (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#1D2C4D" />
                <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
                <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
            </svg>
        ),
        Greenhouse: (
            <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                <rect width="40" height="40" rx="8" fill="#3AB549" />
                <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
                <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
            </svg>
        )
    };

    return icons[site] || null;
};

const BASE_ROLES = [
    "Software Engineer",
    "Senior Software Engineer",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "DevOps Engineer",
    "Data Scientist",
    "Data Analyst",
    "Machine Learning Engineer",
    "Product Manager",
    "Project Manager",
    "UI/UX Designer",
    "Quality Assurance Engineer",
    "Business Analyst",
    "Other (Custom)"
];

const BASE_COMPANIES = [
    "Google",
    "Microsoft",
    "Apple",
    "Amazon",
    "Meta",
    "Netflix",
    "Uber",
    "Airbnb",
    "Twitter",
    "LinkedIn",
    "Other (Custom)"
];

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}
        {...props}
    />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`flex flex-col space-y-1.5 p-6 ${className}`}
        {...props}
    />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={`text-lg font-semibold leading-none tracking-tight ${className}`}
        {...props}
    />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
    <p
        ref={ref}
        className={`text-sm text-muted-foreground ${className}`}
        {...props}
    />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 ${className}`} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`flex items-center p-6 pt-0 ${className}`}
        {...props}
    />
))
CardFooter.displayName = "CardFooter"


const EMPLOYMENT_TYPES = [
    { value: 'full-time', label: 'Full-time' },
    { value: 'part-time', label: 'Part-time' },
    { value: 'contract', label: 'Contract' },
    { value: 'temporary', label: 'Temporary' },
    { value: 'internship', label: 'Internship' }
];

const REMOTE_TYPES = [
    { value: 'on-site', label: 'On-site' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'remote', label: 'Remote' }
];

const JOB_SITES = [
    { name: 'LinkedIn', domain: 'linkedin.com' },
    { name: 'Indeed', domain: 'indeed.com' },
    { name: 'Glassdoor', domain: 'glassdoor.com' }
];

const ResumeUpload = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Initialize states
    const [selectedFile, setSelectedFile] = useState(() => {
        const uploadedFile = localStorage.getItem('uploadedFile');
        if (uploadedFile) {
            return JSON.parse(uploadedFile);
        }
        const savedFileInfo = localStorage.getItem('uploadFormFile');
        return savedFileInfo ? JSON.parse(savedFileInfo) : null;
    });

    const [jobData, setJobData] = useState(() => {
        const savedData = localStorage.getItem('uploadFormJobData');
        return savedData ? JSON.parse(savedData) : {
            companyName: '',
            roleTitle: '',
            department: '',
            location: '',
            salaryRange: '',
            jobDescription: '',
            skills: [],
            yearsOfExperience: '',
            education: [],
            employmentType: 'full-time',
            remoteType: 'on-site',
            notes: ''
        };
    });

    const [url, setUrl] = useState(() =>
        localStorage.getItem('uploadFormUrl') || ''
    );

    // Inside your ResumeUpload component

    const handleNewUpload = () => {
        // Clear all related localStorage items
        localStorage.removeItem('uploadFormFile');
        localStorage.removeItem('uploadFormJobData');
        localStorage.removeItem('uploadFormParseOption');
        localStorage.removeItem('uploadFormUrl');
        localStorage.removeItem('uploadFormSelectedSite');
        localStorage.removeItem('uploadFormStep');
        localStorage.removeItem('uploadFormError');

        // Reset state variables
        setSelectedFile(null);
        setJobData({
            companyName: '',
            roleTitle: '',
            department: '',
            location: '',
            salaryRange: '',
            jobDescription: '',
            skills: [],
            yearsOfExperience: '',
            education: [],
            employmentType: 'full-time',
            remoteType: 'on-site',
            notes: ''
        });
        setUrl('');
        setSelectedSite(null);
        setParseOption('ai');
        setStep('upload');
        setError(null);
    };


    const [selectedSite, setSelectedSite] = useState(() => {
        const saved = localStorage.getItem('uploadFormSelectedSite');
        return saved ? JSON.parse(saved) : null;
    });

    const [parseOption, setParseOption] = useState(() =>
        localStorage.getItem('uploadFormParseOption') || 'ai'
    );

    const [step, setStep] = useState(() => localStorage.getItem('uploadFormStep') || 'upload');
    const [error, setError] = useState(() => localStorage.getItem('uploadFormError') || null);



    const [isScraping, setIsScraping] = useState(false);
    const [matchingRoles, setMatchingRoles] = useState([]);
    const [existingRoles, setExistingRoles] = useState([]);
    const [selectedExistingRole, setSelectedExistingRole] = useState(null);
    const [isLoadingRoles, setIsLoadingRoles] = useState(true);
    const [rawJobDescription, setRawJobDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [subscriptionChannel, setSubscriptionChannel] = useState(null);
    const [uploadType, setUploadType] = useState('simple');

    useEffect(() => {
        localStorage.setItem('uploadFormStep', step);
    }, [step]);

    useEffect(() => {
        if (error) {
            localStorage.setItem('uploadFormError', error);
        } else {
            localStorage.removeItem('uploadFormError');
        }
    }, [error]);


    useEffect(() => {
        if (selectedFile) {
            const fileInfo = {
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
                lastModified: selectedFile.lastModified
            };
            localStorage.setItem('uploadFormFile', JSON.stringify(fileInfo));
        } else {
            localStorage.removeItem('uploadFormFile');
        }
    }, [selectedFile]);


    useEffect(() => {
        localStorage.setItem('uploadFormJobData', JSON.stringify(jobData));
    }, [jobData]);

    useEffect(() => {
        localStorage.setItem('uploadFormUrl', url);
    }, [url]);

    useEffect(() => {
        if (selectedSite) {
            localStorage.setItem('uploadFormSelectedSite', JSON.stringify(selectedSite));
        } else {
            localStorage.removeItem('uploadFormSelectedSite');
        }
    }, [selectedSite]);

    useEffect(() => {
        localStorage.setItem('uploadFormParseOption', parseOption);
    }, [parseOption]);

    // Load existing roles
    useEffect(() => {
        const fetchExistingRoles = async () => {
            try {
                const { data: { user } } = await supabase.auth.getUser();
                const { data: membership } = await supabase
                    .from('agency_members')
                    .select('agency_id')
                    .eq('user_id', user.id)
                    .single();

                if (membership) {
                    const { data: roles } = await supabase
                        .from('open_roles')
                        .select('*')
                        .eq('agency_id', membership.agency_id)
                        .eq('status', 'active')
                        .order('created_at', { ascending: false });

                    setExistingRoles(roles || []);
                }
            } catch (err) {
                console.error('Error fetching roles:', err);
                toast.error('Failed to fetch existing roles');
            } finally {
                setIsLoadingRoles(false);
            }
        };

        fetchExistingRoles();
    }, []);

    // Check for matching roles
    useEffect(() => {
        const checkForMatchingRoles = async () => {
            if (!jobData.companyName || !jobData.roleTitle) return;

            try {
                const { data: { user } } = await supabase.auth.getUser();
                const { data: membership } = await supabase
                    .from('agency_members')
                    .select('agency_id')
                    .eq('user_id', user.id)
                    .single();

                const { data: matches } = await supabase
                    .from('open_roles')
                    .select('*')
                    .eq('agency_id', membership.agency_id)
                    .ilike('company_name', `%${jobData.companyName}%`)
                    .ilike('role_title', `%${jobData.roleTitle}%`);

                setMatchingRoles(matches || []);
            } catch (err) {
                console.error('Error checking for matching roles:', err);
            }
        };

        checkForMatchingRoles();
    }, [jobData.companyName, jobData.roleTitle]);

    const handleFileSelect = useCallback((file) => {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            setError('File size must be less than 10MB');
            return;
        }

        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (!allowedTypes.includes(file.type)) {
            setError('Only PDF, DOC, and DOCX files are allowed');
            return;
        }

        setSelectedFile(file);
        setError(null);
        setStep('details');
    }, []);

    const handleScrapeJob = async () => {
        if (!url || !selectedSite) return;

        setIsScraping(true);
        try {
            const response = await fetch(`${API_BASE_URL}/scrape-job`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    url,
                    site: selectedSite.domain
                })
            });

            if (!response.ok) throw new Error('Failed to scrape job');

            const data = await response.json();
            setRawJobDescription(data.description || '');

            setJobData(prev => ({
                ...prev,
                companyName: data.company || prev.companyName,
                roleTitle: data.title || prev.roleTitle,
                department: data.department || prev.department,
                location: data.location || prev.location,
                salaryRange: data.salary_range || prev.salaryRange,
                jobDescription: data.description || prev.jobDescription
            }));

            toast.success('Job details imported successfully!');
        } catch (err) {
            console.error('Scraping error:', err);
            toast.error('Failed to import job details');
        } finally {
            setIsScraping(false);
        }
    };

    const createInitialRecord = async (fileDetails) => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
            const { data: membership } = await supabase
                .from('agency_members')
                .select('*, agency:agencies(*)')
                .eq('user_id', user.id)
                .single();

            const recordId = uuidv4();
            const initialData = {
                id: recordId,
                agency_id: membership.agency.id,
                uploaded_by: user.id,
                file_name: fileDetails.name,
                file_type: fileDetails.type,
                candidate_name: fileDetails.name.split('.')[0],
                status: uploadType === 'parse' ? 'parsing' : 'uploading',
                parse_requested: uploadType === 'parse',
                parse_status: uploadType === 'parse' ? 'pending' : null,
                created_at: new Date().toISOString()
            };

            await supabase.from('resume_uploads').insert(initialData);
            return { recordId, membership };
        } catch (err) {
            console.error('Error creating record:', err);
            throw new Error('Failed to initialize upload');
        }
    };

    // Subscribe to realtime updates for this upload
    useEffect(() => {
        const channel = supabase.channel('dashboard-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'resume_uploads',
                    // Only listen for changes to this specific upload
                    filter: location.state?.activeUploadId ?
                        `id=eq.${location.state.activeUploadId}` : undefined
                },
                (payload) => {
                    console.log('Change received!', payload);
                    if (payload.new.status === 'completed') {
                        setUploadProgress(100);
                    } else if (payload.new.status === 'parsing') {
                        setUploadProgress(75);
                    } else if (payload.new.status === 'uploading') {
                        setUploadProgress(50);
                    }
                }
            )
            .subscribe();

        return () => {
            channel.unsubscribe();
        };
    }, [location.state?.activeUploadId]);

    const handleSubmit = async () => {
        if (!selectedFile) {
            setError('Please upload a resume file');
            return;
        }

        setIsSubmitting(true);
        let recordId;

        try {
            // Get user data first
            const { data: { user } } = await supabase.auth.getUser();

            // Create initial record with correct status
            const initialStatus = parseOption === 'ai' ? 'parsing' : 'uploading';
            const { recordId: newRecordId, membership } = await createInitialRecord({
                name: selectedFile.name,
                type: selectedFile.type,
                status: initialStatus,
                parse_requested: parseOption === 'ai'
            });

            recordId = newRecordId;

            // Navigate immediately to dashboard
            navigate('/dashboard', {
                state: {
                    activeUploadId: recordId,
                    uploadType: parseOption === 'ai' ? 'parse' : 'simple'
                }
            });

            if (parseOption === 'ai') {
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('record_id', recordId);
                formData.append('user_id', user.id);
                formData.append('agency_id', membership.agency_id);
                formData.append('role', jobData.roleTitle);
                formData.append('company', jobData.companyName);
                formData.append('notes', jobData.notes);
                formData.append('parse', 'true');

                // Update to parsing status
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'parsing',
                        parse_status: 'pending'
                    })
                    .eq('id', recordId);

                const response = await fetch(`${API_BASE_URL}/api/v1/resume/upload_resumeably`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) throw new Error('Parse API Error');

                const parseData = await response.json();

                // Update with completed status
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'completed',
                        parse_status: 'completed',
                        resume_json: parseData.resume_json,
                        resume_text: parseData.resume_text
                    })
                    .eq('id', recordId);

            } else {
                // Basic upload process
                const filePath = `agencies/${membership.agency_id}/uploads/${recordId}/${selectedFile.name}`;

                // Update to uploading status
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'uploading'
                    })
                    .eq('id', recordId);

                const { error: uploadError } = await supabase.storage
                    .from('resume_uploads')
                    .upload(filePath, selectedFile);

                if (uploadError) throw uploadError;

                const { data: { publicUrl } } = supabase.storage
                    .from('resume_uploads')
                    .getPublicUrl(filePath);

                // Update with completed status
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'completed',
                        file_url: publicUrl,
                        file_path: filePath,
                        role: jobData.roleTitle,
                        company: jobData.companyName,
                        notes: jobData.notes
                    })
                    .eq('id', recordId);
            }

        } catch (err) {
            console.error('Upload error:', err);
            if (recordId) {
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'failed',
                        parse_status: parseOption === 'ai' ? 'failed' : null,
                        error_message: err.message
                    })
                    .eq('id', recordId);
            }
            setError(err.message || 'Failed to upload resume');
            toast.error('Upload failed');
        }
    };

    // Modify createInitialRecord to match the example

    // Cleanup function
    const handleClose = () => {
        const cleanupStorage = () => {
            localStorage.removeItem('uploadFormFile');
            localStorage.removeItem('uploadFormJobData');
            localStorage.removeItem('uploadFormParseOption');
            localStorage.removeItem('uploadFormUrl');
            localStorage.removeItem('uploadFormSelectedSite');
            localStorage.removeItem('uploadFormRawDesc');
        };

        cleanupStorage();
        navigate('/dashboard');
    };

    const renderSkillBadges = () => (
        <div className="flex flex-wrap gap-2">
            {jobData.skills.map((skill, index) => (
                <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 
                             rounded-full text-sm flex items-center gap-2"
                >
                    {skill}
                    <button
                        onClick={() => {
                            const newSkills = jobData.skills.filter((_, i) => i !== index);
                            setJobData(prev => ({ ...prev, skills: newSkills }));
                        }}
                        className="hover:text-blue-600 dark:hover:text-blue-300"
                    >
                        <X className="h-4 w-4" />
                    </button>
                </span>
            ))}
        </div>
    );

    return (
        <div className="min-h-screen bg-slate-50 dark:bg-gray-900 p-8">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <div className="flex items-center gap-4 mb-8">
                    <button
                        onClick={handleClose}
                        className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
                    >
                        <ArrowLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                    </button>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                            Upload Resume
                        </h1>
                        <p className="text-gray-600 dark:text-gray-400">
                            Add a new resume and job details to your collection
                        </p>
                    </div>
                    {/* New Upload Button */}
                    <button
                        onClick={handleNewUpload}
                        className="ml-auto px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                    >
                        New Upload
                    </button>
                </div>

                {error && (
                    <div className="mb-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-700 
                                  rounded-xl p-4 text-red-800 dark:text-red-200 flex items-center gap-2">
                        <AlertCircle className="h-5 w-5 flex-shrink-0" />
                        <p>{error}</p>
                    </div>
                )}

                <Card>
                    <CardHeader>
                        <div className="flex justify-between items-center w-full">
                            <div>
                                <CardTitle>Resume Details</CardTitle>
                                {selectedFile && (
                                    <CardDescription>
                                        Uploaded File: <span className="font-medium">{selectedFile.name}</span>
                                    </CardDescription>
                                )}
                            </div>
                            <div className="flex gap-4">
                                <button
                                    onClick={() => setParseOption('ai')}
                                    className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                        ${parseOption === 'ai'
                                            ? 'bg-blue-600 text-white'
                                            : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
                                >
                                    <Wand2 className="w-4 h-4" />
                                    AI Parse
                                </button>
                                <button
                                    onClick={() => setParseOption('manual')}
                                    className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                        ${parseOption === 'manual'
                                            ? 'bg-blue-600 text-white'
                                            : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
                                >
                                    <FileEdit className="w-4 h-4" />
                                    Manual Entry
                                </button>
                            </div>
                        </div>
                    </CardHeader>


                    <CardContent>
                        {step === 'upload' ? (
                            <div
                                className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl p-12 
                                     bg-gray-50 dark:bg-gray-800/50 transition-all duration-300
                                     hover:border-blue-500 dark:hover:border-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20"
                                onDrop={(e) => {
                                    e.preventDefault();
                                    handleFileSelect(e.dataTransfer.files[0]);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="hidden"
                                    accept=".pdf,.doc,.docx"
                                    onChange={(e) => handleFileSelect(e.target.files[0])}
                                />
                                {selectedFile ? (
                                    <div className="flex items-center justify-center gap-4">
                                        <FileText className="h-16 w-16 text-blue-600 dark:text-blue-400" />
                                        <div>
                                            <p className="font-medium text-gray-900 dark:text-white">
                                                {selectedFile.name}
                                            </p>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => setSelectedFile(null)}
                                            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                                        >
                                            <X className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <Upload className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
                                        <label
                                            htmlFor="file-upload"
                                            className="cursor-pointer text-blue-600 dark:text-blue-400 
                                                 hover:text-blue-700 dark:hover:text-blue-300"
                                        >
                                            Click to upload or drag and drop
                                        </label>
                                        <p className="text-gray-500 dark:text-gray-400 text-sm mt-1">
                                            PDF, DOC, DOCX up to 10MB
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="space-y-6">
                                {/* Existing Role Selection */}
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                                        Select Existing Role (Optional)
                                    </h3>
                                    <select
                                        value={selectedExistingRole?.id || ''}
                                        onChange={(e) => {
                                            const role = existingRoles.find(r => r.id === e.target.value);
                                            if (role) {
                                                setSelectedExistingRole(role);
                                                setJobData(prev => ({
                                                    ...prev,
                                                    companyName: role.company_name,
                                                    roleTitle: role.role_title,
                                                    department: role.department || '',
                                                    location: role.location || '',
                                                    salaryRange: role.salary_range || '',
                                                    jobDescription: role.job_description || '',
                                                    skills: role.skills || [],
                                                    employmentType: role.employment_type || 'full-time',
                                                    remoteType: role.remote_type || 'on-site'
                                                }));
                                            }
                                        }}
                                        className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
                                             bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                    >
                                        <option value="">Create New Role</option>
                                        {existingRoles.map(role => (
                                            <option key={role.id} value={role.id}>
                                                {role.company_name} - {role.role_title}
                                                {role.location ? ` (${role.location})` : ''}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Import from Job Site */}
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                                        Import from Job Site (Optional)
                                    </h3>
                                    <div className="grid grid-cols-3 gap-4 mb-4">
                                        {JOB_SITES.map(site => (
                                            <button
                                                key={site.name}
                                                onClick={() => setSelectedSite(site)}
                                                className={`p-4 rounded-lg border ${selectedSite?.name === site.name
                                                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                                                    : 'border-gray-200 dark:border-gray-700'
                                                    } flex items-center gap-3`}
                                            >
                                                <span className="text-gray-900 dark:text-white">
                                                    {site.name}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                    {selectedSite && (
                                        <div className="flex gap-2">
                                            <input
                                                type="url"
                                                value={url}
                                                onChange={(e) => setUrl(e.target.value)}
                                                placeholder={`Paste ${selectedSite.name} job URL`}
                                                className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
                                                     bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                            />
                                            <button
                                                onClick={handleScrapeJob}
                                                disabled={!url || isScraping}
                                                className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                                                     hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                                            >
                                                {isScraping ? (
                                                    <>
                                                        <Loader2 className="w-5 h-5 animate-spin" />
                                                        <span>Importing...</span>
                                                    </>
                                                ) : (
                                                    <span>Import Details</span>
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Job Details Form */}
                                <div className="space-y-6">
                                    {/* Company and Role */}
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Company Name
                                            </label>
                                            <div className="relative">
                                                <Building className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                                <input
                                                    type="text"
                                                    className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
                                                    value={jobData.companyName}
                                                    onChange={(e) => setJobData(prev => ({
                                                        ...prev,
                                                        companyName: e.target.value
                                                    }))}
                                                    placeholder="Enter company name"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Role Title
                                            </label>
                                            <div className="relative">
                                                <BriefcaseIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                                <input
                                                    type="text"
                                                    className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
                                                    value={jobData.roleTitle}
                                                    onChange={(e) => setJobData(prev => ({
                                                        ...prev,
                                                        roleTitle: e.target.value
                                                    }))}
                                                    placeholder="Enter role title"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Location and Department */}
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Location
                                            </label>
                                            <div className="relative">
                                                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                                <input
                                                    type="text"
                                                    className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
                                                    value={jobData.location}
                                                    onChange={(e) => setJobData(prev => ({
                                                        ...prev,
                                                        location: e.target.value
                                                    }))}
                                                    placeholder="Enter location"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Department
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
                                                value={jobData.department}
                                                onChange={(e) => setJobData(prev => ({
                                                    ...prev,
                                                    department: e.target.value
                                                }))}
                                                placeholder="Enter department"
                                            />
                                        </div>
                                    </div>

                                    {/* Employment Type and Remote Type */}
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Employment Type
                                            </label>
                                            <select
                                                value={jobData.employmentType}
                                                onChange={(e) => setJobData(prev => ({
                                                    ...prev,
                                                    employmentType: e.target.value
                                                }))}
                                                className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
                                            >
                                                {EMPLOYMENT_TYPES.map(type => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1">
                                                Remote Type
                                            </label>
                                            <select
                                                value={jobData.remoteType}
                                                onChange={(e) => setJobData(prev => ({
                                                    ...prev,
                                                    remoteType: e.target.value
                                                }))}
                                                className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
                                            >
                                                {REMOTE_TYPES.map(type => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Salary Range */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Salary Range
                                        </label>
                                        <div className="relative">
                                            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                            <input
                                                type="text"
                                                className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
                                                value={jobData.salaryRange}
                                                onChange={(e) => setJobData(prev => ({
                                                    ...prev,
                                                    salaryRange: e.target.value
                                                }))}
                                                placeholder="e.g., $80,000 - $120,000"
                                            />
                                        </div>
                                    </div>

                                    {/* Skills */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Skills
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg mb-2"
                                            placeholder="Type a skill and press Enter"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && e.target.value.trim()) {
                                                    e.preventDefault();
                                                    setJobData(prev => ({
                                                        ...prev,
                                                        skills: [...new Set([...prev.skills, e.target.value.trim()])]
                                                    }));
                                                    e.target.value = '';
                                                }
                                            }}
                                        />
                                        {renderSkillBadges()}
                                    </div>

                                    {/* Job Description */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Job Description
                                        </label>
                                        <textarea
                                            className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-48 resize-none"
                                            value={jobData.jobDescription}
                                            onChange={(e) => setJobData(prev => ({
                                                ...prev,
                                                jobDescription: e.target.value
                                            }))}
                                            placeholder="Enter job description..."
                                        />
                                    </div>

                                    {/* Notes */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Additional Notes
                                        </label>
                                        <textarea
                                            className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-32 resize-none"
                                            value={jobData.notes}
                                            onChange={(e) => setJobData(prev => ({
                                                ...prev,
                                                notes: e.target.value
                                            }))}
                                            placeholder="Add any additional notes..."
                                        />
                                    </div>

                                    {/* Similar Roles Warning */}
                                    {matchingRoles.length > 0 && (
                                        <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 
                                                      dark:border-yellow-700 rounded-lg p-4">
                                            <div className="flex items-start gap-3">
                                                <Users className="w-5 h-5 text-yellow-600 dark:text-yellow-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-yellow-800 dark:text-yellow-200">
                                                        Similar Roles Found
                                                    </h4>
                                                    <p className="text-sm text-yellow-700 dark:text-yellow-300 mt-1">
                                                        There are {matchingRoles.length} similar roles in your system:
                                                    </p>
                                                    <ul className="mt-2 space-y-1">
                                                        {matchingRoles.map((role, index) => (
                                                            <li key={index} className="text-sm text-yellow-700 dark:text-yellow-300">
                                                                {role.company_name} - {role.role_title}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Form Actions */}
                                <div className="flex justify-between pt-6">
                                    <button
                                        onClick={() => setStep('upload')}
                                        className="text-blue-600 dark:text-blue-400 hover:text-blue-700 
                                                 dark:hover:text-blue-300"
                                    >
                                        Back to Upload
                                    </button>
                                    <button
                                        onClick={handleSubmit}
                                        disabled={!selectedFile}
                                        className="px-6 py-2 bg-blue-600 text-white rounded-lg 
                                                 hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                                    >
                                        Continue to Dashboard
                                    </button>
                                </div>
                            </div>
                        )}
                    </CardContent>
                </Card>

                {/* AI Parse Info Section */}
                {parseOption === 'ai' && (
                    <div className="mt-6 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 
                                  dark:border-blue-700 rounded-xl p-6">
                        <div className="flex items-start gap-3">
                            <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 flex-shrink-0 mt-1" />
                            <div>
                                <h3 className="text-blue-900 dark:text-blue-200 font-medium">
                                    About AI Parse
                                </h3>
                                <div className="mt-2 space-y-3 text-sm text-blue-800 dark:text-blue-300">
                                    <p>
                                        Our AI will automatically analyze your resume and extract:
                                    </p>
                                    <ul className="space-y-2 ml-4">
                                        <li className="flex items-center gap-2">
                                            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                                            Contact details and personal information
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                                            Work experience and employment history
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                                            Education background and qualifications
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                                            Skills, certifications, and achievements
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                                            Projects and volunteer experience
                                        </li>
                                    </ul>
                                    <div className="mt-4 bg-blue-100 dark:bg-blue-900/40 rounded-lg p-4">
                                        <p className="text-sm flex items-center gap-2">
                                            <Info className="h-4 w-4" />
                                            Processing will begin automatically on the dashboard
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResumeUpload;




// const ResumeUpload = () => {
//     const navigate = useNavigate();
//     const location = useLocation();

//     // Initialize states with localStorage values
//     const [selectedFile, setSelectedFile] = useState(() => {
//         const savedFileInfo = localStorage.getItem('uploadFormFile');
//         return savedFileInfo ? JSON.parse(savedFileInfo) : null;
//     });

//     const [parseOption, setParseOption] = useState(() =>
//         localStorage.getItem('uploadFormParseOption') || 'ai'
//     );

//     const [jobData, setJobData] = useState(() => {
//         const savedData = localStorage.getItem('uploadFormJobData');
//         return savedData ? JSON.parse(savedData) : {
//             companyName: '',
//             roleTitle: '',
//             department: '',
//             location: '',
//             salaryRange: '',
//             jobDescription: '',
//             skills: [],
//             yearsOfExperience: '',
//             education: [],
//             employmentType: 'full-time',
//             remoteType: 'on-site',
//             notes: ''
//         };
//     });

//     const [url, setUrl] = useState(() =>
//         localStorage.getItem('uploadFormUrl') || ''
//     );

//     const [selectedSite, setSelectedSite] = useState(() => {
//         const saved = localStorage.getItem('uploadFormSelectedSite');
//         return saved ? JSON.parse(saved) : null;
//     });

//     const [rawJobDescription, setRawJobDescription] = useState(() =>
//         localStorage.getItem('uploadFormRawDesc') || ''
//     );

//     // Other state declarations
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [error, setError] = useState(null);
//     const [step, setStep] = useState('upload');
//     const [isParsingResume, setIsParsingResume] = useState(false);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [isScraping, setIsScraping] = useState(false);
//     const [matchingRoles, setMatchingRoles] = useState([]);
//     const [existingRoles, setExistingRoles] = useState([]);
//     const [selectedExistingRole, setSelectedExistingRole] = useState(null);
//     const [isLoadingRoles, setIsLoadingRoles] = useState(true);

//     // localStorage persistence effects
//     useEffect(() => {
//         if (selectedFile) {
//             const fileInfo = {
//                 name: selectedFile.name,
//                 size: selectedFile.size,
//                 type: selectedFile.type,
//                 lastModified: selectedFile.lastModified
//             };
//             localStorage.setItem('uploadFormFile', JSON.stringify(fileInfo));
//         } else {
//             localStorage.removeItem('uploadFormFile');
//         }
//     }, [selectedFile]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormJobData', JSON.stringify(jobData));
//     }, [jobData]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormParseOption', parseOption);
//     }, [parseOption]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormUrl', url);
//     }, [url]);

//     useEffect(() => {
//         if (selectedSite) {
//             localStorage.setItem('uploadFormSelectedSite', JSON.stringify(selectedSite));
//         } else {
//             localStorage.removeItem('uploadFormSelectedSite');
//         }
//     }, [selectedSite]);

//     useEffect(() => {
//         localStorage.setItem('uploadFormRawDesc', rawJobDescription);
//     }, [rawJobDescription]);

//     // Beforeunload handler
//     useEffect(() => {
//         const handleBeforeUnload = (e) => {
//             if (selectedFile || jobData.companyName || jobData.roleTitle) {
//                 e.preventDefault();
//                 e.returnValue = '';
//             }
//         };

//         window.addEventListener('beforeunload', handleBeforeUnload);
//         return () => window.removeEventListener('beforeunload', handleBeforeUnload);
//     }, [selectedFile, jobData]);

//     // Cleanup function
//     const cleanupStorage = () => {
//         localStorage.removeItem('uploadFormFile');
//         localStorage.removeItem('uploadFormJobData');
//         localStorage.removeItem('uploadFormParseOption');
//         localStorage.removeItem('uploadFormUrl');
//         localStorage.removeItem('uploadFormSelectedSite');
//         localStorage.removeItem('uploadFormRawDesc');
//     };

//     // Handle close/cancel
//     const handleClose = () => {
//         cleanupStorage();
//         navigate('/dashboard');
//     };
//     // Load existing roles
//     useEffect(() => {
//         const fetchExistingRoles = async () => {
//             try {
//                 const {data: {user} } = await supabase.auth.getUser();
//                 const {data: membership } = await supabase
//                     .from('agency_members')
//                     .select('agency_id')
//                     .eq('user_id', user.id)
//                     .single();

//                 if (membership) {
//                     const {data: roles } = await supabase
//                         .from('open_roles')
//                         .select('*')
//                         .eq('agency_id', membership.agency_id)
//                         .eq('status', 'active')
//                         .order('created_at', {ascending: false });

//                     setExistingRoles(roles || []);
//                 }
//             } catch (err) {
//                 console.error('Error fetching roles:', err);
//                 toast.error('Failed to fetch existing roles');
//             } finally {
//                 setIsLoadingRoles(false);
//             }
//         };

//         fetchExistingRoles();
//     }, []);

//     // Realtime updates subscription
//     useEffect(() => {
//         const channel = supabase.channel('upload_status')
//             .on('postgres_changes', {
//                 event: '*',
//                 schema: 'public',
//                 table: 'resume_uploads',
//             }, (payload) => {
//                 if (payload.new.status === 'completed') {
//                     setUploadProgress(100);
//                     navigate('/dashboard', {
//                         state: {message: 'Resume uploaded successfully' }
//                     });
//                 } else if (payload.new.status === 'parsing') {
//                     setUploadProgress(75);
//                 } else if (payload.new.status === 'uploading') {
//                     setUploadProgress(50);
//                 }
//             })
//             .subscribe();

//         return () => {
//             channel.unsubscribe();
//         };
//     }, [navigate]);

//     // Check for matching roles
//     useEffect(() => {
//         const checkForMatchingRoles = async () => {
//             if (!jobData.companyName || !jobData.roleTitle) return;

//             try {
//                 const {data: {user} } = await supabase.auth.getUser();
//                 const {data: membership } = await supabase
//                     .from('agency_members')
//                     .select('agency_id')
//                     .eq('user_id', user.id)
//                     .single();

//                 const {data: matches } = await supabase
//                     .from('open_roles')
//                     .select('*')
//                     .eq('agency_id', membership.agency_id)
//                     .ilike('company_name', `%${jobData.companyName}%`)
//                     .ilike('role_title', `%${jobData.roleTitle}%`);

//                 setMatchingRoles(matches || []);
//             } catch (err) {
//                 console.error('Error checking for matching roles:', err);
//             }
//         };

//         checkForMatchingRoles();
//     }, [jobData.companyName, jobData.roleTitle]);

//     const handleFileSelect = useCallback((file) => {
//         if (!file) return;

//         if (file.size > 10 * 1024 * 1024) {
//             setError('File size must be less than 10MB');
//             return;
//         }

//         const allowedTypes = [
//             'application/pdf',
//             'application/msword',
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//         ];

//         if (!allowedTypes.includes(file.type)) {
//             setError('Only PDF, DOC, and DOCX files are allowed');
//             return;
//         }

//         setSelectedFile(file);
//         setError(null);
//         setStep('details');

//         if (parseOption === 'ai') {
//             handleResumeAIParse(file);
//         }
//     }, [parseOption]);




//     const handleResumeAIParse = async (file) => {
//         setIsParsingResume(true);
//         let recordId;
//         setUploadProgress(25);

//         try {
//             const {recordId: newRecordId, membership } = await createInitialRecord(file);
//             recordId = newRecordId;
//             setUploadProgress(50);

//             const formData = new FormData();
//             formData.append('file', file);
//             formData.append('record_id', recordId);
//             formData.append('user_id', membership.user_id);
//             formData.append('agency_id', membership.agency_id);
//             formData.append('parse', 'true');

//             setUploadProgress(75);

//             const response = await fetch(`${API_BASE_URL}/upload_resumeably`, {
//                 method: 'POST',
//                 body: formData
//             });

//             if (!response.ok) throw new Error('Failed to parse resume');

//             const parseData = await response.json();

//             // Update the resume record with parsed data
//             await supabase
//                 .from('resume_uploads')
//                 .update({
//                     status: 'completed',
//                     parse_status: 'completed',
//                     resume_json: parseData.resume_json,
//                     resume_text: parseData.resume_text,
//                     parsed_data: parseData
//                 })
//                 .eq('id', recordId);

//             // Update form data with parsed information
//             setJobData(prev => ({
//                 ...prev,
//                 companyName: parseData.current_company || '',
//                 roleTitle: parseData.current_title || '',
//                 department: parseData.department || '',
//                 location: parseData.location || '',
//                 jobDescription: parseData.experience || '',
//                 skills: parseData.skills || [],
//                 yearsOfExperience: parseData.years_of_experience || '',
//                 education: parseData.education || []
//             }));

//             setUploadProgress(100);
//             toast.success('Resume parsed successfully!');
//         } catch (err) {
//             console.error('Resume parsing error:', err);
//             if (recordId) {
//                 await supabase
//                     .from('resume_uploads')
//                     .update({
//                         status: 'failed',
//                         parse_status: 'failed',
//                         error_message: err.message
//                     })
//                     .eq('id', recordId);
//             }
//             toast.error('Failed to parse resume');
//         } finally {
//             setIsParsingResume(false);
//         }
//     };

//     const handleScrapeJob = async () => {
//         if (!url || !selectedSite) return;

//         setIsScraping(true);
//         try {
//             const response = await fetch(`${API_BASE_URL}/scrape-job`, {
//                 method: 'POST',
//                 headers: {'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     url,
//                     site: selectedSite.domain,
//                     record_id: selectedFile?.record_id // If we want to associate the scrape with the upload
//                 })
//             });

//             if (!response.ok) throw new Error('Failed to scrape job');

//             const data = await response.json();
//             setRawJobDescription(data.description || '');

//             // Update job data
//             setJobData(prev => ({
//                 ...prev,
//                 companyName: data.company || prev.companyName,
//                 roleTitle: data.title || prev.roleTitle,
//                 department: data.department || prev.department,
//                 location: data.location || prev.location,
//                 salaryRange: data.salary_range || prev.salaryRange,
//                 jobDescription: data.description || prev.jobDescription
//             }));

//             // If we have an active upload, update its metadata
//             if (selectedFile?.record_id) {
//                 await supabase
//                     .from('resume_uploads')
//                     .update({
//                         job_data: data,
//                         raw_job_description: data.description
//                     })
//                     .eq('id', selectedFile.record_id);
//             }

//             toast.success('Job details imported successfully!');
//         } catch (err) {
//             console.error('Scraping error:', err);
//             toast.error('Failed to import job details');
//         } finally {
//             setIsScraping(false);
//         }
//     };

//     const createInitialRecord = async (fileDetails) => {
//         const {data: {user} } = await supabase.auth.getUser();
//         const {data: membership } = await supabase
//             .from('agency_members')
//             .select('*, agency:agencies(*)')
//             .eq('user_id', user.id)
//             .single();

//         const recordId = uuidv4();
//         const initialData = {
//             id: recordId,
//             agency_id: membership.agency.id,
//             uploaded_by: user.id,
//             file_name: fileDetails.name,
//             file_type: fileDetails.type,
//             status: parseOption === 'ai' ? 'parsing' : 'uploading',
//             parse_requested: parseOption === 'ai',
//             parse_status: parseOption === 'ai' ? 'pending' : null,
//             created_at: new Date().toISOString()
//         };

//         await supabase.from('resume_uploads').insert(initialData);
//         return {recordId, membership};
//     };

//     const handleSubmit = async () => {
//         if (!selectedFile) {
//             setError('Please upload a resume file');
//             return;
//         }

//         setIsSubmitting(true);
//         setUploadProgress(25);
//         let recordId;

//         try {
//             const {recordId: newRecordId, membership } = await createInitialRecord(selectedFile);
//             recordId = newRecordId;
//             setUploadProgress(50);

//             // Upload file to storage
//             const filePath = `agencies/${membership.agency_id}/uploads/${recordId}/${selectedFile.name}`;
//             const {error: uploadError } = await supabase.storage
//                 .from('resume_uploads')
//                 .upload(filePath, selectedFile);

//             if (uploadError) throw uploadError;

//             const {data: {publicUrl} } = supabase.storage
//                 .from('resume_uploads')
//                 .getPublicUrl(filePath);

//             setUploadProgress(75);

//             // Create or update resume record
//             await supabase.from('resume_uploads').update({
//                 file_url: publicUrl,
//                 file_path: filePath,
//                 parsed_data: jobData,
//                 raw_text: rawJobDescription,
//                 status: 'completed',
//                 company: jobData.companyName,
//                 role: jobData.roleTitle,
//                 notes: jobData.notes,
//                 updated_at: new Date().toISOString()
//             }).eq('id', recordId);

//             // Handle role creation/association
//             if (selectedExistingRole) {
//                 await supabase.from('role_applications').insert({
//                     id: uuidv4(),
//                     role_id: selectedExistingRole.id,
//                     resume_id: recordId,
//                     status: 'pending',
//                     created_at: new Date().toISOString()
//                 });
//             } else if (jobData.companyName && jobData.roleTitle) {
//                 await supabase.from('open_roles').insert({
//                     id: uuidv4(),
//                     agency_id: membership.agency_id,
//                     resume_id: recordId,
//                     uploaded_by: membership.user_id,
//                     company_name: jobData.companyName,
//                     role_title: jobData.roleTitle,
//                     department: jobData.department,
//                     location: jobData.location,
//                     salary_range: jobData.salaryRange,
//                     job_description: jobData.jobDescription,
//                     skills: jobData.skills,
//                     employment_type: jobData.employmentType,
//                     remote_type: jobData.remoteType,
//                     raw_description: rawJobDescription,
//                     status: 'active',
//                     created_at: new Date().toISOString()
//                 });
//             }

//             setUploadProgress(100);
//             cleanupStorage();
//             toast.success('Resume uploaded successfully!');
//             navigate('/dashboard', {
//                 state: {
//                     activeUploadId: recordId,
//                     message: selectedExistingRole ? 'Resume uploaded and linked to existing role' : 'Resume processed and new role created'
//                 }
//             });
//         } catch (err) {
//             console.error('Upload error:', err);
//             if (recordId) {
//                 await supabase
//                     .from('resume_uploads')
//                     .update({
//                         status: 'failed',
//                         error_message: err.message
//                     })
//                     .eq('id', recordId);
//             }
//             setError(err.message || 'Failed to upload resume');
//             toast.error('Upload failed');
//         } finally {
//             setIsSubmitting(false);
//         }
//     };
//     const renderSkillBadges = () => (
//         <div className="flex flex-wrap gap-2">
//             {jobData.skills.map((skill, index) => (
//                 <span
//                     key={index}
//                     className="px-3 py-1 bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 
//                              rounded-full text-sm flex items-center gap-2"
//                 >
//                     {skill}
//                     <button
//                         onClick={() => {
//                             const newSkills = jobData.skills.filter((_, i) => i !== index);
//                             setJobData(prev => ({ ...prev, skills: newSkills }));
//                         }}
//                         className="hover:text-blue-600 dark:hover:text-blue-300"
//                     >
//                         <X className="h-4 w-4" />
//                     </button>
//                 </span>
//             ))}
//         </div>
//     );

//     return (
//         <div className="min-h-screen bg-slate-50 dark:bg-gray-900 p-8">
//             <div className="max-w-4xl mx-auto">
//                 {/* Header */}
//                 <div className="flex items-center gap-4 mb-8">
//                     <button
//                         onClick={handleClose}
//                         className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
//                     >
//                         <ArrowLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
//                     </button>
//                     <div>
//                         <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
//                             Upload Resume
//                         </h1>
//                         <p className="text-gray-600 dark:text-gray-400">
//                             Add a new resume and job details to your collection
//                         </p>
//                     </div>
//                 </div>

//                 {error && (
//                     <div className="mb-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-700 
//                                   rounded-xl p-4 text-red-800 dark:text-red-200 flex items-center gap-2">
//                         <AlertCircle className="h-5 w-5 flex-shrink-0" />
//                         <p>{error}</p>
//                     </div>
//                 )}

//                 <Card>
//                     <CardHeader>
//                         <div className="flex justify-between items-center">
//                             <CardTitle>Resume Details</CardTitle>
//                             <div className="flex gap-4">
//                                 <button
//                                     onClick={() => setParseOption('ai')}
//                                     className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
//                                             ${parseOption === 'ai'
//                                             ? 'bg-blue-600 text-white'
//                                             : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
//                                 >
//                                     <Wand2 className="w-4 h-4" />
//                                     AI Parse
//                                 </button>
//                                 <button
//                                     onClick={() => setParseOption('manual')}
//                                     className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
//                                             ${parseOption === 'manual'
//                                             ? 'bg-blue-600 text-white'
//                                             : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
//                                 >
//                                     <FileEdit className="w-4 h-4" />
//                                     Manual Entry
//                                 </button>
//                             </div>
//                         </div>
//                     </CardHeader>

//                     <CardContent>
//                         {step === 'upload' ? (
//                             <div
//                                 className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl p-12 
//                                          bg-gray-50 dark:bg-gray-800/50 transition-all duration-300
//                                          hover:border-blue-500 dark:hover:border-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20"
//                                 onDrop={(e) => {
//                                     e.preventDefault();
//                                     handleFileSelect(e.dataTransfer.files[0]);
//                                 }}
//                                 onDragOver={(e) => e.preventDefault()}
//                             >
//                                 <input
//                                     type="file"
//                                     id="file-upload"
//                                     className="hidden"
//                                     accept=".pdf,.doc,.docx"
//                                     onChange={(e) => handleFileSelect(e.target.files[0])}
//                                 />
//                                 {selectedFile ? (
//                                     <div className="flex items-center justify-center gap-4">
//                                         <FileText className="h-16 w-16 text-blue-600 dark:text-blue-400" />
//                                         <div>
//                                             <p className="font-medium text-gray-900 dark:text-white">
//                                                 {selectedFile.name}
//                                             </p>
//                                             <p className="text-sm text-gray-500 dark:text-gray-400">
//                                                 {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
//                                             </p>
//                                         </div>
//                                         <button
//                                             onClick={() => setSelectedFile(null)}
//                                             className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
//                                         >
//                                             <X className="h-5 w-5 text-gray-500 dark:text-gray-400" />
//                                         </button>
//                                     </div>
//                                 ) : (
//                                     <div className="text-center">
//                                         <Upload className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
//                                         <label
//                                             htmlFor="file-upload"
//                                             className="cursor-pointer text-blue-600 dark:text-blue-400 
//                                                      hover:text-blue-700 dark:hover:text-blue-300"
//                                         >
//                                             Click to upload or drag and drop
//                                         </label>
//                                         <p className="text-gray-500 dark:text-gray-400 text-sm mt-1">
//                                             PDF, DOC, DOCX up to 10MB
//                                         </p>
//                                     </div>
//                                 )}
//                             </div>
//                         ) : (
//                             <div className="space-y-6">
//                                 {/* Existing Role Selection */}
//                                 <div>
//                                     <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
//                                         Select Existing Role (Optional)
//                                     </h3>
//                                     <select
//                                         value={selectedExistingRole?.id || ''}
//                                         onChange={(e) => {
//                                             const role = existingRoles.find(r => r.id === e.target.value);
//                                             if (role) {
//                                                 setSelectedExistingRole(role);
//                                                 setJobData(prev => ({
//                                                     ...prev,
//                                                     companyName: role.company_name,
//                                                     roleTitle: role.role_title,
//                                                     department: role.department || '',
//                                                     location: role.location || '',
//                                                     salaryRange: role.salary_range || '',
//                                                     jobDescription: role.job_description || '',
//                                                     skills: role.skills || [],
//                                                     employmentType: role.employment_type || 'full-time',
//                                                     remoteType: role.remote_type || 'on-site'
//                                                 }));
//                                             }
//                                         }}
//                                         className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                  bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                     >
//                                         <option value="">Create New Role</option>
//                                         {existingRoles.map(role => (
//                                             <option key={role.id} value={role.id}>
//                                                 {role.company_name} - {role.role_title}
//                                                 {role.location ? ` (${role.location})` : ''}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>

//                                 {/* Import from Job Site */}
//                                 <div>
//                                     <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
//                                         Import from Job Site (Optional)
//                                     </h3>
//                                     <div className="grid grid-cols-3 gap-4 mb-4">
//                                         {JOB_SITES.map(site => (
//                                             <button
//                                                 key={site.name}
//                                                 onClick={() => setSelectedSite(site)}
//                                                 className={`p-4 rounded-lg border ${selectedSite?.name === site.name
//                                                     ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
//                                                     : 'border-gray-200 dark:border-gray-700'
//                                                     } flex items-center gap-3`}
//                                             >
//                                                 <JobSiteIcon site={site.name} />
//                                                 <span className="text-gray-900 dark:text-white">
//                                                     {site.name}
//                                                 </span>
//                                             </button>
//                                         ))}
//                                     </div>
//                                     {selectedSite && (
//                                         <div className="flex gap-2">
//                                             <input
//                                                 type="url"
//                                                 value={url}
//                                                 onChange={(e) => setUrl(e.target.value)}
//                                                 placeholder={`Paste ${selectedSite.name} job URL`}
//                                                 className="flex-1 px-4 py-2 border dark:border-gray-700 rounded-lg 
//                                                          bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
//                                             />
//                                             <button
//                                                 onClick={handleScrapeJob}
//                                                 disabled={!url || isScraping}
//                                                 className="px-4 py-2 bg-blue-600 text-white rounded-lg 
//                                                          hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                                             >
//                                                 {isScraping ? (
//                                                     <>
//                                                         <Loader2 className="w-5 h-5 animate-spin" />
//                                                         <span>Importing...</span>
//                                                     </>
//                                                 ) : (
//                                                     <span>Import Details</span>
//                                                 )}
//                                             </button>
//                                         </div>
//                                     )}
//                                 </div>

//                                 {/* Job Details Form */}
//                                 <div className="space-y-6">
//                                     {/* Company and Role */}
//                                     <div className="grid grid-cols-2 gap-6">
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Company Name
//                                             </label>
//                                             <div className="relative">
//                                                 <Building className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                                 <input
//                                                     type="text"
//                                                     className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
//                                                     value={jobData.companyName}
//                                                     onChange={(e) => setJobData(prev => ({
//                                                         ...prev,
//                                                         companyName: e.target.value
//                                                     }))}
//                                                     placeholder="Enter company name"
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Role Title
//                                             </label>
//                                             <div className="relative">
//                                                 <BriefcaseIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                                 <input
//                                                     type="text"
//                                                     className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
//                                                     value={jobData.roleTitle}
//                                                     onChange={(e) => setJobData(prev => ({
//                                                         ...prev,
//                                                         roleTitle: e.target.value
//                                                     }))}
//                                                     placeholder="Enter role title"
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>

//                                     {/* Location and Department */}
//                                     <div className="grid grid-cols-2 gap-6">
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Location
//                                             </label>
//                                             <div className="relative">
//                                                 <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                                 <input
//                                                     type="text"
//                                                     className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
//                                                     value={jobData.location}
//                                                     onChange={(e) => setJobData(prev => ({
//                                                         ...prev,
//                                                         location: e.target.value
//                                                     }))}
//                                                     placeholder="Enter location"
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Department
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
//                                                 value={jobData.department}
//                                                 onChange={(e) => setJobData(prev => ({
//                                                     ...prev,
//                                                     department: e.target.value
//                                                 }))}
//                                                 placeholder="Enter department"
//                                             />
//                                         </div>
//                                     </div>

//                                     {/* Employment Type and Remote Type */}
//                                     <div className="grid grid-cols-2 gap-6">
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Employment Type
//                                             </label>
//                                             <select
//                                                 value={jobData.employmentType}
//                                                 onChange={(e) => setJobData(prev => ({
//                                                     ...prev,
//                                                     employmentType: e.target.value
//                                                 }))}
//                                                 className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
//                                             >
//                                                 {EMPLOYMENT_TYPES.map(type => (
//                                                     <option key={type.value} value={type.value}>
//                                                         {type.label}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                         <div>
//                                             <label className="block text-sm font-medium mb-1">
//                                                 Remote Type
//                                             </label>
//                                             <select
//                                                 value={jobData.remoteType}
//                                                 onChange={(e) => setJobData(prev => ({
//                                                     ...prev,
//                                                     remoteType: e.target.value
//                                                 }))}
//                                                 className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg"
//                                             >
//                                                 {REMOTE_TYPES.map(type => (
//                                                     <option key={type.value} value={type.value}>
//                                                         {type.label}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                     </div>

//                                     {/* Salary Range */}
//                                     <div>
//                                         <label className="block text-sm font-medium mb-1">
//                                             Salary Range
//                                         </label>
//                                         <div className="relative">
//                                             <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
//                                             <input
//                                                 type="text"
//                                                 className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg"
//                                                 value={jobData.salaryRange}
//                                                 onChange={(e) => setJobData(prev => ({
//                                                     ...prev,
//                                                     salaryRange: e.target.value
//                                                 }))}
//                                                 placeholder="e.g., $80,000 - $120,000"
//                                             />
//                                         </div>
//                                     </div>

//                                     {/* Skills */}
//                                     <div>
//                                         <label className="block text-sm font-medium mb-1">
//                                             Skills
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg mb-2"
//                                             placeholder="Type a skill and press Enter"
//                                             onKeyDown={(e) => {
//                                                 if (e.key === 'Enter' && e.target.value.trim()) {
//                                                     e.preventDefault();
//                                                     setJobData(prev => ({
//                                                         ...prev,
//                                                         skills: [...new Set([...prev.skills, e.target.value.trim()])]
//                                                     }));
//                                                     e.target.value = '';
//                                                 }
//                                             }}
//                                         />
//                                         {renderSkillBadges()}
//                                     </div>

//                                     {/* Job Description */}
//                                     <div>
//                                         <label className="block text-sm font-medium mb-1">
//                                             Job Description
//                                         </label>
//                                         <textarea
//                                             className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-48 resize-none"
//                                             value={jobData.jobDescription}
//                                             onChange={(e) => setJobData(prev => ({
//                                                 ...prev,
//                                                 jobDescription: e.target.value
//                                             }))}
//                                             placeholder="Enter job description..."
//                                         />
//                                     </div>

//                                     {/* Notes */}
//                                     <div>
//                                         <label className="block text-sm font-medium mb-1">
//                                             Additional Notes
//                                         </label>
//                                         <textarea
//                                             className="w-full px-4 py-2 border dark:border-gray-700 rounded-lg h-32 resize-none"
//                                             value={jobData.notes}
//                                             onChange={(e) => setJobData(prev => ({
//                                                 ...prev,
//                                                 notes: e.target.value
//                                             }))}
//                                             placeholder="Add any additional notes..."
//                                         />
//                                     </div>

//                                     {/* Similar Roles Warning */}
//                                     {matchingRoles.length > 0 && (
//                                         <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 
//                                                       dark:border-yellow-700 rounded-lg p-4">
//                                             <div className="flex items-start gap-3">
//                                                 <Users className="w-5 h-5 text-yellow-600 dark:text-yellow-400 mt-0.5" />
//                                                 <div>
//                                                     <h4 className="font-medium text-yellow-800 dark:text-yellow-200">
//                                                         Similar Roles Found
//                                                     </h4>
//                                                     <p className="text-sm text-yellow-700 dark:text-yellow-300 mt-1">
//                                                         There are {matchingRoles.length} similar roles in your system:
//                                                     </p>
//                                                     <ul className="mt-2 space-y-1">
//                                                         {matchingRoles.map((role, index) => (
//                                                             <li key={index} className="text-sm text-yellow-700 dark:text-yellow-300">
//                                                                 {role.company_name} - {role.role_title}
//                                                             </li>
//                                                         ))}
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>

//                                 {/* Form Actions */}
//                                 <div className="flex justify-between pt-6">
//                                     <button
//                                         onClick={() => setStep('upload')}
//                                         className="text-blue-600 dark:text-blue-400 hover:text-blue-700 
//                                                  dark:hover:text-blue-300"
//                                     >
//                                         Back to Upload
//                                     </button>
//                                     <button
//                                         onClick={handleSubmit}
//                                         disabled={!selectedFile || isSubmitting}
//                                         className="px-6 py-2 bg-blue-600 text-white rounded-lg 
//                                                  hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                                     >
//                                         {isSubmitting ? (
//                                             <>
//                                                 <Loader2 className="w-5 w-5 animate-spin" />
//                                                 <span>Processing...</span>
//                                             </>
//                                         ) : (
//                                             <span>Upload Resume & Save Details</span>
//                                         )}
//                                     </button>
//                                 </div>
//                             </div>
//                         )}
//                     </CardContent>
//                 </Card>

//                 {/* Loading States */}
//                 {(isParsingResume || (uploadProgress > 0 && uploadProgress < 100)) && (
//                     <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
//                         <div className="bg-white dark:bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4">
//                             <div className="space-y-4">
//                                 <div className="flex items-center gap-3">
//                                     <Loader2 className="h-6 w-6 animate-spin text-blue-600" />
//                                     <h3 className="text-lg font-medium">
//                                         {isParsingResume ? 'Parsing Resume...' : 'Uploading...'}
//                                     </h3>
//                                 </div>
//                                 <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
//                                     <div
//                                         className="bg-blue-600 h-2 rounded-full transition-all duration-300"
//                                         style={{ width: `${uploadProgress}%` }}
//                                     />
//                                 </div>
//                                 <p className="text-sm text-gray-600 dark:text-gray-400">
//                                     {isParsingResume
//                                         ? 'Analyzing resume content...'
//                                         : 'Uploading your resume...'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ResumeUpload;

