import React, { useState } from 'react';
import {
    Search,
    Calendar,
    ChevronDown,
    ChevronUp,
    CheckCircle,
    XCircle,
    Briefcase
} from 'lucide-react';


import ConnectCalendarButton from './ConnectCalendarButton';
// No need to import pngs directly since we're using the public folder
// Ensure the logos are placed in the public/logos directory

const CallDashboard = () => {
    const [selectedCall, setSelectedCall] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

    // Mapping of call services to their logo paths
    const serviceLogos = {
        'Zoom': '/logos/zoom.png',
        'Google Meet': '/logos/google-meet.png',
        'Microsoft Teams': '/logos/microsoft-teams.png'
        // Add more mappings if needed
    };

    // Expanded mock data with additional fields
    const mockCalls = [
        {
            id: '1',
            candidateName: 'John Doe',
            email: 'john.doe@example.com',
            currentRole: 'Senior Developer',
            currentCompany: 'Tech Solutions Inc.',
            skills: ['React', 'Node.js', 'TypeScript'],
            careerGoals: 'Senior Full Stack Developer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$120,000 - $150,000',
            timeNeeded: '2 weeks',
            location: 'New York, NY',
            yearsOfExperience: 5,
            date: '2023-06-15T14:30:00Z',
            duration: 45,
            callSummary: 'John showed strong technical skills and enthusiasm for the role. Follow up needed on project experience.',
            callService: 'Zoom',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '2',
            candidateName: 'Jane Smith',
            email: 'jane.smith@example.com',
            currentRole: 'Data Scientist',
            currentCompany: 'Data Analytics LLC',
            skills: ['Python', 'Machine Learning', 'SQL'],
            careerGoals: 'Lead Data Scientist',
            citizenStatus: 'Permanent Resident',
            salaryRequirements: '$110,000 - $140,000',
            timeNeeded: '1 week',
            location: 'San Francisco, CA',
            yearsOfExperience: 4,
            date: '2023-07-20T10:15:00Z',
            duration: 30,
            callSummary: 'Jane has excellent analytical skills and a solid understanding of machine learning algorithms. Awaiting reference checks.',
            callService: 'Google Meet',
            currentlyInterviewing: false,
            hasExistingOffers: true
        },
        {
            id: '3',
            candidateName: 'Michael Brown',
            email: 'michael.brown@example.com',
            currentRole: 'Frontend Engineer',
            currentCompany: 'Creative Minds',
            skills: ['JavaScript', 'React', 'CSS'],
            careerGoals: 'UI/UX Lead',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$100,000 - $130,000',
            timeNeeded: '3 weeks',
            location: 'Austin, TX',
            yearsOfExperience: 3,
            date: '2023-08-05T09:00:00Z',
            duration: 40,
            callSummary: 'Michael demonstrated a strong portfolio and creative approach to frontend development. Interested in leadership roles.',
            callService: 'Skype',
            currentlyInterviewing: true,
            hasExistingOffers: true
        },
        {
            id: '4',
            candidateName: 'Emily Davis',
            email: 'emily.davis@example.com',
            currentRole: 'Backend Developer',
            currentCompany: 'Innovatech',
            skills: ['Node.js', 'Express', 'MongoDB'],
            careerGoals: 'Full Stack Developer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$115,000 - $145,000',
            timeNeeded: '2 weeks',
            location: 'Seattle, WA',
            yearsOfExperience: 6,
            date: '2023-09-10T11:45:00Z',
            duration: 50,
            callSummary: 'Emily has extensive experience in backend development and database management. Looking for opportunities to work on scalable systems.',
            callService: 'Zoom',
            currentlyInterviewing: false,
            hasExistingOffers: false
        },
        {
            id: '5',
            candidateName: 'David Wilson',
            email: 'david.wilson@example.com',
            currentRole: 'DevOps Engineer',
            currentCompany: 'CloudNet',
            skills: ['AWS', 'Docker', 'Kubernetes'],
            careerGoals: 'Senior DevOps Engineer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$130,000 - $160,000',
            timeNeeded: '1 week',
            location: 'Denver, CO',
            yearsOfExperience: 7,
            date: '2023-10-01T13:30:00Z',
            duration: 55,
            callSummary: 'David has robust experience with cloud infrastructures and automation tools. Highly recommended for the senior role.',
            callService: 'Microsoft Teams',
            currentlyInterviewing: true,
            hasExistingOffers: true
        },
        // Additional 15 mock calls
        {
            id: '6',
            candidateName: 'Sarah Johnson',
            email: 'sarah.johnson@example.com',
            currentRole: 'Product Manager',
            currentCompany: 'NextGen Products',
            skills: ['Product Strategy', 'Agile', 'Roadmapping'],
            careerGoals: 'Director of Product',
            citizenStatus: 'Permanent Resident',
            salaryRequirements: '$140,000 - $170,000',
            timeNeeded: '2 weeks',
            location: 'Boston, MA',
            yearsOfExperience: 8,
            date: '2023-10-15T16:00:00Z',
            duration: 60,
            callSummary: 'Sarah has a proven track record in product management and leadership. Seeking roles with strategic impact.',
            callService: 'Zoom',
            currentlyInterviewing: false,
            hasExistingOffers: false
        },
        {
            id: '7',
            candidateName: 'Chris Lee',
            email: 'chris.lee@example.com',
            currentRole: 'UI/UX Designer',
            currentCompany: 'DesignHub',
            skills: ['Figma', 'Adobe XD', 'User Research'],
            careerGoals: 'Lead UX Designer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$90,000 - $120,000',
            timeNeeded: '1 week',
            location: 'Los Angeles, CA',
            yearsOfExperience: 4,
            date: '2023-11-05T10:30:00Z',
            duration: 35,
            callSummary: 'Chris has a strong design portfolio and excellent user research skills. Great fit for the lead position.',
            callService: 'Google Meet',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '8',
            candidateName: 'Amanda Martinez',
            email: 'amanda.martinez@example.com',
            currentRole: 'QA Engineer',
            currentCompany: 'QualityFirst',
            skills: ['Selenium', 'Test Automation', 'JIRA'],
            careerGoals: 'Senior QA Engineer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$85,000 - $110,000',
            timeNeeded: '3 weeks',
            location: 'Chicago, IL',
            yearsOfExperience: 5,
            date: '2023-12-01T09:15:00Z',
            duration: 40,
            callSummary: 'Amanda has extensive experience in test automation and quality assurance. Looking to advance to a senior role.',
            callService: 'Skype',
            currentlyInterviewing: false,
            hasExistingOffers: true
        },
        {
            id: '9',
            candidateName: 'Robert King',
            email: 'robert.king@example.com',
            currentRole: 'Machine Learning Engineer',
            currentCompany: 'AI Innovators',
            skills: ['TensorFlow', 'Python', 'Data Modeling'],
            careerGoals: 'Lead ML Engineer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$125,000 - $155,000',
            timeNeeded: '2 weeks',
            location: 'San Diego, CA',
            yearsOfExperience: 6,
            date: '2023-12-10T14:45:00Z',
            duration: 50,
            callSummary: 'Robert has strong expertise in machine learning and data modeling. Highly recommended for leadership roles.',
            callService: 'Microsoft Teams',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '10',
            candidateName: 'Laura Green',
            email: 'laura.green@example.com',
            currentRole: 'Marketing Specialist',
            currentCompany: 'MarketPros',
            skills: ['SEO', 'Content Marketing', 'Google Analytics'],
            careerGoals: 'Marketing Manager',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$80,000 - $110,000',
            timeNeeded: '1 week',
            location: 'Miami, FL',
            yearsOfExperience: 3,
            date: '2023-12-20T11:00:00Z',
            duration: 30,
            callSummary: 'Laura has a solid background in SEO and content marketing. Interested in managerial positions.',
            callService: 'Zoom',
            currentlyInterviewing: false,
            hasExistingOffers: false
        },
        {
            id: '11',
            candidateName: 'Daniel Walker',
            email: 'daniel.walker@example.com',
            currentRole: 'Sales Executive',
            currentCompany: 'SalesForce Inc.',
            skills: ['CRM', 'Lead Generation', 'Negotiation'],
            careerGoals: 'Sales Manager',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$100,000 - $130,000',
            timeNeeded: '2 weeks',
            location: 'Houston, TX',
            yearsOfExperience: 7,
            date: '2024-01-05T13:00:00Z',
            duration: 45,
            callSummary: 'Daniel has exceptional sales skills and experience in CRM systems. Excellent candidate for the sales manager role.',
            callService: 'Google Meet',
            currentlyInterviewing: true,
            hasExistingOffers: true
        },
        {
            id: '12',
            candidateName: 'Olivia Harris',
            email: 'olivia.harris@example.com',
            currentRole: 'Content Writer',
            currentCompany: 'WriteRight',
            skills: ['Copywriting', 'SEO Writing', 'Editing'],
            careerGoals: 'Senior Content Strategist',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$70,000 - $100,000',
            timeNeeded: '1 week',
            location: 'Portland, OR',
            yearsOfExperience: 4,
            date: '2024-01-15T10:00:00Z',
            duration: 35,
            callSummary: 'Olivia has a strong portfolio in copywriting and SEO. Looking to move into content strategy.',
            callService: 'Skype',
            currentlyInterviewing: false,
            hasExistingOffers: false
        },
        {
            id: '13',
            candidateName: 'Kevin Turner',
            email: 'kevin.turner@example.com',
            currentRole: 'Mobile Developer',
            currentCompany: 'AppWorks',
            skills: ['React Native', 'Swift', 'Kotlin'],
            careerGoals: 'Lead Mobile Developer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$115,000 - $145,000',
            timeNeeded: '3 weeks',
            location: 'Dallas, TX',
            yearsOfExperience: 6,
            date: '2024-01-25T15:30:00Z',
            duration: 50,
            callSummary: 'Kevin has extensive experience in mobile app development and leadership. Suitable for lead roles.',
            callService: 'Microsoft Teams',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '14',
            candidateName: 'Sophia Martinez',
            email: 'sophia.martinez@example.com',
            currentRole: 'Graphic Designer',
            currentCompany: 'Creative Studio',
            skills: ['Adobe Photoshop', 'Illustrator', 'InDesign'],
            careerGoals: 'Senior Graphic Designer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$75,000 - $105,000',
            timeNeeded: '2 weeks',
            location: 'Atlanta, GA',
            yearsOfExperience: 5,
            date: '2024-02-01T09:30:00Z',
            duration: 40,
            callSummary: 'Sophia has a keen eye for design and extensive experience with Adobe Suite. Ready for senior design positions.',
            callService: 'Zoom',
            currentlyInterviewing: false,
            hasExistingOffers: true
        },
        {
            id: '15',
            candidateName: 'James Anderson',
            email: 'james.anderson@example.com',
            currentRole: 'Systems Analyst',
            currentCompany: 'InfoTech',
            skills: ['System Analysis', 'SQL', 'Python'],
            careerGoals: 'Senior Systems Analyst',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$95,000 - $125,000',
            timeNeeded: '1 week',
            location: 'Phoenix, AZ',
            yearsOfExperience: 5,
            date: '2024-02-10T14:00:00Z',
            duration: 38,
            callSummary: 'James has strong analytical skills and experience in system optimization. Great candidate for senior roles.',
            callService: 'Skype',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '16',
            candidateName: 'Linda White',
            email: 'linda.white@example.com',
            currentRole: 'HR Manager',
            currentCompany: 'PeopleFirst',
            skills: ['Recruitment', 'Employee Relations', 'HRIS'],
            careerGoals: 'Director of HR',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$110,000 - $140,000',
            timeNeeded: '2 weeks',
            location: 'Philadelphia, PA',
            yearsOfExperience: 8,
            date: '2024-02-20T11:15:00Z',
            duration: 45,
            callSummary: 'Linda has extensive experience in HR management and employee relations. Excellent fit for director-level positions.',
            callService: 'Google Meet',
            currentlyInterviewing: false,
            hasExistingOffers: true
        },
        {
            id: '17',
            candidateName: 'Brian Harris',
            email: 'brian.harris@example.com',
            currentRole: 'Business Analyst',
            currentCompany: 'BizAnalytics',
            skills: ['Data Analysis', 'Process Improvement', 'SQL'],
            careerGoals: 'Senior Business Analyst',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$90,000 - $120,000',
            timeNeeded: '1 week',
            location: 'San Jose, CA',
            yearsOfExperience: 4,
            date: '2024-03-01T10:45:00Z',
            duration: 40,
            callSummary: 'Brian has strong skills in data analysis and process improvement. Looking to advance to senior analyst roles.',
            callService: 'Zoom',
            currentlyInterviewing: true,
            hasExistingOffers: false
        },
        {
            id: '18',
            candidateName: 'Karen Lopez',
            email: 'karen.lopez@example.com',
            currentRole: 'Full Stack Developer',
            currentCompany: 'WebWorks',
            skills: ['JavaScript', 'Node.js', 'React'],
            careerGoals: 'Lead Full Stack Developer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$115,000 - $145,000',
            timeNeeded: '3 weeks',
            location: 'Orlando, FL',
            yearsOfExperience: 7,
            date: '2024-03-10T13:20:00Z',
            duration: 50,
            callSummary: 'Karen has comprehensive experience in full stack development and team leadership. Ideal for lead developer positions.',
            callService: 'Microsoft Teams',
            currentlyInterviewing: false,
            hasExistingOffers: false
        },
        {
            id: '19',
            candidateName: 'Steven Clark',
            email: 'steven.clark@example.com',
            currentRole: 'Network Engineer',
            currentCompany: 'NetSecure',
            skills: ['Cisco', 'Firewall Management', 'Network Design'],
            careerGoals: 'Senior Network Engineer',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$100,000 - $130,000',
            timeNeeded: '2 weeks',
            location: 'Las Vegas, NV',
            yearsOfExperience: 6,
            date: '2024-03-20T16:10:00Z',
            duration: 45,
            callSummary: 'Steven has strong expertise in network security and infrastructure design. Recommended for senior network roles.',
            callService: 'Skype',
            currentlyInterviewing: true,
            hasExistingOffers: true
        },
        {
            id: '20',
            candidateName: 'Barbara Lewis',
            email: 'barbara.lewis@example.com',
            currentRole: 'Digital Marketing Manager',
            currentCompany: 'MarketGurus',
            skills: ['SEO', 'PPC', 'Content Strategy'],
            careerGoals: 'Director of Digital Marketing',
            citizenStatus: 'US Citizen',
            salaryRequirements: '$120,000 - $150,000',
            timeNeeded: '1 week',
            location: 'Charlotte, NC',
            yearsOfExperience: 7,
            date: '2024-04-01T09:30:00Z',
            duration: 50,
            callSummary: 'Barbara has extensive experience in digital marketing strategies and team management. Perfect for director roles.',
            callService: 'Zoom',
            currentlyInterviewing: false,
            hasExistingOffers: false
        }
    ];

    // Filtered and sorted calls based on search term and sort configuration
    const filteredCalls = mockCalls
        .filter(call =>
            call.candidateName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            call.currentRole.toLowerCase().includes(searchTerm.toLowerCase()) ||
            call.currentCompany.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

    // Function to handle sorting
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="min-h-screen bg-slate-50 dark:bg-gray-900">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <header className="p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                    <div className="flex items-center justify-between">
                        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Call Dashboard</h1>
                        <ConnectCalendarButton />
                    </div>
                </header>

                {/* Main Content */}
                <div className="flex h-[calc(100vh-80px)]">
                    {/* Left Column - Call List */}
                    <div className="w-2/5 border-r border-gray-200 dark:border-gray-700 overflow-y-auto">
                        {/* Search */}
                        <div className="p-4 sticky top-0 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                <input
                                    type="text"
                                    placeholder="Search calls..."
                                    className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-full bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Sort Controls */}
                        <div className="p-4 sticky top-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => handleSort('date')}
                                    className="flex items-center gap-1 px-3 py-1 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
                                >
                                    Date
                                    {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />)}
                                </button>
                                <button
                                    onClick={() => handleSort('candidateName')}
                                    className="flex items-center gap-1 px-3 py-1 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
                                >
                                    Name
                                    {sortConfig.key === 'candidateName' && (sortConfig.direction === 'asc' ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />)}
                                </button>
                                <button
                                    onClick={() => handleSort('location')}
                                    className="flex items-center gap-1 px-3 py-1 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
                                >
                                    Location
                                    {sortConfig.key === 'location' && (sortConfig.direction === 'asc' ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />)}
                                </button>
                            </div>
                        </div>

                        {/* Call List */}
                        <div className="divide-y divide-gray-200 dark:divide-gray-700">
                            {filteredCalls.map((call) => (
                                <div
                                    key={call.id}
                                    onClick={() => setSelectedCall(call)}
                                    className={`p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 cursor-pointer ${selectedCall?.id === call.id ? 'bg-blue-50 dark:bg-blue-900/20' : ''
                                        }`}
                                >
                                    <div className="flex items-start gap-4">
                                        <div className="w-12 h-12 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center">
                                            <span className="text-blue-600 dark:text-blue-400 font-semibold">
                                                {call.candidateName.split(' ').map(n => n[0]).join('')}
                                            </span>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-semibold text-gray-900 dark:text-gray-100">{call.candidateName}</h3>
                                            <p className="text-sm text-gray-600 dark:text-gray-400">{call.currentRole} at {call.currentCompany}</p>
                                            <div className="mt-2 flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                                                <Calendar className="h-4 w-4" />
                                                <span>{new Date(call.date).toLocaleDateString()}</span>
                                            </div>
                                            {/* New Fields: Currently Interviewing & Existing Offers */}
                                            <div className="mt-2 flex items-center gap-2 text-sm">
                                                {call.currentlyInterviewing ? (
                                                    <div className="flex items-center gap-1 text-green-500">
                                                        <CheckCircle className="h-4 w-4" />
                                                        <span>Interviewing</span>
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center gap-1 text-gray-500">
                                                        <XCircle className="h-4 w-4" />
                                                        <span>Not Interviewing</span>
                                                    </div>
                                                )}
                                                {call.hasExistingOffers ? (
                                                    <div className="flex items-center gap-1 text-yellow-500">
                                                        <Briefcase className="h-4 w-4" />
                                                        <span>Has Offers</span>
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center gap-1 text-gray-500">
                                                        <Briefcase className="h-4 w-4" />
                                                        <span>No Offers</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {filteredCalls.length === 0 && (
                                <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                                    No calls found matching your search.
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Right Column - Call Details */}
                    <div className="w-3/5 overflow-y-auto">
                        {selectedCall ? (
                            <div className="p-6">
                                <div className="mb-6">
                                    <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
                                        {selectedCall.candidateName}
                                    </h2>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        {selectedCall.currentRole} at {selectedCall.currentCompany}
                                    </p>
                                </div>

                                {/* Call Details */}
                                <div className="space-y-6">
                                    <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
                                        <h3 className="font-semibold text-gray-900 dark:text-gray-100 mb-3">Call Details</h3>
                                        <div className="grid grid-cols-2 gap-4 text-sm">
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Date</p>
                                                <p className="text-gray-900 dark:text-gray-100">
                                                    {new Date(selectedCall.date).toLocaleString()}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Duration</p>
                                                <p className="text-gray-900 dark:text-gray-100">{selectedCall.duration} minutes</p>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Service</p>
                                                {/* Display the service logo if available, else show the service name */}
                                                <div className="flex items-center gap-2">
                                                    {serviceLogos[selectedCall.callService] ? (
                                                        <img
                                                            src={serviceLogos[selectedCall.callService]}
                                                            alt={selectedCall.callService}
                                                            className="h-6 w-6 object-contain"
                                                        />
                                                    ) : (
                                                        <span className="text-gray-900 dark:text-gray-100">{selectedCall.callService}</span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* New Fields: Currently Interviewing & Existing Offers */}
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Currently Interviewing</p>
                                                <p className={`text-gray-900 dark:text-gray-100 flex items-center gap-1`}>
                                                    {selectedCall.currentlyInterviewing ? (
                                                        <>
                                                            <CheckCircle className="h-5 w-5 text-green-500" />
                                                            <span>Yes</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <XCircle className="h-5 w-5 text-red-500" />
                                                            <span>No</span>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Existing Offers</p>
                                                <p className={`text-gray-900 dark:text-gray-100 flex items-center gap-1`}>
                                                    {selectedCall.hasExistingOffers ? (
                                                        <>
                                                            <Briefcase className="h-5 w-5 text-yellow-500" />
                                                            <span>Yes</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Briefcase className="h-5 w-5 text-gray-500" />
                                                            <span>No</span>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Candidate Information */}
                                    <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
                                        <h3 className="font-semibold text-gray-900 dark:text-gray-100 mb-3">Candidate Information</h3>
                                        <div className="space-y-4">
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Career Goals</p>
                                                <p className="text-gray-900 dark:text-gray-100">{selectedCall.careerGoals}</p>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Skills</p>
                                                <div className="flex flex-wrap gap-2 mt-1">
                                                    {selectedCall.skills.map((skill) => (
                                                        <span key={skill} className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400 rounded text-sm">
                                                            {skill}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">Call Summary</p>
                                                <p className="text-gray-900 dark:text-gray-100">{selectedCall.callSummary}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="h-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                                Select a call to view details
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallDashboard;


// {
//     id: '1',
//     candidateName: 'John Doe',
//     email: 'john.doe@example.com',
//     currentRole: 'Senior Developer',
//     currentCompany: 'Tech Solutions Inc.',
//     skills: ['React', 'Node.js', 'TypeScript'],
//     careerGoals: 'Senior Full Stack Developer',
//     citizenStatus: 'US Citizen',
//     salaryRequirements: '$120,000 - $150,000',
//     timeNeeded: '2 weeks',
//     location: 'New York, NY',
//     yearsOfExperience: 5,
//     date: '2023-06-15T14:30:00Z',
//     duration: 45,
//     callSummary: 'John showed strong technical skills and enthusiasm for the role. Follow up needed on project experience.',
//     callService: 'Zoom',
//     currentlyInterviewing: true,
//     hasExistingOffers: false,
//     governmentClearance: true,
//     candidateIntention: 'actively looking',
//     willingToReferFriend: false,
//     interviewStage: 'initial',
//     strengths: {
//         technical: ['React Expertise', 'Node.js Proficiency'],
//         soft: ['Team Player', 'Quick Learner']
//     },
//     weaknesses: {
//         technical: ['Limited UI Design Experience'],
//         soft: ['Public Speaking']
//     },
//     technicalProjectsMentioned: [
//         'E-commerce Platform',
//         'Real-time Chat Application'
//     ],
//     preferredContactMethod: 'Phone',
//     timeZone: 'EST',
//     assignedRole: null
// },