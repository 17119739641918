import React, { useEffect, useState } from 'react';
import {
    PdfLoader,
    PdfHighlighter,
} from 'react-pdf-highlighter';
import { Loader2, ZoomIn, ZoomOut, FileDown } from 'lucide-react';
import supabase from '../../supabaseClient';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const NGROK_API_URL = "https://e0a0-74-88-7-3.ngrok-free.app/highlight-document/";

const ResumePreviewPanel = ({ fileUrl, fileType, conversionId, sharedDataUrl, missingDataUrl }) => {
    const [resumeText, setResumeText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState('');
    const [processedPdfUrl, setProcessedPdfUrl] = useState(null); // For analyzed PDF
    const [scale, setScale] = useState(1);
    const [error, setError] = useState(null);
    const [isMissingDataView, setIsMissingDataView] = useState(false); // Toggle View

    // Fetch resume text from Supabase
    useEffect(() => {
        const fetchResumeText = async () => {
            if (!conversionId) return;

            try {
                const { data, error } = await supabase
                    .from('resume_conversions')
                    .select('resume_json')
                    .eq('id', conversionId)
                    .eq('is_latest', true)
                    .single();

                if (error) throw error;

                if (data?.resume_json) {
                    // Clean JSON data: Extract meaningful text
                    const cleanedText = extractTextFromJson(data.resume_json);
                    setResumeText(cleanedText);
                } else {
                    setError('No resume JSON data found.');
                }
            } catch (err) {
                console.error('Error fetching resume text:', err);
                setError('Failed to load resume text.');
            }
        };

        fetchResumeText();
    }, [conversionId]);

    // Utility function to clean JSONB and extract text
    const extractTextFromJson = (jsonData) => {
        const textSegments = [];

        const traverse = (obj) => {
            for (const key in obj) {
                if (typeof obj[key] === 'string') {
                    textSegments.push(obj[key]); // Collect strings
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    traverse(obj[key]); // Recursively traverse nested objects
                }
            }
        };

        traverse(jsonData);
        return textSegments.join(' ').replace(/\s+/g, ' ').trim(); // Join and clean whitespace
    };


    // Set initial PDF URL
    useEffect(() => {
        if (fileUrl) {
            try {
                const url = new URL(fileUrl);
                setPdfUrl(url.toString());
                setIsLoading(false);
            } catch (err) {
                console.error('Invalid file URL:', err);
                setError('Invalid PDF URL.');
            }
        }
    }, [fileUrl]);

    // Perform Extraction Analysis
    const handleExtractionAnalysis = async () => {
        if (!fileUrl || !resumeText) {
            setError('Resume text and file are required for analysis.');
            return;
        }

        setIsLoading(true);
        setProcessedPdfUrl(null);
        const formData = new FormData();

        // Fetch the file content (convert fileUrl to a Blob)
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        formData.append('file', blob, 'resume.pdf'); // Name the file as "resume.pdf"
        formData.append('resume_text', resumeText);
        formData.append('match', 'false');
        formData.append('output_pdf', 'false');

        try {
            const apiResponse = await fetch(NGROK_API_URL, {
                method: 'POST',
                body: formData, // Content-Type auto-handled
            });

            if (!apiResponse.ok) {
                throw new Error(`Failed to analyze resume: ${apiResponse.statusText}`);
            }

            // Process response: Create URL for the processed PDF
            const resultBlob = await apiResponse.blob();
            const fileURL = URL.createObjectURL(resultBlob);
            setProcessedPdfUrl(fileURL);
        } catch (err) {
            console.error('Error performing extraction analysis:', err);
            setError('Extraction analysis failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Toggle between Missing Data and Shared Data Views
    const handleToggleView = () => {
        setIsMissingDataView((prev) => !prev);
        setPdfUrl(isMissingDataView ? sharedDataUrl : missingDataUrl);
    };

    return (
        <div className="flex flex-col h-full w-full">
            {/* Header and Controls */}
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
                <div className="text-lg font-semibold">Resume Preview</div>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => setScale((prev) => Math.max(0.5, prev - 0.1))}
                        className="p-2 rounded hover:bg-gray-100"
                        title="Zoom Out"
                    >
                        <ZoomOut className="w-4 h-4" />
                    </button>
                    <span className="text-sm font-medium">{Math.round(scale * 100)}%</span>
                    <button
                        onClick={() => setScale((prev) => Math.min(2, prev + 0.1))}
                        className="p-2 rounded hover:bg-gray-100"
                        title="Zoom In"
                    >
                        <ZoomIn className="w-4 h-4" />
                    </button>
                    <button
                        onClick={handleExtractionAnalysis}
                        className="p-2 rounded bg-blue-600 text-white hover:bg-blue-700"
                        title="Extraction Analysis"
                    >
                        {isLoading ? (
                            <Loader2 className="w-4 h-4 animate-spin" />
                        ) : (
                            "Extraction Analysis"
                        )}
                    </button>
                    {/* <button
                        onClick={handleToggleView}
                        className="p-2 rounded bg-gray-600 text-white hover:bg-gray-700"
                        title="Toggle View"
                    >
                        {isMissingDataView ? 'Switch to Shared Data' : 'Switch to Missing Data'}
                    </button> */}
                </div>
            </div>

            {/* PDF Viewer */}
            <div className="flex-1 bg-gray-50 relative">
                {isLoading ? (
                    <div className="flex items-center justify-center h-full">
                        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                    </div>
                ) : fileType?.includes('pdf') ? (
                    <PdfLoader
                        url={processedPdfUrl || pdfUrl} // Toggle between original and processed PDF
                        beforeLoad={
                            <div className="flex items-center justify-center h-full">
                                <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                            </div>
                        }
                    >
                        {(pdfDocument) => (
                            <PdfHighlighter
                                pdfDocument={pdfDocument}
                                enableAreaSelection={false}
                                highlights={[]} // Highlights fetched externally
                                scrollRef={() => { }}
                            />
                        )}
                    </PdfLoader>
                ) : (
                    <img
                        src={fileUrl}
                        alt="Resume Preview"
                        className="w-full h-auto"
                    />
                )}
            </div>

            {/* Error Message */}
            {error && (
                <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                </div>
            )}
        </div>
    );
};

export default ResumePreviewPanel;
