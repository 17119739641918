// SideBar.jsx
import React, { useState, useEffect } from 'react';
import {
    Rocket,
    Settings,
    Sparkles,
    Menu,
    X,
    Building,
    CreditCard,
    Key,
    Users,
    Upload,
    Edit2,
    Shield,
    ChevronRight,
    Moon,
    Sun,
    Monitor,
    User,
    Briefcase,
    Phone,        // Imported Phone icon for Call Dashboard
    Calendar      // Imported Calendar icon (optional)
} from 'lucide-react';

const SideBar = ({
    activeView,
    onViewChange,
    userData,
    agencyData,
    themeState = 'light',
    onThemeChange = () => { }
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (activeView === 'resume-editor') {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);  // Revert to expanded state when switching away from "resume-editor"
        }
    }, [activeView]);

    useEffect(() => {
        if (activeView === 'call-dashboard') {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);  // Revert to expanded state when switching away from "resume-editor"
        }
    }, [activeView]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setIsMobileMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigationItems = [
        {
            id: 'resume-upload',
            label: 'Upload Resume',
            icon: Upload,
            highlight: true
        },

        {
            id: 'apply',
            label: (
                <div className="flex items-center gap-2">
                    Your Candidates
                    <Sparkles className="h-4 w-4 text-yellow-400 animate-pulse" />
                </div>
            ),
            icon: Rocket,
            highlight: true
        },
        {
            id: 'resume-editor',
            label: (
                <div className="flex items-center gap-2">
                    Resume Editor
                    <Sparkles className="h-4 w-4 text-blue-400 animate-pulse" />
                </div>
            ),
            icon: Edit2,
            highlight: true
        },
        {
            id: 'call-dashboard',  // New Call Dashboard Navigation Item
            label: 'Call Dashboard',
            icon: Phone,
            highlight: true
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: Settings,
            subItems: [
                { id: 'organization', label: 'Organization', icon: Building },
                { id: 'billing', label: 'Billing & Usage', icon: CreditCard },
                { id: 'team', label: 'Team Members', icon: Users },
                { id: 'security', label: 'Security', icon: Shield },
                { id: 'api', label: 'API Settings', icon: Key }
            ]
        }
    ];

    const handleNavigation = (itemId) => {
        onViewChange?.(itemId);
        setIsMobileMenuOpen(false);
    };

    const cycleTheme = () => {
        if (themeState === 'light') {
            onThemeChange('dark');
        } else if (themeState === 'dark') {
            onThemeChange('system');
        } else {
            onThemeChange('light');
        }
    };

    const getThemeIconAndLabel = () => {
        switch (themeState) {
            case 'light':
                return { icon: <Sun className="h-5 w-5" />, label: 'Light Mode' };
            case 'dark':
                return { icon: <Moon className="h-5 w-5" />, label: 'Dark Mode' };
            case 'system':
                return { icon: <Monitor className="h-5 w-5" />, label: 'System Mode' };
            default:
                return { icon: <Sun className="h-5 w-5" />, label: 'Light Mode' };
        }
    };

    const { icon: themeIcon, label: themeLabel } = getThemeIconAndLabel();

    return (
        <>
            {/* Mobile Menu Button */}
            <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="lg:hidden fixed top-4 left-4 z-10 p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700"
            >
                {isMobileMenuOpen ? (
                    <X className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                ) : (
                    <Menu className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                )}
            </button>

            {/* Mobile Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-5"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}

            {/* Sidebar Container */}
            <div
                className={`${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
    lg:translate-x-0 fixed lg:sticky top-0 z-10 h-screen 
    bg-blue-50 dark:bg-gray-800 border-r border-blue-200 dark:border-gray-700
    transition-all duration-300 flex flex-col
    ${isExpanded ? 'w-72' : 'w-20'}`}
            >
                {/* User and Agency Info */}
                <div className="p-4 border-b border-blue-200 dark:border-gray-700">
                    {isExpanded ? (
                        <div className="space-y-3">
                            <div className="flex items-center space-x-3">
                                <div className="h-10 w-10 rounded-full bg-blue-200 dark:bg-gray-700 flex items-center justify-center">
                                    <User className="h-5 w-5 text-blue-700 dark:text-gray-300" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="font-medium text-blue-900 dark:text-gray-100">
                                        {userData?.first_name} {userData?.last_name}
                                    </span>
                                    <span className="text-sm text-blue-600 dark:text-gray-400">
                                        {userData?.role || 'Recruiter'}
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 pl-1">
                                <Briefcase className="h-4 w-4 text-blue-600 dark:text-gray-400" />
                                <span className="text-sm font-medium text-blue-800 dark:text-gray-300">
                                    {agencyData?.name || 'Agency Name'}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center">
                            <div className="h-10 w-10 rounded-full bg-blue-200 dark:bg-gray-700 flex items-center justify-center">
                                <User className="h-5 w-5 text-blue-700 dark:text-gray-300" />
                            </div>
                        </div>
                    )}
                </div>

                {/* Navigation */}
                <nav className="flex-1 overflow-y-auto py-2">
                    {navigationItems.map((item) => (
                        <div key={item.id}>
                            <button
                                onClick={() => handleNavigation(item.id)}
                                className={`w-full px-6 py-4 flex items-center justify-between transition-all duration-200
                                    group hover:bg-blue-100 dark:hover:bg-gray-700/50
                                    ${(activeView === item.id || item.subItems?.some(sub => sub.id === activeView))
                                        ? 'bg-blue-100 dark:bg-gray-700 border-r-4 border-blue-500 shadow-sm'
                                        : 'hover:border-r-4 hover:border-blue-300'}
                                    ${item.highlight ? 'bg-gradient-to-r from-blue-100 to-indigo-50 dark:from-gray-700 dark:to-gray-600 hover:from-blue-200 hover:to-indigo-100' : ''}`}
                            >
                                <div className="flex items-center min-w-0">
                                    <item.icon
                                        className={`h-6 w-6 flex-shrink-0 transition-colors duration-200
                                            ${activeView === item.id
                                                ? 'text-blue-700 dark:text-gray-200'
                                                : 'text-blue-600 dark:text-gray-300 group-hover:text-blue-800 dark:group-hover:text-gray-100'}`}
                                    />
                                    {isExpanded && (
                                        <span className={`ml-4 text-base font-medium transition-colors duration-200
                                            ${activeView === item.id
                                                ? 'text-blue-900 dark:text-gray-100'
                                                : 'text-blue-900 dark:text-gray-200 group-hover:text-blue-800 dark:group-hover:text-gray-100'}`}>
                                            {item.label}
                                        </span>
                                    )}
                                </div>
                                {item.subItems && isExpanded && (
                                    <ChevronRight
                                        className={`h-5 w-5 text-blue-400 dark:text-gray-300 transition-transform duration-200
                                            ${activeView === item.id || item.subItems.some(sub => sub.id === activeView) ? 'transform rotate-90' : ''}`}
                                    />
                                )}
                            </button>

                            {/* Sub Items */}
                            {item.subItems && (activeView === item.id || item.subItems.some(sub => sub.id === activeView)) && isExpanded && (
                                <div className="py-2 space-y-1 bg-blue-100 dark:bg-gray-700/50">
                                    {item.subItems.map((subItem) => (
                                        <button
                                            key={subItem.id}
                                            onClick={() => handleNavigation(subItem.id)}
                                            className={`w-full px-6 py-3 flex items-center transition-all duration-200
                                                group hover:bg-blue-200 dark:hover:bg-gray-600
                                                ${activeView === subItem.id
                                                    ? 'bg-blue-200 dark:bg-gray-600 text-blue-900 dark:text-gray-100'
                                                    : 'text-blue-900 dark:text-gray-200 hover:text-blue-800 dark:hover:text-gray-100'}`}
                                        >
                                            <subItem.icon className="h-5 w-5 mr-4 group-hover:text-blue-800 dark:group-hover:text-gray-100" />
                                            <span className="text-sm group-hover:text-blue-800 dark:group-hover:text-gray-100">
                                                {subItem.label}
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>

                {/* Theme Toggle */}
                <div className="p-4 border-t border-blue-200 dark:border-gray-700">
                    <button
                        onClick={cycleTheme}
                        className="w-full px-4 py-2 text-left flex items-center gap-2 text-blue-900 dark:text-gray-200 bg-blue-100 dark:bg-gray-700 rounded hover:bg-blue-200 dark:hover:bg-gray-600 transition-colors"
                    >
                        {themeIcon}
                        {isExpanded && <span>{themeLabel}</span>}
                    </button>
                </div>

                {/* Expand/Collapse Button */}
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="hidden lg:flex p-4 border-t border-blue-200 dark:border-gray-700 items-center justify-center 
                        hover:bg-blue-100 dark:hover:bg-gray-700 transition-colors duration-200 group"
                >
                    <ChevronRight
                        className={`h-6 w-6 text-blue-400 dark:text-gray-200 transition-transform duration-300 group-hover:text-blue-600 dark:group-hover:text-gray-100
                            ${isExpanded ? 'transform rotate-180' : ''}`}
                    />
                </button>
            </div>
        </>
    );

};

export default SideBar;
