import React, { useState } from 'react';
import { Calendar, Loader2 } from 'lucide-react';
import supabase from '../../supabaseClient';

const ConnectCalendarButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const connectCalendar = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            console.log('Session:', session);

            // Get Google OAuth token from the session
            const googleToken = session?.provider_refresh_token;

            if (!googleToken) {
                throw new Error('No Google refresh token available. Please sign in with Google again.');
            }

            const response = await fetch('https://726c-74-64-243-204.ngrok-free.app/api/connect-calendar', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh_token: googleToken,
                    provider: 'Google'
                })
            });

            console.log('Response status:', response.status);

            const responseText = await response.text();
            console.log('Raw response:', responseText);

            if (!response.ok) {
                throw new Error(responseText || 'Failed to connect calendar');
            }

            alert('Calendar connected successfully!');
        } catch (err) {
            const errorMessage = err.message || 'Unknown error occurred';
            setError(errorMessage);
            console.error('Calendar connection error:', errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button
                onClick={connectCalendar}
                disabled={isLoading}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 disabled:opacity-50"
            >
                {isLoading ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                    <Calendar className="h-5 w-5" />
                )}
                {isLoading ? 'Connecting...' : 'Link Agent to Calendar(s)'}
            </button>

            {error && (
                <div className="mt-2 text-sm text-red-500">
                    {error}
                </div>
            )}
        </>
    );
};

export default ConnectCalendarButton;